import {Table, Spin, Form, Input, Button, Select} from "antd";
import React, { FC, useEffect, useState } from "react";
import { useConnection, useModule } from "../../Services/ApplicationContext";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import "../styles.scss";
import {Option} from "antd/es/mentions";

export const Modules = () => {
  const { get, post } = useConnection();
  const { setModuleDetails, moduleDetails } = useModule();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [modelVisible, setModelVisible] = useState<boolean>(false);
  const [moduleData, setModuleData] = useState<any>();
  const [tableData, setTableData] = useState<any>([]);
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Module ID",
      dataIndex: "module_id",
      key: "module_id",
    },
    {
      title: "Module Name",
      dataIndex: "module_name",
      key: "module_name",
    },
    {
      title: "Module Description",
      dataIndex: "module_description",
      key: "module_description",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      render: (_: any, record: any) => {
        return (
          <>
            <Button type="primary" onClick={() => setFields(record)}>
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  const setFields = (record: any) => {
    console.log(record);
    setVisible(true);
    if(record.module_id == 2) {
      setModelVisible(true);
    } else {
      setModelVisible(false);
    }
    form.setFieldsValue({
      module_name: record.module_name,
      module_description: record.module_description,
      module_id: record.module_id,
    });
  };

  // useEffect(() => {
    //getModules();
  // }, []);

  // const getModules = async () => {
  //   try {
  //     setLoading(true);
  //     const result = await get("classes/view_modules/");
  //     console.log(result.data.modules);
  //     setTableData(result.data.modules);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     errorNotification("Error", error.message);
  //   }
  // };

  const onUpdate = async (values: any) => {
    console.log(values);
    try {
      setLoading(true);
      const data: any = {
        module_description: values.module_description,
        module_id: values.module_id,
        module_name: values.module_name,
      }

      if(values.module_id == 2) {
        data["od_model"] = values.od_model
      }

      await post("classes/update_module/", data);

      setModuleDetails(
        moduleDetails.map((data: any) => {
          if (data.module_id === values.module_id) {
            (data.module_name = values.module_name),
              (data.module_description = values.module_description);
          }
          console.log(data)
          return data;
        })
      );
      // setModuleDetails(moduleDetails.map((data: any) => {
      //   if (data.module_id === values.module_id) {
      //     data.module_name = values.module_name
      //   }
      //   return data;
      // }));
      form.resetFields();
      setVisible(false);
      setLoading(false);
      successNotification("Successful", "Module Updated successfully!");
    } catch (error) {
      errorNotification("Error", error.message);
      setLoading(false);
    }
  };

  return (
    <>
      {visible ?
          <div className="component-box">
            <div
                style={{
                  marginBottom: 20,
                  justifyContent: "center",
                }}
            >
          <span
              style={{
                fontSize: 30,
              }}
          >
            Edit Modules
          </span>
            </div>
            <Form layout="vertical" form={form} onFinish={onUpdate} initialValues={{od_model:"restnet"}}>
              <Form.Item label="Module Name" name="module_id" hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item
                  label="Module Name"
                  name="module_name"
                  rules={[{ transform: (value) => value.trim(), required: true, message: "Please enter a module name" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                  label="Module description"
                  name="module_description"
                  rules={[{ transform: (value) => value.trim(), required: true, message: "Please enter a description" }]}
              >
                <Input.TextArea />
              </Form.Item>
              {modelVisible ?
                  <Form.Item
                      label="Object detection model"
                      name="od_model"
                      rules={[{ required: true, message: "Please select object detection model" }]}
                  >
                    <Select defaultValue="restnet">
                      <Option value={"restnet"}>RestNet</Option>
                      <Option value={"yolo"}>YOLO-Tiny</Option>
                    </Select>
                  </Form.Item>
              : null}
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Form>
          </div>
      : null}
      <div className="component-box">
        {" "}
        <Table
          pagination={{
            pageSizeOptions: ["10", "15", "25"],
            showSizeChanger: true,
          }}
          bordered
          dataSource={moduleDetails}
          columns={columns}
          loading={loading}
        />
      </div>
    </>
  );
};
