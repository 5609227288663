import React, { FC, useContext, useState, useEffect } from "react";
import { Form, Radio, Image, Spin, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import { ImageContext } from "./ImageContext/ImageContext";
import {NavigationContext} from "../SideNavigation/SideNavigation";
export const ImageDataComponent: FC = () => {
  const { get, post } = useConnection();
  const [sample, setSample] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const { image, setImage, setResetFields, sessionData, setSessionData, setSessionSavable } = useContext(ImageContext)!;
  const {setGlobalSessionSave} = useConfig();

  useEffect(() => {
    getSamples();
  }, []);

  //get base64
  const getBase64 = (file: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const onImageUpload = async (fileBase64: any) => {
    setImage(fileBase64);
    try {
      successNotification("File Upload", "File Uploaded sucessfully!");
    } catch (error) {
      errorNotification("Error", error.message)
      console.log(error);
    }
  };

  const getSamples = async () => {
    setLoading(true);
    try {
      const result = await get("modules/image/getSamples");
      const data = result.data.data;
      setSample(data);
      setLoading(false);
    } catch (error) {
      setLoading(false)
      errorNotification("Error", error.message)
console.log(error)
    }
  };

  const onSampleImageChange = async (e: any) => {
    setLoading(true);
    setResetFields(true);
    try {
      const result = await post(
        "datasets/get_dataset_data/",
        {
          dataset_id: e.target.value,
        } 
      );
      result.data.dataset = "data:image/jpeg;base64," + result.data.dataset  
      setImage(result.data.dataset)
      successNotification("Success", "Dataset changed!");
      // console.log(result.data.dataset + )
      setLoading(false)
    } catch (error) {
      errorNotification("Error", error.message)
      setLoading(false);
    }
    setResetFields(false);
    setSessionSavable(false);
    setGlobalSessionSave(false);
    sessionData.session_data = [];
    setSessionData(sessionData);
  };
  const handleChange = (info: any) => {
    setResetFields(true);
    if (info.file.status === "uploading") {
      setLoadingUpload(true);
      return;
    }
    if (info.file.status === "error" || info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        setLoadingUpload(false);
        setImage(imageUrl);
        onImageUpload(imageUrl);
      });
    }
    setResetFields(false);
    setSessionSavable(false);
    setGlobalSessionSave(false);
    sessionData.session_data = [];
    setSessionData(sessionData);
  };
  const uploadButton = (
    <div>
      {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      errorNotification("Error", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 <= 1;
    if (!isLt2M) {
      errorNotification("Error", "Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt2M;
  }
  return (
    <div className="component-box">
      <Form>
        <h4>Choose a File</h4>

        <Form.Item>
        
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            accept={"image/*"}
          >
            {image ? (
              <img src={image} alt="avatar" style={{ width: "100%", height:"100%", objectFit:'contain' }} />
            ) : (
              uploadButton
            )}
          </Upload>
           
        </Form.Item>
        <Form.Item label="Or select from sample images">
          <Radio.Group onChange={(e) => onSampleImageChange(e)}>
            {
              sample.map((data:any) => <Radio value = {data.dataset_id}>{data.dataset_name}</Radio>)
            }
          </Radio.Group>
        </Form.Item>
        <div className="">
          <Spin spinning={loading}>
            <div className={"img-cont"}>
              {image === "" ? null : (
                // <Image width={'auto'} height={500} src={image}  />
                <Image src={image} />
              )}
            </div>
          </Spin>
        </div>
      </Form>
    </div>
  );
};
