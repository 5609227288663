import React, {FC, useContext, useEffect, useState} from "react";
import { Button, Checkbox, Form, Select, Slider, Table } from "antd";
import { Option } from "antd/es/mentions";
import { LocationContext } from "./LocationContext/LocationContext";
import CheckboxGroup from "antd/lib/checkbox/Group";
import { isPostfixUnaryExpression } from "typescript";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import moment from "moment";
import { table } from "console";
import { successNotification } from "../../Services/NotificationService";
import { CSVLink } from "react-csv";
import "../../Pages/styles.scss";
import {NavigationContext} from "../SideNavigation/SideNavigation";

export const LocationForm: FC = () => {
  const { map, setMap, setLoading, sessionData, setSessionData, setSessionSavable, stepOneData, stepTwoData, resetFields } = useContext(LocationContext);
  const {setGlobalSessionSave} = useConfig();
  const { post } = useConnection();
  const [tableData, setTableData] = useState<any>([]);
  const [visualizeButton, setVisualizeButton] = useState<boolean>(false);
  const [showpathButton, setShowPathButton] = useState<boolean>(false);
  const [poiSelected, setPoiSelected] = useState<boolean>(false);
  const [poiAvailabe,setPoiAvailable] = useState<any>(undefined)
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const hours = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
     
  ];

  const types = [
    "Atm",
    "Attraction",
    "Baby Care Room",
    "Baggage",
    "Bars & Lounges",
    "Cafes",
    "Candies, Chocolates & Delicatessen",
    "Checkin",
    "Children's Apparel & Toys",
    "Clinic",
    "Convenience Stores",
    "Entertainment",
    "Escalator",
    "Fashion Accessories, Bags & Shoes",
    "Fine Jewellery",
    "Fragrance & Cosmetics",
    "Immigration",
    "Information",
    "Free Internet",
    "Gate",
    "Gst Refund",
    "Lifestyle Electronics, Cameras & Computers",
    "Lifts",
    "Luxury Boutiques",
    "Mid-Price Fashion",
    "Money Changer",
    "Music, Books & Magazines",
    "Pharmacy And Healthcare",
    "Postal",
    "Public Phone",
    "Quickbites",
    "Rest Area",
    "Restaurants & Bistros",
    "Skytrain",
    "Souvenirs & Gifts",
    "Sports",
    "Toilets",
    "Transfer Lounge",
    "Tv Lounge",
    "Watches",
    "Wellness & Beauty",
    "Wine, Liquor & Tobacco",
  ];

  const samples = [
    {
      key: "Sample 1",
      value: "sample 1",
    },
    {
      key: "Sample 2",
      value: "sample 2",
    },
    {
      key: "Sample 3",
      value: "sample 3",
    },
    {
      key: "Sample 4",
      value: "sample 4",
    },
    {
      key: "Sample 5",
      value: "sample 5",
    },
    {
      key: "Sample 6",
      value: "sample 6",
    },
    {
      key: "Sample 7",
      value: "sample 7",
    },
    {
      key: "Sample 8",
      value: "sample 8",
    },
    {
      key: "Sample 9",
      value: "sample 9",
    },
    {
      key: "Sample 10",
      value: "sample 10",
    },
  ];

  const marks = {
    0: {
      style: {
        color: "black",
      },
      label: <strong>0</strong>,
    },
    5: "05",
    10: "10",
    15: "15",
    20: "20",
    25: "25",
    30: {
      style: {
        color: "black",
      },
      label: <strong>30</strong>,
    },
  };

  const selectHour = (value: any) => {
    console.log(value)
    if(value.length > 0){
      setVisualizeButton(true)
    }else{
      setVisualizeButton(false)
    }
   

    if (value && value.length && value.includes("select-all")) {
      form.setFieldsValue({
        hour_list: hours,
      });
      setVisualizeButton(true)
    } else if (value.includes("deselect-all")) {
      form.setFieldsValue({
        hour_list: [],
      });
      setVisualizeButton(false)
    }
  };

  const selectType = (value: any) => {
    value.length > 0 ? setPoiSelected(true) : setPoiSelected(false);
    if (value && value.length && value.includes("select-all")) {
      console.log(types);
      form.setFieldsValue({
        type_list: types,
      });
      setPoiSelected(true)
    } else if (value.includes("deselect-all")) {
      form.setFieldsValue({
        type_list: [],
      });
      setPoiSelected(false)
    }
  };

  useEffect(() => {
    // set the data from stepOneData
    restoreStepOneSession();
  }, [stepOneData]);

  useEffect(() => {
    // set the data from stepOneData
    restoreStepTwoSession();
  }, [stepTwoData]);

  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      form2.resetFields();
      setVisualizeButton(false);
      setTableData([]);
    }
  }, [resetFields]);

  const setData = (step_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == step_data.step) {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const restoreStepOneSession = () => {
    if(stepOneData?.response_data?.data) {
      form.setFieldsValue({
        hour_list: stepOneData?.request_data.hour_list,
        type_list: stepOneData?.request_data.type_list,
        show_labels: stepOneData?.request_data.show_labels
      });
      setMap(stepOneData?.response_data?.data.map);
      setVisualizeButton(true);
    }
  }

  const restoreStepTwoSession = () => {
    if(stepTwoData?.response_data?.data) {
      form2.setFieldsValue({
        individual: stepTwoData.request_data.individual,
        path_order: stepTwoData.request_data.path_order,
        radius: stepTwoData.request_data.radius,
        show_labels: stepTwoData.request_data.show_labels,
        start_end: stepTwoData.request_data.start_end
      });
      setTableData(stepTwoData?.response_data?.data.tracking_info);
      setMap(stepTwoData?.response_data?.data.map);
      setPoiAvailable(stepTwoData?.response_data?.data.tracking_info.length > 0 ?true:false);
      setShowPathButton(true);
    }
  }

  const generateHeatMap = async (values: any) => {
   console.log(values)
   console.log( values.hour_list)
   if(values.hour_list === undefined){
    values.hour_list = []
   }
    values.location = "airport";
    values.hour_list = values.hour_list.map((hour: string) => parseInt(hour));
    setLoading(true);
    try {
      let result = await post("modules/location/generateHeatMap", values);
      result.data.data.map =
        "data:image/jpeg;base64," + result?.data?.data?.map;
      setMap(result.data.data.map);
      console.log("response", result?.data?.data?.map);
      setLoading(false);

      const step_one_data = {
        step: 'step 1',
        request_data: values,
        response_data: {
          data: result.data.data
        }
      }
      setData(step_one_data);
      setSessionSavable(true);
      setGlobalSessionSave(true);
      successNotification("Successful", "Heat map generation successful!");
    } catch (error) {
      setLoading(false);
    }
  };

  const trackIndividual = async (value: any) => {
    setLoading(true);
    console.log(value);
    try {
      if (value.show_labels === undefined) value.show_labels = false;
      if (value.path_order === undefined) value.path_order = false;
      if (value.start_end === undefined) value.start_end = false;

      value.location = "airport";
      let result = await post("modules/location/trackIndividual", value);
      console.log(result);
      result.data.data.map =
        "data:image/jpeg;base64," + result?.data?.data?.map;

      const data = [
        {
          time_stamp: "19:07",
          duration: 2473.0,
          poi_list: ["Cocoa & Co", "M&M's World"],
        },
        {
          time_stamp: "22:48",
          duration: 3439.0,
          poi_list: ["transfer lounge"],
        },
      ];
      setTableData(result.data.data.tracking_info);
      setMap(result.data.data.map);
      setPoiAvailable(result.data.data.tracking_info.length > 0 ?true:false);

      const step_two_data = {
        step: 'step 2',
        request_data: value,
        response_data: {
          data: result.data.data
        }
      }
      setData(step_two_data);

      setLoading(false);
      successNotification("Successful", "Track Individual generation successful!");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  //const formatted = moment.utc(secs*1000).format('HH:mm:ss');

  const columns = [
    {
      title: "Time",
      key: "time_stamp",
      dataIndex: "time_stamp",
      sorter: (a: any, b: any) =>
        moment(a.time_stamp, "HH:mm").unix() -
        moment(b.time_stamp, "HH:mm").unix(),
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
      sorter: (a: any, b: any) => a.duration - b.duration,
      render: (text: any, record: any) => {
        const hours = moment.utc(record.duration * 1000).format("HH");
        const mins = moment.utc(record.duration * 1000).format("mm");
        const sec = moment.utc(record.duration * 1000).format("ss");
        let time = "";

        if (!(hours.localeCompare("00") == 0)) time = hours + "hrs ";
        if (!(mins.localeCompare("00") == 0)) time = time + mins + "mins ";
        if (!(sec.localeCompare("00") == 0)) time = time + sec + "secs ";

        return <span>{time}</span>;
      },
    },
    {
      title: "POI",
      key: "poi",
      dataIndex: "poi",
      sorter: (a: any, b: any) => a.poi.localeCompare(b.poi),
      render: (poi: string) => {
        return <span>{poi}</span>
      },
    },
  ];

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <div className="component-box">
          <h2>Step 1: Generate Heat Maps</h2>
          <Form form={form} onFinish={generateHeatMap}>
            <span>Select hour of day</span>
            <Form.Item name="hour_list">
              <Select
                placeholder="Select hour of day"
                mode="multiple"
                onChange={selectHour}
              >
                <Option value="select-all">Select All</Option>
                <Option value="deselect-all">Deselect All</Option>

                {hours.map((hour: any) => (
                  <Option value={hour}>{hour}</Option>
                ))}
              </Select>
            </Form.Item>
            <span>
              Indicate POIs{" "}
              <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                (Max 40 labels will be shown)
              </span>
            </span>
            <Form.Item name="type_list">
              <Select
                placeholder="Select Type"
                mode="multiple"
                onChange={selectType}
              >
                <Option value="select-all">Select All</Option>
                <Option value="deselect-all">Deselect All</Option>

                {types.map((type: any) => (
                  <Option value={type}>{type}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="show_labels" valuePropName="checked">
              <Checkbox value={"show_labels"}>Show labels</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  (map.localeCompare("") == 0) ||
                  (!visualizeButton && !poiSelected)
                }
              >
                Visualize
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="component-box">
          <div>
            <h2>Step 2: Track Individual</h2>
            <Form form={form2} onFinish={trackIndividual} layout="vertical" initialValues = {{radius :10}}>
              <Form.Item name="individual">
                <Select onChange={() => setShowPathButton(true)}>
                  {samples.map((sample: any) => (
                    <Option value={sample.value}>{sample.key}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="start_end" valuePropName="checked">
                <Checkbox>Show path start & end</Checkbox>
              </Form.Item>
              <Form.Item
                name="path_order"
                valuePropName="checked"
                style={{ marginTop: "-2%" }}
              >
                <Checkbox>Show path order labels</Checkbox>
              </Form.Item>
              <Form.Item
                name="show_labels"
                valuePropName="checked"
                style={{ marginTop: "-2%" }}
              >
                <Checkbox>Show POI labels</Checkbox>
              </Form.Item>

              <Form.Item name="radius" label={<h3>POI Radius</h3>}>
                <Slider marks={marks} defaultValue={10} max={30}/>
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  (map.localeCompare("") == 0) || !showpathButton
                }
              >
                Show Path
              </Button>
            </Form>
          </div>
          {tableData.length > 0 && (
            <div className="">
              <div className="" style={{ marginTop: "20px" }}>
                <Table columns={columns} dataSource={tableData} bordered/>
              </div>
              <div className="">
                <Button type="primary">
                  <CSVLink data={tableData} filename={"POI_table.csv"} headers = {[
                     { label: "Time Stamp", key: "time_stamp" },
                     { label: "POI", key: "poi" },
                     { label: "Duration (seconds)", key: "duration" }
                  ]}>
                    Download
                  </CSVLink>
                </Button>
              </div>
            </div>
            
          )}
         {
           poiAvailabe === false ?  <div style = {{margin:'10px 0px'}}>No POI</div> : <span></span>
         }
        </div>
      </div>
    </>
  );
};
