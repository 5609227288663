import { Button, Table } from "antd";
import React, { FC, useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { DecisionTreeContext } from "./DecisionTreeContext";
import "../../Pages/styles.scss";
import {NavigationContext} from "../SideNavigation/SideNavigation";
import {useConfig} from "../../Services/ApplicationContext";

export const StepOneExplore: FC = () => {
  const { file, setFile, setTargetVariable, setFeatures, sessionData, setSessionData, stepOneData, setSessionSavable, resetFields } = useContext(
    DecisionTreeContext
  );
  const {setGlobalSessionSave} = useConfig();
  const [columns, setColumns] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);

  const setData = (step_one_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_one_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == 'step 1') {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_one_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const exploreDataset = () => {
    console.log(stepOneData.file);
    console.log(file);
    let columnX: string[] = atob(file).split("\n")[0].split(",");

    console.log("before", columnX);
    // let decode = window.atob(fileBase64).split("\n")[0].split(",");
    if (columnX[0].split("ï»¿")[0].localeCompare("") == 0) {
      columnX[0] = columnX[0].split("ï»¿")[1];
    }
    console.log("after", columnX);
    setTargetVariable(columnX);
    setFeatures(columnX);
    columnX = columnX.map((col: any) => {
      return col.split(" ").join("");
    });

    const dataSet = atob(file).split("\n");
    dataSet.shift();
    //  console.log(dataSet);
    const v = dataSet.map((data: any) => {
      const row = data.split(new RegExp(",(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)"));
      let newData = {};
      row.map((item: any, inderow: number) => {
        newData = { ...newData, [columnX[inderow]]: item };
      });
      return newData;
    });
     

    //preparing column set
    setColumns(
      columnX.map((col: any) => {
          console.log(col)
        const columnsSet = {
          title: col,
          dataIndex: col,
          key: col,
           
        };
        return columnsSet;
      })
    );

    setTableData(v);
    const step_one_data = {
      step: 'step 1',
      file: file,
      response_data: {}
    }
    setData(step_one_data);
    setSessionSavable(true);
    setGlobalSessionSave(true);
  };


  useEffect(() => {
    setTableData([]);
  }, [file]);

  useEffect(() => {
    if(stepOneData.file) {
      // setTableData([]);
      exploreDataset();
    }
  }, [stepOneData]);

  useEffect(() => {
    setTableData([]);
  }, [resetFields]);


  return (
    <div className="component-box">
      <h2>Step 1: Explore Data</h2>
      <Button
        type="primary"
        onClick={exploreDataset}
        disabled={(file != undefined && file.localeCompare("") == 0)}
      >
        Explore
      </Button>

      {tableData.length > 0 && (
        <>
          <div className="" style={{ marginTop: 15 }}>
            <Table columns={columns} dataSource={tableData} scroll={{x:true}} bordered />
          </div>
          <div className="">
            <Button type="primary">
              <CSVLink data={tableData} filename={"Explore_Data.csv"}>
                Download
              </CSVLink>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
