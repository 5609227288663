import React, { FC, useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
} from "antd";

import { TextContext } from "./TextContext/TextContext";
import { useConnection } from "../../Services/ApplicationContext";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import { CSVLink } from "react-csv";
import { useForm } from "antd/lib/form/Form";
import "../../Pages/styles.scss";
const { Option } = Select;

export const StepTwoComponent: FC = () => {
  const word_list = [
    "a",
    "an",
    "and",
    "are",
    "as",
    "at",
    "be",
    "but",
    "by",
    "for",
    "if",
    "in",
    "into",
    "is",
    "it",
    "no",
    "not",
    "of",
    "on",
    "or",
    "such",
    "that",
    "the",
    "their",
    "then",
    "there",
    "these",
    "they",
    "this",
    "to",
    "was",
    "will",
    "with",
  ];

  const { token, stopWords, setStopWords, sessionData, setSessionData, stepTwoData, resetFields, visualize } = useContext(TextContext);
  const [stopWordList, setStopWordList] = useState<any>([""]);
  const [tableData, setTableData] = useState<any>([{}]);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [noOfTokens, setNoOfTokens] = useState<number>(0);
  //const [selectAll, setSelectAll] = useState<boolean>(false);
  const { post } = useConnection();
  const [form] = Form.useForm();

  useEffect(() => {
    // set the data from stepOneData
    restoreStepTwoSession();
  }, [stepTwoData]);

  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      setVisible(false);
    }
  }, [resetFields]);

  //adding stop words
  const onStopWordsChange = (value: any) => {
    console.log("---------", value);

    if (value && value.length && value.includes("select-all")) {
      form.setFieldsValue({
        stopwords: word_list,
      });
      setStopWords({ ...stopWords, stop_word_list: word_list });
       return;
    } else if (value.includes("deselect-all")) {
      form.setFieldsValue({
        stopwords: [],
      });
      setStopWords({ ...stopWords, stop_word_list: [] });
      return
    }
    setStopWords({ ...stopWords, stop_word_list: value });
  };

  //adding custom stop word
  const onCustomTextChange = (e: any) => {
    console.log("--------------", e.target.value);
    let arr = e.target.value;
    arr = arr.split(",");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].trim()
    }
    console.log(arr);
    setStopWords({ ...stopWords, custom_word_list: arr });
  };

  const setData = (step_two_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_two_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == 'step 2') {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_two_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const setSelectedFields = (stop_words: any, c_stop_words: any) => {
    var custom_words = '';
    for(const stop in c_stop_words) {
      custom_words += c_stop_words[stop].trim();
      custom_words += ',';
    }
    if(custom_words) {
      custom_words = custom_words.slice(0, custom_words.length-1)
    }
    form.setFieldsValue({
      stopwords:stop_words,
      custom_word:custom_words
    });
  }

  const restoreStepTwoSession = () => {
    if(stepTwoData?.response_data?.data) {
      setNoOfTokens(stepTwoData.response_data.data.length);
      setSelectedFields(stepTwoData.request_data.stop_word_list, stepTwoData.request_data.custom_word_list)
      setTableData(
          stepTwoData.response_data.data.map((data: any, index: number) => {
            const newdata = {
              index: index + 1,
              token: data,
            };
            return newdata;
          })
      );
      setVisible(true);
    }
  }

  const removeStopWords = async (values: any) => {
    console.log("***********", stopWords?.stop_word_list);
    console.log(stopWordList);

    if(!token) {
      errorNotification("Error", "Tokenize first!");
      return;
    }

    const data = {
      custom_word_list: stopWords?.custom_word_list,
      tokens: token,
      stop_word_list:
        stopWords?.stop_word_list === undefined
          ? []
          : stopWords?.stop_word_list,
    };

    console.log(data);
    setLoading(true);
    try {
      console.log(stopWords);
      const result = await post("modules/text/removingStopWords", data);
      console.log(result);
      setNoOfTokens(result?.data?.data.length);
      setTableData(
        result?.data?.data.map((data: any, index: number) => {
          const newdata = {
            index: index + 1,
            token: data,
          };
          return newdata;
        })
      );

      //stepTwo session data
      const step_two_data = {
        step: 'step 2',
        request_data: data,
        response_data: {
          data: result?.data?.data
        }
      }
      //set session data
      setData(step_two_data)

      setLoading(false);
      setVisible(true);
      successNotification("Successful", "Removed Stop Words!");
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
      errorNotification("Error", error.message)
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      sorter: (a: any, b: any) => a.token.localeCompare(b.token),
    },
  ];

  useEffect(() => {
    //form.setFieldsValue(word_list);
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Step 2: Remove Stop Words</h2>
        <Form
          onFinish={removeStopWords}
          form={form}

        ><h4>Select stop words to remove</h4>
          <Form.Item name="stopwords">

            <Select mode="multiple" onChange={onStopWordsChange}>
              {/* <Option value='sdad'>sasd</Option> */}
              <Option value={'select-all'}>Select All</Option>
              <Option value={'deselect-all'}>Deselect All</Option>
              {word_list.map((word) => (
                <Option value={word}>{word}</Option>
              ))}
            </Select>
            {/* value = {selectAll ? word_list : []} */}
            {/* <Button onClick = {() => setSelectAll(!selectAll)}>{selectAll ? "Deselect All" : "Select All"}</Button> */}
          </Form.Item>
          <h4>Custom stop words (Use a comma to seperate)</h4>
          <Form.Item label="" name="custom_word">
            <Input onChange={(e) => onCustomTextChange(e)} />
          </Form.Item>
          <Row>
            <Button type="primary" htmlType="submit" disabled={!visualize}>
              Remove Stop Words
            </Button>
          </Row>
        </Form>
        {visible ? (
          <>
            <Table
              columns={columns}
              bordered
              pagination={{
                pageSizeOptions: ["10", "15", "25"],
                showSizeChanger: true,
              }}
              dataSource={tableData}
            />
            <div className="" style={{ marginBottom: 10, marginTop: 5 }}>
              <span style={{ fontWeight: "bold" }}>
                Total Number of Tokens = {noOfTokens}
              </span>
            </div>
            <div className="">
              <Button type="primary">
                <CSVLink data={tableData} filename={"Remove_Stop_words.csv"}>
                  Download
                </CSVLink>
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </Spin>
  );
};
