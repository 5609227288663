import React, {FC, useContext} from "react";
import { NetworkStatistics } from "../../Components/NetworkAnalysisComponent/NetworkStatistics";
import { NetworkVisualize } from "../../Components/NetworkAnalysisComponent/NetworkVisualize";
import { NetworkFile } from "../../Components/NetworkAnalysisComponent/UploadNetworkFile";
import {
  NetworkContext,
  NetworkContextProvider
} from "../../Components/NetworkAnalysisComponent/NetworkContext/NetworkContext";
import {Modal} from "antd";

export const NetworkAnalysis: FC = () => {
  return (
      <NetworkContextProvider>
        <NetworkContainerWrapper />
      </NetworkContextProvider>
  );
};

export const NetworkContainerWrapper: FC = () => {
  const {saveSession, restoreSession} = useContext(NetworkContext);
  const restoreWarn = () => {
    const modal = Modal.confirm({
      title: 'Warning',
      content: `Restore will clear all your unsaved data`,
      onOk() {
        restoreSession();
        modal.destroy();
      },
      onCancel() {
        modal.destroy();
      }
    });
  }
  return (
      <>
        <div
            style={{
              marginBottom: 20,
              justifyContent: "center",
            }}
        >
            <span
                style={{
                  fontSize: 30,
                }}
            >
              Network Analysis
            </span>
          <div
              style={{
                float: "right"
              }}
          >
            <button
                onClick={saveSession}
                className={"btn-save"}
            > Save </button>
            <button
                onClick={restoreWarn}
                className={"btn-restore"}
            > Restore </button>
          </div>
          <div style={{clear: 'both'}}></div>
        </div>
        <NetworkFile/>
        <NetworkVisualize/>
        <NetworkStatistics/>
      </>
  );
}
