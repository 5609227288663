import React, {FC, useContext, useEffect, useState} from "react";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import {errorNotification, successNotification} from "../../Services/NotificationService";
import {NavigationContext} from "../SideNavigation/SideNavigation";

export type DecisionTreeContextProps = {
  file: string;
  setFile: (val: string) => void;
  targetVariable: string[];
  setTargetVariable: (val: any) => void;
  features: string[];
  setFeatures: (val: any) => void;
  plot: string;
  setPlot: (val: string) => void;
  sessionData: any;
  setSessionData: (val: any) => void;
  stepOneData: any;
  setStepOneData: (val: any) => void;
  stepTwoData: any;
  setStepTwoData: (val: any) => void;
  sessionSavable: boolean;
  setSessionSavable: (val: boolean) => void;
  saveSession: () => Promise<void>;
  restoreSession: () => Promise<void>;
  resetFields: boolean;
  setResetFields: (val: boolean) => void;
};

export const DecisionTreeContext = React.createContext<
  DecisionTreeContextProps
>({
  file: "",
  setFile: () => {},
  targetVariable: [],
  setTargetVariable: () => {},
  features: [],
  setFeatures: () => {},
  plot: "",
  setPlot: () => {},
  sessionData: {},
  setSessionData: () => {},
  stepOneData: {},
  setStepOneData: () => {},
  stepTwoData: {},
  setStepTwoData: () => {},
  sessionSavable: false,
  setSessionSavable: () =>{},
  saveSession: async () => {},
  restoreSession: async () => {},
  resetFields: false,
  setResetFields: () =>{},
});

export const DecisionTreeContextProvider: FC = (props) => {
  const { post } = useConnection();
  const {setGlobalSessionSave} = useConfig();
  const [file, setFile] = useState<any>("");
  const [plot, setPlot] = useState<any>("");
  const [targetVariable, setTargetVariable] = useState<any>([]);
  const [features, setFeatures] = useState<any>([]);
  const [sessionData, setSessionData] = useState<any>({
        module: "tree",
        session_data: []
    });
  const [stepOneData, setStepOneData] = useState<any>({
        step: "step 1",
        file: '',
        response_data: {}
    });
  const [stepTwoData, setStepTwoData] = useState<any>({
        step: "step 2",
        request_data: {
            data: '',
            features: [],
            target: '',
        },
        response_data: {
            data:null
        }
    });
  const [sessionSavable, setSessionSavable] = useState<boolean>(false);
  const [resetFields, setResetFields] = useState<boolean>(false);

  const saveSession = async () => {
        console.log("save session")
        if(sessionSavable) {
            console.log("saving")
            try{
                const result = await post("session/save", sessionData);
                setGlobalSessionSave(false);
                successNotification("Success", "Session saved!")
            } catch (error) {
                console.log("Exception: save session: decision tree ", error);
                errorNotification("Error", error.message);
            }
        }
    }
  const restoreSession = async () => {
        try{
            setResetFields(true);
            setResetFields(false);
            const result = await post("session/restore", { module: "tree" });
            console.log(result)
            // setRestoreFieldClear(!restoreFieldClear);
            const s_data = result?.data?.data;
            for(let i=0; i<s_data.session_data?.length; i++) {
                const step = s_data.session_data[i];
                switch (step.step) {
                    case "step 1": {
                        console.log("restoring step 1");
                        setFile(step.file);
                        setStepOneData(step);
                    } break;
                    case "step 2": {
                        console.log("restoring step 2");
                        setStepTwoData(step);
                    } break;
                }
            }
            setSessionData(s_data);
            setSessionSavable(true);
            setGlobalSessionSave(true);
            successNotification("Success", "Session restored!")
        } catch (error) {
            console.log("Exception: restore session: clustering ", error);
            errorNotification("Error", error.message);
        }
    }

  useEffect(() => {
      setGlobalSessionSave(sessionSavable);
  }, [sessionSavable]);

  return (
    <DecisionTreeContext.Provider
      value={{
        file,
        setFile,
        targetVariable,
        setTargetVariable,
        plot,
        setPlot,
        features,
        setFeatures,
        sessionData,
        setSessionData,
        stepOneData,
        setStepOneData,
        stepTwoData,
        setStepTwoData,
        sessionSavable,
        setSessionSavable,
        saveSession,
        restoreSession,
        resetFields,
        setResetFields
      }}
    >
      {props.children}
    </DecisionTreeContext.Provider>
  );
};

export default DecisionTreeContextProvider;
