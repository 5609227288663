import React, { FC, useContext, useEffect, useState } from "react";
import {Button, Col, Form, Input, InputNumber, Row, Select, Slider, Table} from "antd";
import { ClusteringContext } from "./ClusteringContext";
import { useConnection } from "../../Services/ApplicationContext";
import {
    errorNotification,
    successNotification,
} from "../../Services/NotificationService";
import {Option} from "antd/es/mentions";
import {CSVLink} from "react-csv";

export const StepThreeMDCluster: FC = () => {
    const {
        file,
        variableList,
        randomSeed,
        clusterCount,
        sessionData,
        setSessionData,
        stepThreeData,
        setStepThreeData,
        resetFields
    } = useContext(ClusteringContext);
    const [columns, setColumns] = useState<object[]>([])
    const [tableData, setTableData] = useState([]);
    const { post } = useConnection();
    const { Option } = Select;
    const [form] = Form.useForm();

    useEffect(() => {
        restoreStepThreeSession();
    }, [stepThreeData]);

    const setData = (step_three_data: any) => {
        const s_data = sessionData;
        if(!s_data.session_data.length) {
            s_data.session_data.push(step_three_data);
            setSessionData(s_data);
        } else {
            let data = s_data.session_data;
            let index = -1
            for(let i=0; i<data.length; i++) {
                if(data[i].step == 'step 3') {
                    index = i;
                    break;
                }
            }
            if(index != -1) {
                data.splice(index, 1);
            }
            data.push(step_three_data);
            setSessionData(s_data);
        }

        console.log(sessionData);
    }

    const restoreStepThreeSession = async () => {
        console.log(stepThreeData)
        if(stepThreeData?.response_data?.data) {
            form.setFieldsValue({
                headers: stepThreeData.request_data.dimensions
            });
            createTableHeaders(stepThreeData.request_data.dimensions);
            setTableData(stepThreeData?.response_data?.data.plot_data);
        }
    }

    const createTableHeaders = (headers: []) => {
        const cols = [
            {
                title: "Point ID",
                dataIndex: "point_id",
                key: "point_id",
                sorter: (a: any, b: any) => a.point_id - b.point_id,
            },
            {
                title: "Cluster ID",
                dataIndex: "cluster_id",
                key: "cluster_id",
                sorter: (a: any, b: any) => a.cluster_id - b.cluster_id,
            }
        ];
        for(const index in headers) {
            cols.push(
                {
                    title: headers[index] + "",
                    dataIndex: headers[index] + "",
                    key: headers[index] + "",
                    sorter: (a: any, b: any) => {
                        const name = headers[index]
                        return a[name] - b[name]
                    }
                }
            )
        }
        setColumns(cols);
    }

    const onSubmit = async (values: any) => {
        console.log(randomSeed);
        if (randomSeed < 0 || randomSeed > 100) {
            errorNotification("Error", "Random seed should be between 0 and 100!");
            return;
        }
        if (clusterCount < 1 || clusterCount > 10) {
            errorNotification(
                "Error",
                "Number of clusters should be between 1 and 10!"
            );
        }
        console.log(values);
        console.log("--- file", file);

        try {
            // setRandomSeed(values?.random_seed)
            // columns and table data
            const headers = values.headers;
            console.log(headers)
            if(!headers || headers.length < 1 || headers.length > 5) {
                errorNotification("Error", "Only 1 to 5 dimensions are supported!");
                return;
            }

            const data: any = {
                data: file,
                clusters: clusterCount,
                random_seed: parseInt(randomSeed+""),
                dimensions: headers
            };
            const result = await post("modules/clustering/generateMDClusters", data);
            console.log(result);
            createTableHeaders(headers);
            setTableData(result.data.data.plot_data);

            const step_three_data = {
                step: 'step 3',
                request_data: data,
                response_data: {
                    data: result.data.data
                }
            }

            setData(step_three_data);
            successNotification("Success", "Data generated successfully!");
        } catch (error) {
            errorNotification("Error", error.message)
        }
    };

    useEffect(() => {
        form.resetFields();
    },[variableList]);

    useEffect(() => {
        if(resetFields) {
            form.resetFields();
            setTableData([]);
        }
    });

    const renderVariableList = () => {
        return variableList.map((value, index) => (
            <Option key={value} value={value.trim()}>
                {value.trim()}
            </Option>
        ));
    };

    return (
        <div className="component-box">
            <h2>Step 3: Multidimensional Clustering</h2>
            <Form
                layout="vertical"
                onFinish={onSubmit}
                form={form}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Please select upto 5 dimensions",
                        },
                    ]}
                    label={
                        <span>
                            Dimensions:{" "}
                            <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                                (Number of dimensions must be between 1 and 5)
                            </span>
                        </span>
                    }
                    name="headers"
                    style={{ width: "100%" }}
                >
                    <Select mode="multiple" allowClear>
                        {variableList?.length > 0 && renderVariableList()}
                    </Select>
                </Form.Item>
                <Button
                    type="primary"
                    style={{ width: "100px" }}
                    htmlType="submit"
                    disabled={
                        !(randomSeed > 0) && !(clusterCount > 0)
                    }
                >
                    Calculate
                </Button>
            </Form>
            <div className="">
                {tableData.length > 0 && (
                    <>
                        <Table
                            columns={columns}
                            bordered
                            pagination={{
                                pageSizeOptions: ["10", "15", "25"],
                                showSizeChanger: true,
                            }}
                            dataSource={tableData}
                        />
                        <div className="" style={{ marginTop: 15 }}>
                            <Button type="primary">
                                <CSVLink data={tableData} filename={"MD_Data.csv"}>
                                    Download
                                </CSVLink>
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
