import { Button, Image } from "antd";
import Text from "antd/lib/typography/Text";
import React, {FC, useContext, useEffect, useState} from "react";
import { useConnection } from "../../Services/ApplicationContext";
import { ImageContext } from "./ImageContext/ImageContext";
import { LoadingOutlined } from "@ant-design/icons";

import "./styles.scss";
import { errorNotification, successNotification } from "../../Services/NotificationService";
export const OpticalCharacterRecognition: FC = () => {
  const { image, setData, stepFourData, resetFields } = useContext(ImageContext);
  const [ocr, setOCR] = useState<any>();
  const { post } = useConnection();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    restoreStepFourSession();
  }, [stepFourData]);

  useEffect(() => {
    if(resetFields) {
      setOCR('');
    }
  }, [resetFields]);

  const restoreStepFourSession = () => {
    if(stepFourData?.response_data?.data) {
      const extractedText: string = stepFourData?.response_data?.data?.extracted_text.trim();
      const characters = extractedText.length;
      const noOfWords =
          extractedText === "" ? 0 : extractedText.split(/[\s\n\r\t]+/).length;
      setOCR({
        result: extractedText.replace(/\n/g, "</br>"),
        words: noOfWords,
        characters,
      });
    }
  }

  const processImage = async () => {
    console.log("hellooo ", image);
    setLoading(true);
    try {
      const result = await post("modules/image/ocrAnalysis", {
        image: image.split(",")[1],
      });
      console.log("----------------", result);
      console.log(result?.data?.data);
      const extractedText: string = result?.data?.data?.extracted_text.trim();
      console.log(extractedText.length);
      const characters = extractedText.length;
      const noOfWords =
        extractedText === "" ? 0 : extractedText.split(/[\s\n\r\t]+/).length;
      console.log("no of characters", characters);
      console.log(" of words", extractedText.replace("\n", "</br>"));
      console.log(" of ", extractedText.split("\n"));

      setOCR({
        result: extractedText.replace(/\n/g, "</br>"),
        words: noOfWords,
        characters,
      });
      setLoading(false);

      const step_four_data = {
        step: 'step 4',
        request_data: {
          image: image
        },
        response_data: {
          data: result.data.data
        }
      }

      await setData(step_four_data);

      successNotification("Success", "Optical Character Recognition Successful!")
    } catch (error) {
      console.log("exep ", error);
      setLoading(false);
      errorNotification("Error", error.message)
    }
  };

  return (
    <div className="component-box">
      <div>
        <h2>Step 4: Optical Character Recognition (OCR)</h2>
        <Button type="primary" onClick={processImage} loading={loading} disabled = {image.localeCompare("") == 0 ? true:false}>
          Detect
        </Button>
      </div>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        {ocr && (
          <div className={"estimate-panel"}>
            {ocr.result !== "" ? (
              <div dangerouslySetInnerHTML={{ __html: ocr.result }} />
            ) : (
              <div>No text detected!</div>
            )}
            <br />
            <span>No of Characters : {ocr.characters}</span>
            <span>No of Words : {ocr.words}</span>
          </div>
        )}
      </div>
    </div>
  );
};
