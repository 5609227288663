import React, { FC, useContext } from "react";
import { EdgeDetectionComponent } from "../../Components/ImageProcessingComponent/EdgeDetectionComponent";
import { FaceDetectionComponent } from "../../Components/ImageProcessingComponent/FaceDetectionComponent";
import { GrayScaleImageComponent } from "../../Components/ImageProcessingComponent/GrayScaleImageComponent";
import {
  ImageContextProvider,
  ImageContext,
} from "../../Components/ImageProcessingComponent/ImageContext/ImageContext";
import { ImageDataComponent } from "../../Components/ImageProcessingComponent/ImageDataComponent";
import { ObjectDetectionComponent } from "../../Components/ImageProcessingComponent/ObjectDetectionComponent";
import { OpticalCharacterRecognition } from "../../Components/ImageProcessingComponent/OpticalCharacterComponent";
import { RgbAnalysis } from "../../Components/ImageProcessingComponent/RgbAnalysisComponent";
import "../styles.scss";
import {Modal} from "antd";

export const ImageProcessingContainer: FC = () => {
  const { image } = useContext(ImageContext);
  console.log(image);
  return (
    <ImageContextProvider>
      <ImageProcessingContainerWrapper />
    </ImageContextProvider>
  );
};

export const ImageProcessingContainerWrapper: FC = () => {
  const { saveSession, restoreSession } = useContext(ImageContext);
  const restoreWarn = () => {
      const modal = Modal.confirm({
          title: 'Warning',
          content: `Restore will clear all your unsaved data`,
          onOk() {
              restoreSession();
              modal.destroy();
          },
          onCancel() {
              modal.destroy();
          }
      });
  }

  return (
      <>
        <div
            style={{
              marginBottom: 20,
              justifyContent: "center",
            }}
        >
          <span
              style={{
                fontSize: 30,
              }}
          >
            Image Processing
          </span>
            <div
                style={{
                    float: "right"
                }}
            >
                <button
                    onClick={saveSession}
                    className={"btn-save"}
                > Save </button>
                <button
                    onClick={restoreWarn}
                    className={"btn-restore"}
                > Restore </button>
            </div>
            <div style={{clear: 'both'}}></div>
        </div>
        <div>
          <ImageDataComponent />
          <GrayScaleImageComponent />
          <RgbAnalysis />
          <EdgeDetectionComponent />
          <OpticalCharacterRecognition />
          <FaceDetectionComponent />
          <ObjectDetectionComponent/>
        </div>
      </>
  );
}
