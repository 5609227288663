import React, {FC, Ref, useContext, useEffect, useState} from "react";
import {useConfig, useConnection} from "../../../Services/ApplicationContext";
import {errorNotification, successNotification} from "../../../Services/NotificationService";
import {NavigationContext} from "../../SideNavigation/SideNavigation";

export type NetworkContextProps = {
    graph: any;
    setGraph: (val: any) => void;
    nodeFile: string;
    setNodeFile: (val: string) => void;
    edgeFile: string;
    setEdgeFile: (val: string) => void;
    loading:boolean;
    setLoading:(val:boolean) => void;
    disablePhysics: boolean;
    setDisablePhysics: (val: boolean) => void;
    hierarchical: boolean;
    setHierarchical: (val: boolean) => void;
    options: any;
    setOptions: (val: any) => void;
    graphNet: any;
    setGraphNet: (val: any) => void;
    sessionData: any;
    setSessionData: (val: any) => void;
    stepOneData: any;
    setStepOneData: (val: any) => void;
    stepTwoData: any;
    setStepTwoData: (val: any) => void;
    sessionSavable: boolean;
    setSessionSavable: (val: boolean) => void;
    saveSession: () => Promise<void>;
    restoreSession: () => Promise<void>;
    setData: (val: any) => Promise<void>;
    resetFields: boolean;
    setResetFields: (val: boolean) => void;
}

export const NetworkContext = React.createContext<NetworkContextProps>({
    graph: {nodes:[], edges:[]},
    setGraph: () => {},
    nodeFile: "",
    setNodeFile: () => {},
    edgeFile: "",
    setEdgeFile: () => {},
    loading:false,
    setLoading:() => {},
    disablePhysics: true,
    setDisablePhysics: () => {},
    hierarchical: false,
    setHierarchical: () => {},
    options: {},
    setOptions: () => {},
    graphNet: {},
    setGraphNet: () => {},
    sessionData: {},
    setSessionData: () => {},
    stepOneData: {},
    setStepOneData: (val: any) => {},
    stepTwoData: {},
    setStepTwoData: (val: any) => {},
    sessionSavable: false,
    setSessionSavable: () =>{},
    saveSession: async () => {},
    restoreSession: async () => {},
    setData: async (val: any) => {},
    resetFields: false,
    setResetFields: () =>{},
});

export const NetworkContextProvider : FC = (props) => {
    const { post } = useConnection();
    const {setGlobalSessionSave, globalSessionSave} = useConfig();
    const [graph, setGraph] = useState<any>({nodes:[], edges:[]});
    const [nodeFile, setNodeFile] = useState<string>("");
    const [edgeFile, setEdgeFile] = useState<string>("");
    const [loading,setLoading] = useState<any>(false);
    const [disablePhysics,setDisablePhysics] = useState<any>(true);
    const [hierarchical, setHierarchical] = useState<boolean>(false);
    const [options, setOptions] = useState<any> ({
        physics:{
            enabled: true,
            hierarchicalRepulsion: {
                avoidOverlap: 1
            },
            stabilization: {
                enabled: true,
                iterations: 100, // maximum number of iteration to stabilize
                updateInterval: 10,
                onlyDynamicEdges: false,
                fit: true
            },
            forceAtlas2Based: {
                gravitationalConstant: -126,
                springLength: 200,
                springConstant: 0.01
            },
            maxVelocity: 50,
            solver: "forceAtlas2Based",
            timestep: 0.35,
            improvedLayout: false
        },
        layout: {
            hierarchical: {
                enabled: false,
                levelSeparation: 150,
                direction: "UD",
                sortMethod: "directed",
                shakeTowards: "roots"
            },
            improvedLayout: false
        },
        edges: {
            color: "#1f91c2",
            arrows: "",
            arrowStrikethrough: true,
            font: {
                align: "top"
            },
            scaling: {
                min: 1,
                max: 3
            },
        },
        nodes: {
            shape: "dot",
            shadow: true,
            size: 1,
            font: {
                align: "left"
            }
        },
        interaction: {
            multiselect: true
        },
    });
    const [sessionSavable, setSessionSavable] = useState<boolean>(false);
    const [sessionData, setSessionData] = useState<any>({
        module: "network",
        session_data: []
    });
    const [stepOneData, setStepOneData] = useState<any>({
        step: "step 1",
        request_data: {
            hierarchical: false,
            directed: false,
            weighted: false,
        },
        response_data: {
            data: null
        },
        restore: false
    });
    const [stepTwoData, setStepTwoData] = useState<any>({
        step: "step 2",
        request_data: {},
        response_data: {
            data:{
                global_measures: null,
                central_measures: {
                    table_data: []
                }
            }
        },
        restore: false
    });
    const [resetFields, setResetFields] = useState<boolean>(false);
    const [graphNet, setGraphNet] = useState<any>();

    const saveSession = async () => {
        console.log("save session")
        if(sessionSavable) {
            console.log("saving")
            try{
                for(const d in sessionData.session_data) {
                    sessionData.session_data[d].restore = true;
                }
                setSessionData(sessionData);
                const result = await post("session/save", sessionData);
                setGlobalSessionSave(false);
                successNotification("Success", "Session Saved!")
            } catch (error) {
                console.log("Exception: save session: location ", error);
                errorNotification("Error", error.message);
            }
        }
    }
    const restoreSession = async () => {
        try{
            setResetFields(true);
            setSessionSavable(false);
            setGlobalSessionSave(false);
            const result = await post("session/restore", { module: "network" });
            console.log(result)
            // setRestoreFieldClear(!restoreFieldClear);
            const s_data = result?.data?.data;
            for(let i=0; i<s_data.session_data?.length; i++) {
                const step = s_data.session_data[i];
                switch (step.step) {
                    case "step 1": {
                        console.log("restoring network step 1");
                        setStepOneData(step);
                    } break;
                    case "step 2": {
                        console.log("restoring network step 2");
                        setStepTwoData(step);
                    } break;
                }
            }
            setSessionData(s_data);
            setSessionSavable(true);
            setGlobalSessionSave(true);
            successNotification("Success", "Session restored!")
        } catch (error) {
            console.log("Exception: restore session: network ", error);
            errorNotification("Error", error.message);
        }
        setResetFields(false);
    }
    const setData = async (step_data: any) => {
        const s_data = sessionData;
        if(!s_data.session_data.length) {
            s_data.session_data.push(step_data);
            setSessionData(s_data);
        } else {
            let data = s_data.session_data;
            let index = -1
            for(let i=0; i<data.length; i++) {
                if(data[i].step == step_data.step) {
                    index = i;
                    break;
                }
            }
            if(index != -1) {
                data.splice(index, 1);
            }
            data.push(step_data);
            setSessionData(s_data);
        }
        console.log(sessionData);
    }

    useEffect(() => {
        setGlobalSessionSave(sessionSavable);
    }, [sessionSavable]);

    return (
        <NetworkContext.Provider
            value={{
                graph,
                setGraph,
                nodeFile,
                setNodeFile,
                edgeFile,
                setEdgeFile,
                loading,
                hierarchical,
                setHierarchical,
                options,
                setOptions,
                graphNet,
                setGraphNet,
                setLoading,
                disablePhysics,
                setDisablePhysics,
                sessionData,
                setSessionData,
                stepOneData,
                setStepOneData,
                stepTwoData,
                setStepTwoData,
                sessionSavable,
                setSessionSavable,
                saveSession,
                restoreSession,
                setData,
                resetFields,
                setResetFields
            }}
        >
            {props.children}
        </NetworkContext.Provider>
    );
};