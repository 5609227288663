import React, {FC, useContext, useEffect, useState} from "react";
import { Button, Form, Input, Select, Radio, Row, Table, Spin } from "antd";
import TextContext from "./TextContext/TextContext";
import { useConnection } from "../../Services/ApplicationContext";
import { LoadingOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import "../../Pages/styles.scss";

export const StepThreeComponent: FC = () => {
  // const [stemming, setStemming] = useState<any>();
  const [defaultValue, setDefaultValue] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any>([{}]);
  const {
    token,
    stopWords,
    setStopWords,
    setToken,
    tempToken,
    setFilter,
    sessionData,
    setSessionData,
    stepThreeData,
    resetFields,
    visualize,
  } = useContext(TextContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [noOfTokens, setNoOfTokens] = useState<number>(0);
  const { post } = useConnection();
  const [form] = Form.useForm();

  useEffect(() => {
    // set the data from stepOneData
    restoreStepThreeSession();
  }, [stepThreeData]);

  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      setVisible(false);
    }
  }, [resetFields]);

  const onStemmingChange = (e: any) => {
    setDefaultValue(e.target.value);
    console.log(e.target.value);
    // if(e.target.value){
    // setStemming({
    //   remove_stop_words: e.target.value,
    //   tokens: token,
    //   stop_word_list: stopWords.stop_word_list,
    //   custom_word_list: stopWords.custom_word_list,
    // });
    // }else{
    //   setStemming({
    //     remove_stop_words: e.target.value,
    //     tokens: token,
    //     stop_word_list: stopWords.stop_word_list,
    //     custom_word_list: stopWords.custom_word_list,
    //   });
    // }
  };

  const setData = (step_three_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_three_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == 'step 3') {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_three_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const restoreStepThreeSession = () => {
    if(stepThreeData?.response_data?.data) {
      setFilter(stepThreeData.request_data.remove_stop_words);
      setNoOfTokens(stepThreeData?.response_data?.data.length);
      setToken(stepThreeData?.response_data?.data?.map((data: any) => data.token));

      form.setFieldsValue({
        filter:stepThreeData.request_data.remove_stop_words
      })

      setTableData(
          stepThreeData?.response_data?.data.map((data: any, index: number) => {
            const newdata = {
              index: index + 1,
              token: data.token,
              stemmed_token: data.stemmed_token,
            };
            return newdata;
          })
      );
      setVisible(true);
    }
  }

  const removeStopWords = async () => {
    let data = {};
    if (defaultValue) {
      data = {
        remove_stop_words: true,
        tokens: token,
        stop_word_list: stopWords.stop_word_list,
        custom_word_list: stopWords.custom_word_list,
      };
      setFilter(true);
    } else {
      data = {
        remove_stop_words: false,
        tokens: tempToken,
        stop_word_list: [],
        custom_word_list: [],
      };
      setFilter(false);
    }
    console.log(data);
    setLoading(true);
    try {
      console.log(stopWords);
      const result = await post("modules/text/stemming", data);
      setNoOfTokens(result?.data?.data.length);
      setToken(result?.data?.data?.map((data: any) => data.token));
      setTableData(
        result?.data?.data.map((data: any, index: number) => {
          const newdata = {
            index: index + 1,
            token: data.token,
            stemmed_token: data.stemmed_token,
          };
          return newdata;
        })
      );
      console.log("***** step 3 token", result?.data?.data);

      //stepTwo session data
      const step_three_data = {
        step: 'step 3',
        request_data: data,
        response_data: {
          data: result?.data?.data
        }
      }
      //set session data
      setData(step_three_data)

      setLoading(false);
      setVisible(true);
      successNotification("Successful", "Stemming Successful!");
    } catch (error) {
      setLoading(false);
      console.log(error);
      errorNotification("Error", error.message)
    }
  };

  //defining columns for the table
  const columns = [
    {
      title: "ID",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      sorter: (a: any, b: any) => a.token.localeCompare(b.token),
    },
    {
      title: "Stemmed Token",
      dataIndex: "stemmed_token",
      key: "stemmed_token",
      sorter: (a: any, b: any) =>
        a.stemmed_token.localeCompare(b.stemmed_token),
      // sortOrder: 'stemmed_token' === 'name' && 'descend',
      // ellipsis: true,
    },
  ];

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <div>
          <h2>Step 3: Stemming</h2>
          <h4>Filter stop words</h4>
          <Form form={form}>
          <Form.Item name="filter">
            <Radio.Group
              onChange={(e) => onStemmingChange(e)}
              defaultValue={defaultValue}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          </Form>
          <Row>
            <Button type="primary" onClick={removeStopWords} disabled={!visualize}>
              Stem Words
            </Button>
          </Row>
        </div>
        {visible ? (
          <>
            <Table
              columns={columns}
              bordered
              pagination={{
                pageSizeOptions: ["10", "15", "25"],
                showSizeChanger: true,
              }}
              dataSource={tableData}
            />
            <div className="" style={{ marginBottom: 10, marginTop: 5 }}>
              <span style={{ fontWeight: "bold" }}>
                Total Number of Tokens = {noOfTokens}
              </span>
            </div>
            <div className="">
              <Button type="primary">
                <CSVLink data={tableData} filename={"stemming.csv"}>
                  Download
                </CSVLink>
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </Spin>
  );
};
