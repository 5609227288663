import React, {FC, Ref, useContext, useEffect, useState} from "react";
import {
  Form,
  Input,
  Anchor,
  Checkbox,
  Button,
  Radio,
  Slider,
  Row,
  Col, Spin, Image,
} from "antd";
import "./styles.scss";
import {NetworkContext} from "./NetworkContext/NetworkContext";
import Graph from "react-graph-vis";
import {RadioChangeEvent} from "antd/es/radio";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import {errorNotification, infoNotification, successNotification} from "../../Services/NotificationService";
import {NavigationContext} from "../SideNavigation/SideNavigation";
import {CSVLink} from "react-csv";
const { Link } = Anchor;
export const NetworkFile: FC = () => {
  const {get, post} = useConnection();
  const [form] = Form.useForm();
  const {
    loading,
    setLoading,
    disablePhysics,
    setDisablePhysics,
    graph ,
    setGraph,
    options,
    setOptions,
    setSessionSavable,
    setGraphNet,
    sessionData,
    setSessionData,
    stepOneData,
    setStepOneData,
    setData,
    resetFields
  } = useContext(NetworkContext);
  const {setGlobalSessionSave} = useConfig();
  const [directed, setDirected] = useState<boolean>(false);
  const [weighted, setWeighted] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [showSliders, setShowSliders] = useState<boolean>(true);
  const [customVisible, setCustomVisible] = useState<boolean>(false);
  const network:Ref<any> = React.createRef();
  const [sampleFiles, setSampleFiles] = useState<[]>([]);
  const [customNodes, setCustomNodes] = useState<any[]>([]);
  const [customEdges, setCustomEdges] = useState<any[]>([]);

  const setStepData = () => {
    const step_one = {
      step: "step 1",
      request_data: {
        weighted: weighted,
        directed: directed,
        hierarchical: false
      },
      response_data: {
        data: {
          graph: graph,
          options: options
        }
      },
      restore: false
    }
    setStepOneData(step_one);
    setData(step_one);
  }
  const restoreStep = async () => {
    console.log("restoring step 1");
    // form.resetFields();
    setCustomVisible(false);
    form.setFieldsValue({
      ["dir_check"]: stepOneData.request_data.directed,
      ["w_check"]: stepOneData.request_data.weighted
    })
    options.physics = stepOneData.response_data.data.options.physics;
    options.layout = stepOneData.response_data.data.options.layout;
    options.edges = stepOneData.response_data.data.options.edges;
    setOptions(options);
    graph.nodes = stepOneData.response_data.data.graph.nodes;
    graph.edges = stepOneData.response_data.data.graph.edges;
    if(stepOneData.request_data.weighted) {
      for(const e in graph.edges) {
        graph.edges[e].value = graph.edges[e].weight;
        graph.edges[e].label = graph.edges[e]._label;
      }
    }
    setGraph(graph);
  }

  const events = {
    stabilized: () => {
      console.log("stabilized");
      options.physics.enabled = false;
      setOptions(options);
      network.current?.Network.setOptions(options);
      setStepData();
      setSessionSavable(true);
      setGlobalSessionSave(true);
      setDisablePhysics(true);
    },
  };
  const getSamples = async () => {
    const result = await get("modules/network/getSamples");
    const data = result?.data?.data;
    console.log(data)
    setSampleFiles(data);
  }
  useEffect(()=>{
    getSamples();
    form.setFieldsValue({
      data_option: "random",
      num_nodes: 10,
      density: 0.5
    });
  }, []);
  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      options.physics.enabled = true;
      setOptions(options);
      network.current?.Network.setOptions(options);
      setVisible(false);
      setSessionSavable(false);
      setGlobalSessionSave(false);
      setCustomVisible(false);
    }
  }, [resetFields]);
  useEffect(() => {
    console.log(stepOneData);
    if(stepOneData.restore) {
      restoreStep().then(() => {
        if(visible && network.current) {
          network.current.Network.body.data.nodes = graph.nodes;
          network.current.Network.body.data.edges = graph.edges;
          network.current.Network.setOptions(options);
        }
        setVisible(true);
        setSessionSavable(true);
        setGlobalSessionSave(true);
        cleanGraph();
      });
    }
  }, [stepOneData]);

  useEffect(() => {
    console.log("edges changed", customEdges);
  }, [customEdges]);

  const processGraph = (grph: any) => {
    const nodes = [];
    const edges = [];
    for(const n in grph.nodes) {
      const node = {
        id: parseInt(n),
        value: 1,
        label: ""+grph.nodes[n],
      };
      nodes.push(node);
    }
    for(const e in grph.edges) {
      const edge = {
        id: e,
        from: parseInt(grph.edges[e].from),
        to: parseInt(grph.edges[e].to),
        value: weighted? grph.edges[e].weight : 1,
        weight: grph.edges[e].weight,
        _label: ""+(grph.edges[e]?.weight? grph.edges[e].weight : 1),
        color: {highlight: "#AB56CC"}
      };
      if(weighted) {
        // @ts-ignore
        edge["label"] = edge._label;
      }
      edges.push(edge);
    }

    graph.edges = edges;
    graph.nodes = nodes;
    setGraph(graph);
    if(visible) {
      if(!network.current) {
        errorNotification("Error", "Please try again!");
        return false;
      }
      network.current?.Network.setData({nodes: nodes, edges: edges});
    }
    cleanGraph();
    return true;
  }
  const getNodeId = (node_label: any) => {
    for(const i in graph.nodes) {
      if(graph.nodes[i].label.trim() === node_label.trim()) {
        return graph.nodes[i].id;
      }
    }
    return node_label;
  }
  const mapEdges = () => {
    const edges = graph.edges;
    for(const e in edges) {
      const from_node = parseInt(getNodeId(edges[e].from));
      const to_node = parseInt(getNodeId(edges[e].to));
      edges[e].from = from_node;
      edges[e].to = to_node;
    }
    graph.edges = edges;
    setGraph(graph);
  }
  const cleanGraph = () => {
    for(const e in graph.edges) {
      graph.edges[e].value = 1;
      delete graph.edges[e].label;
    }
  }
  const generateProcessableGraph = (nodes_list:any[], edges_list:any[]) => {
    try {
      const nodes = []
      const edges = []
      for(let i=0; i<nodes_list.length; i++) {
        console.log(nodes_list[i])
        if(nodes_list[i] == "") {
          continue;
        }
        const node = {
          id: i,
          value: 1,
          label: nodes_list[i]
        }
        nodes.push(node);
      }

      for(let i=0; i<edges_list.length; i++) {
        console.log(edges_list[i])
        if(edges_list[i] == "") {
          continue;
        }
        const edge_data = edges_list[i].split(",");
        const edge = {
          id: i,
          from: edge_data[0],
          to: edge_data[1],
          value: weighted? edge_data[2] : 1,
          weight: edge_data[2],
          _label: ""+(edge_data[2] ? edge_data[2] : 1),
          color: {highlight: "#AB56CC"}
        }
        if(weighted) {
          // @ts-ignore
          edge["label"] = edge._label;
        }
        edges.push(edge);
      }

      return {nodes: nodes, edges: edges};
    } catch (error) {
      console.log(error);
      throw Error("Unprocessable corrupted dataset!");
    }
  }

  const onNodeFileChange = (e: any) => {
    const file = e.target.files[0];
    console.log();
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      try {
        let data: any = reader.result;
        const lines = data.split("\n");
        console.log((lines));
        const nodes = []
        if (lines.length > 1) {
          for (let i = 1; i < lines.length; i++) {
            console.log(lines[i])
            if (!lines[i] || lines[i].trim() == "") {
              continue;
            }
            const node = {
              id: i - 1,
              value: 1,
              label: lines[i].trim()
            }
            nodes.push(node);
          }
        }
        // graph.nodes = nodes;
        // setGraph(graph);
        setCustomNodes(nodes);
      } catch (e) {
        errorNotification("Error", "Invalid nodes file!");
      }
    });
    reader.readAsText(file);
  };
  const onEdgeFileChange = (e: any) => {
    let isIncorrectWeight = false;
    const file = e.target.files[0];
    console.log();
    const reader = new FileReader();
    reader.addEventListener("loadend", () => {
      try {
        let data: any = reader.result;
        const lines = data.split("\n");
        console.log((lines));
        const edges = []
        if (lines.length > 1) {
          for (let i = 1; i < lines.length; i++) {
            console.log(lines[i])
            if (!lines[i] || lines[i].trim() == "") {
              continue;
            }
            const edge_data = lines[i].split(",");
            console.log("edge data", edge_data)
            let weight = 1
            if (edge_data.length > 2) {
              if(!isIncorrectWeight) {
                console.log("not incorrect: ", parseInt(edge_data[2].trim(), 10))
                const res = parseInt(edge_data[2].trim(), 10);
                if(!isNaN(res)) {
                  weight = edge_data[2].trim();
                } else {
                  isIncorrectWeight = true;
                  infoNotification("Info", "Invalid weights value(s). Weights will be ignored!");
                }
              }
            }
            const edge = {
              id: i - 1,
              from: edge_data[0].trim(),
              to: edge_data[1].trim(),
              value: weighted ? weight : 1,
              weight: weight,
              _label: "" + weight,
              color: {highlight: "#AB56CC"}
            }
            if (weighted) {
              // @ts-ignore
              edge["label"] = edge._label;
            }
            edges.push(edge);
          }
        }
        setCustomEdges(edges);
      } catch (e) {
        errorNotification("Error", "Invalid edges file!");
      }
      // graph.edges = edges;
      // setGraph(graph);
    });
    reader.readAsText(file);
  };
  const radioChange = (event: RadioChangeEvent) => {
    graph.nodes = []
    graph.edges = []
    setGraph(graph);
    options.physics.enabled = true;
    setOptions(options);
    network.current?.Network.setOptions(options);
    if(network.current?.Network) {
      network.current.Network.body.data.nodes = graph.nodes;
      network.current.Network.body.data.edges = graph.edges;
      network.current.Network.setOptions(options);
    }
    setCustomEdges([]);
    setCustomNodes([]);
    sessionData.session_data = []
    setSessionData(sessionData);
    setSessionSavable(false);
    setGlobalSessionSave(false);
    setVisible(false);
    if(event.target.value == "random" || event.target.value == "small_world") {
      setCustomVisible(false);
      setShowSliders(true);
    } else if (event.target.value == "custom"){
      setCustomVisible(true);
      setShowSliders(false);
    } else {
      setCustomVisible(false);
      setShowSliders(false);
    }
    successNotification("Success", "Dataset changed!");
  }
  const removeWeight = async (val: CheckboxChangeEvent) => {
    const chk = val.target.checked;
    setWeighted(chk);
    if (network.current) {
      const edges = network.current?.Network.body.data.edges._data;
      if(chk) {
        for (const e in edges) {
          console.log(graph.edges)
          const weight = graph.edges[e].weight;
          const label = graph.edges[e]._label;
          network.current?.Network.body.data.edges.update({id: e, label: label});
          network.current?.Network.body.data.edges.update({id: e, value: weight});
        }
      } else {
        network.current?.Network.body.data.edges.clear();
        network.current?.Network.body.data.edges.update(graph.edges);
      }
    } else {
      if(chk) {
        for(const e in graph.edges) {
          graph.edges[e].value = graph.edges[e].weight;
          graph.edges[e].label = graph.edges[e]._label;
        }
      } else {
        for(const e in graph.edges) {
          graph.edges[e].value = 1;
          delete graph.edges[e].label;
        }
      }
      setGraph(graph);
    }
  }
  const generateGraph = async () => {
    const num = form.getFieldValue("num_nodes");
    const density = form.getFieldValue("density");
    const selectedRadio = form.getFieldValue("data_option");
    try {
      if(selectedRadio === "random" || selectedRadio === "small_world") {
        console.log(selectedRadio);
        let result;
        if(selectedRadio === "small_world") {
          if(num < 5) {
            errorNotification("Error", "Small world requires more than 5 nodes!");
            setLoading(false);
            return;
          }
          result = await post("modules/network/generateSmallWorld", {
            num_nodes: num,
            density: density
          });
        } else {
          result = await post("modules/network/generateRandomNetwork", {
            num_nodes: num,
            density: density
          });
        }
        // console.log(result?.data?.data.graph);
        const res = processGraph(result?.data?.data.graph);
        if(!res) {
          return;
        }
        setVisible(true);
      } else if(selectedRadio === "custom") {
        if(customNodes.length < 1){
          errorNotification("Error", "Upload nodes file!");
          return;
        }
        if(customEdges.length < 1){
          errorNotification("Error", "Upload edges file!");
          return;
        }
        try {
          graph.nodes = customNodes;
          graph.edges = customEdges;
          setGraph(graph);
          mapEdges();
          if (visible) {
            network.current?.Network.setData({nodes: graph.nodes, edges: graph.edges});
          }
          console.log(graph)
          setVisible(true);
          cleanGraph();
          setCustomNodes([])
          setCustomEdges([])
          form.setFieldsValue({
            cus_nodes: null,
            cus_edges: null,
          })
        } catch (e) {
          errorNotification("Error", "Network failed to process. Please check nodes/edges files!");
        }
      } else {
        console.log("option", selectedRadio);
        const data_files = sampleFiles[selectedRadio];
        const result = await post("datasets/get_dataset_data/", {
          dataset_id: data_files["nodes_id"],
        });
        console.log(result)
        const result2 = await post("datasets/get_dataset_data/", {
          dataset_id: data_files["edges_id"],
        });
        console.log(result2);
        let decode = atob(result.data.dataset).split("\n");
        const nodes_list: any[] = decode.slice(1,);
        const edges_list: any[] = atob(result2.data.dataset).split("\n").slice(1,);
        console.log(nodes_list)
        const grph = generateProcessableGraph(nodes_list, edges_list);

        graph.nodes = grph.nodes;
        graph.edges = grph.edges;
        setGraph(graph);
        mapEdges();
        if(visible) {
          network.current?.Network.setData({nodes: graph.nodes, edges: graph.edges});
        }
        setVisible(true);
      }
      // setStepData();
      // setSessionSavable(true);
      successNotification("Success", "Network generated!");
      infoNotification("Info", "Stabilizing the network!");
    } catch (error) {
      errorNotification("Error", error.message);
      setLoading(false);
    }
  }

  return (
    <div className="component-box">
      <h2>Upload Network Files</h2>
      <Form layout="vertical" form={form} style={{marginBottom: 10}}>
        <h1>Select sample datasets</h1>
        <Form.Item name={"data_option"}>
          <Radio.Group onChange={radioChange}>
            {
              sampleFiles.map((dataset:any, index) => <Radio value={index}>{dataset.name}</Radio>)
            }
            <Radio value={"random"}>Random</Radio>
            <Radio value={"small_world"}>Small World</Radio>
            <Radio value={"custom"}>Custom</Radio>
          </Radio.Group>
        </Form.Item>
          { showSliders ?
            <Row style={{marginTop: "15px"}}>
              <Col flex={2} style={{marginRight: 15}}>
                <h1>No of Nodes</h1>
                <Form.Item name="num_nodes" initialValue={10}>
                  <Slider
                      min={0}
                      max={30}
                      marks={{
                        0: "0",
                        3: "3",
                        6: "6",
                        9: "9",
                        12: "12",
                        15: "15",
                        18: "18",
                        21: "21",
                        24: "24",
                        27: "27",
                        30: "30"
                      }}
                      defaultValue={10}
                  />
                </Form.Item>
              </Col>
              <Col flex={2}>
                <h1>Network Density</h1>
                <Form.Item name="density" initialValue={0.5}>
                  <Slider
                      min={0.0}
                      max={1.0}
                      marks={{
                        0: "0",
                        0.1: "0.1",
                        0.2: "0.2",
                        0.3: "0.3",
                        0.4: "0.4",
                        0.5: "0.5",
                        0.6: "0.6",
                        0.7: "0.7",
                        0.8: "0.8",
                        0.9: "0.9",
                        1.0: "1"
                      }}
                      step={0.1}
                      defaultValue={0.5}
                  />
                </Form.Item>
              </Col>
            </Row>
          : null}
          { customVisible ? <div style={{marginTop: 10}}>
                <Form.Item label="Node File" name="cus_nodes">
                  <Input type="file" accept=".csv,text/plain" onChange={onNodeFileChange}/>
                </Form.Item>
                <Form.Item label="Edge File" name="cus_edges">
                  <Input type="file" accept=".csv,text/plain" onChange={onEdgeFileChange}/>
                </Form.Item>
                <Form.Item>
                    <div>
                    <CSVLink data={atob("dmVydGljZQowCjEKMgozCjQKNQo2CjcKOAo5")} filename={"sample_nodes.csv"}>
                      Sample Nodes File
                    </CSVLink>
                    </div>
                    <div>
                    <CSVLink data={atob("dmVydGljZTEsdmVydGljZTIsdmFsdWUKMCwxLDAuMQowLDIsMC41IAoxLDMsMC4xCjEsNCwwLjQKMSw4LDAuNAoyLDMsMC4xCjIsNiwwLjIKMiw3LDAuMwoyLDgsMC40CjMsOCwwLjEKNCw1LDAuMQo1LDgsMC4zCjYsNywwLjQ=")} filename={"sample_edges.csv"}>
                      Sample Edges File
                    </CSVLink>
                    </div>
                </Form.Item>
              </div>
              : null
          }
          <h1 style={{marginTop: 10}}>Set network parameters</h1>
          <Form.Item name="dir_check" style={{float: "left", marginRight: 10}} valuePropName="checked">
            <Checkbox onChange={(val)=> {
              const chk = val.target.checked;
              setDirected(chk);
              if (chk) {
                options.edges.arrows = "to";
                setOptions(options);
                network.current?.Network.setOptions(options);
              } else {
                options.edges.arrows = "";
                setOptions(options);
                network.current?.Network.setOptions(options);
              }
            }}>Directed</Checkbox>
          </Form.Item>
          <Form.Item name="w_check" valuePropName="checked">
            <Checkbox disabled={graph.edges.length > 100} onChange={removeWeight}>Weighted</Checkbox>
          </Form.Item>
          <div className="" style={{ marginTop: 20, clear: "both"}}>
            <Button type="primary" onClick={async ()=>{
              generateGraph();
              setDisablePhysics(false);
              // cleanGraph();
            }}>Visualize</Button>
            {" "}
            <Button type="primary" disabled={disablePhysics} onClick={async ()=>{
              options.physics.enabled = false;
              setOptions(options);
              network.current?.Network.setOptions(options);
              setStepData();
              setSessionSavable(true);
              setGlobalSessionSave(true);
              setDisablePhysics(true);
              // cleanGraph();
            }}>Disable Physics</Button>
          </div>
        {/*</Form.Item>*/}
      </Form>
      { visible?
          <Graph
              ref={network}
              className={"myNetwork"}
              graph={graph}
              options={options}
              events={events}
              style={{height: "640px"}}
              getNetwork={(network: any) => {
                setGraphNet(network);
              }}
        />
      : null}

    </div>
  );
};
