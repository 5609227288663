import React, { FC, useContext, useEffect, useState } from "react";
import { Image, Button, Form, Table, Row, Col, Select, Spin } from "antd";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import { ClusteringContext } from "./ClusteringContext";
import { CSVLink } from "react-csv";
import { errorNotification, successNotification } from "../../Services/NotificationService";
import "../../Pages/styles.scss";
import {NavigationContext} from "../SideNavigation/SideNavigation";

export const StepOnePlot: FC = () => {
  const { post } = useConnection();
  const [form] = Form.useForm()
  const {
    file,
    variableList,
    plot,
    setPlot,
    tableData,
    setTableData,
    setRandomSeed,
    setClusterCount,
    columns,
    setColumns,
    loadingGraph,
    setLoadingGraph,
    axisValue,
    setAxisValue,
    sessionData,
    setSessionData,
    stepOneData,
    setSessionSavable,
    resetFields
  } = useContext(ClusteringContext);

  const {setGlobalSessionSave} = useConfig();

  const [axis, setAxis] = useState<any>({
    y: false,
    x: false,
  });
  const { Option } = Select;
  const columnX = [
    {
      title: "Point ID",
      dataIndex: "point_id",
      key: "point_id",
      sorter: (a: any, b: any) => a.point_id - b.point_id,
    },
    {
      title: "X",
      dataIndex: "x",
      key: "x",
      sorter: (a: any, b: any) => a.x - b.x,
    },
    {
      title: "Y",
      dataIndex: "y",
      key: "y",
      sorter: (a: any, b: any) => a.y - b.y,
    },
  ];

  useEffect(() => {
    console.log('hellow')
  },[])
  useEffect(() => {
    // set the data from stepOneData
    restoreStepOneSession();
  }, [stepOneData]);
  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      setTableData([]);
      setPlot('');
    }
  });

  const setData = (step_one_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_one_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == 'step 1') {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_one_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const restoreStepOneSession = () => {
    if(stepOneData?.response_data?.data) {
      setAxisValue({ ...axisValue, x: stepOneData?.request_data.x, y: stepOneData?.request_data.y });
      setAxis({...axis,x:true,y:true})
      form.setFieldsValue({
        x: stepOneData.request_data.x,
        y: stepOneData.request_data.y
      });
      setColumns(columnX);
      setPlot(stepOneData?.response_data?.data.plot);
      setTableData(stepOneData?.response_data?.data.plot_data);
      setRandomSeed(stepOneData?.response_data?.data.random_seed);
    }
  }

  const onSubmit = async (values: any) => {
    console.log(values);
    console.log("------ step one file", file);

    if(values.x === values.y){
      errorNotification("Error","Values for X axis and Y axis cannot be same");
      return;
    }

    setAxisValue({ ...axisValue, x: values.x, y: values.y });
    try {
      setLoadingGraph(true);
      const result = await post("modules/clustering/generatePlot", {
        data: file,
        x: values.x,
        y: values.y,
      });
      console.log(result);
      result.data.data.plot = "data:image/jpeg;base64," + result.data.data.plot;
      setColumns(columnX);
      setPlot(result.data.data.plot);
      setTableData(result.data.data.plot_data);
      setRandomSeed(result.data.data.random_seed);
      setLoadingGraph(false);

      const step_one_data = {
        step: 'step 1',
        request_data: {
          data: file,
          x: values.x,
          y: values.y
        },
        response_data: {
          data: result.data.data
        }
      }

      setData(step_one_data);
      // session can be saved
      setSessionSavable(true);
      setGlobalSessionSave(true);
      setClusterCount(2);
      successNotification("Sucess", "Plot Generated Successfully");
    } catch (error) {
      errorNotification("Error", error.message)
      setLoadingGraph(false);
      console.log(error);
    }
  };

  useEffect(() => {
   
    form.resetFields()
    setAxis({...axis,x:false,y:false})
  },[variableList])

  return (
    <Spin spinning={loadingGraph}>
      <div className="component-box">
        <h2>Step 1: Plot</h2>

        <Form onFinish={onSubmit} layout="vertical"  form = {form} >
          <Row gutter={16}>
            <Col span={10}>
              <h3>X axis:</h3>
              <Form.Item name="x">
                <Select onChange={() => setAxis({ ...axis, x: true })}>
                  {variableList.map((axis: string) => (
                    <Option value={axis}>{axis}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <h3>Y axis:</h3>
              <Form.Item name="y">
                <Select onChange={() => setAxis({ ...axis, y: true })}>
                  {variableList.map((axis: string) => (
                    <Option value={axis}>{axis}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              style={{ width: "100px" }}
              htmlType="submit"
              disabled={!(axis.y && axis.x) || file.localeCompare("") == 0}
            >
              Plot
            </Button>
          </Form.Item>
        </Form>

        {plot && (
          <Row justify="center">
            <Col>
              <Image src={plot} height={750} width={900} />
            </Col>
          </Row>
        )}
        <div className="">
          {tableData.length > 0 && (
            <>
              <Table
                columns={columns}
                bordered
                pagination={{
                  pageSizeOptions: ["10", "15", "25"],
                  showSizeChanger: true,
                }}
                dataSource={tableData}
              />
              <div className="" style={{ marginTop: 15 }}>
                <Button type="primary">
                  <CSVLink data={tableData} filename={"Plot_Data.csv"}>
                    Download
                  </CSVLink>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </Spin>
  );
};
