import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";

export interface Response<T = any> {
  data: T;
  statusText: string;
  status?: number;
  headers: any;
  config?: AxiosRequestConfig;
  request?: any;
  message: string;
}

export type Methods = "get" | "post" | "delete" | "put" | "patch";
export type ConnectionProps = {
  post<T = any, R = Response<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
  get<T = any, R = Response<T>>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<R>;
  delete<T = any, R = Response<T>>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<R>;
  put<T = any, R = Response<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
  patch<T = any, R = Response<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
  updateToken: (token?: string) => void;
};
export type RoleProp = "1" | "2" | "3";
export type UserProps = {
  email?: string;
  role: RoleProp;
  token?: string;
  decoded?: { [key: string]: string };
  setEmail: (val: string) => void;
  setDecoded: (val: { [key: string]: string }) => void;
  setToken: (val: string) => void;
  setRole: (val: RoleProp) => void;
};
export type ConfigProps = {
  globalSessionSave: boolean;
  setGlobalSessionSave: (val: boolean) => void;
}
const ApplicationContext = React.createContext<{
  connection?: ConnectionProps;
  user?: UserProps;
  module? :any;
  config?: ConfigProps;
}>({});

type ApplicationContextProviderProps = {
  serverSideUrl: string;
  apiPrefix: string;
  children: ReactNode;
};
export const ApplicationContextProvider: FC<ApplicationContextProviderProps> = (
  props: ApplicationContextProviderProps
) => {
  const [token, setToken] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [decoded, setDecoded] = useState<{ [key: string]: string }>();
  const [role, setRole] = useState<RoleProp>("3");
  const [moduleDetails,setModuleDetails] = useState<any>([]);
  const [globalSessionSave, setGlobalSessionSave] = useState<boolean>(false);

  const { serverSideUrl, apiPrefix, children } = props;
  useEffect(() => {
    if (localStorage.getItem("token") !== null && token === undefined) {
      const temp = localStorage.getItem("token");
      if (temp != null) {
        const decodedX: { [key: string]: string } = jwt_decode(temp);
        setDecoded(decodedX);
        setToken(temp);
        setRole(decodedX.usr as RoleProp);
        setEmail(decodedX.sub);
      }
    }
  }, []);
  useEffect(() => {
    const timer = setInterval(async () => {
      const newToken: any = await localStorage.getItem("token");
      if (token !== newToken) {
        setToken(newToken);
      }
    }, 3000);
    if (token) {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [token]);

  const capitalizeFirstLetter = (str: any) => {
    if(str && typeof str === 'string') {
      str = str.toLowerCase();
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return "";
    }
  }

  const send = useCallback(
    (
      method: Methods,
      path: string,
      data?: any,
      config?: AxiosRequestConfig
    ) => {
      return new Promise((resolve, reject) => {
        const url = `${serverSideUrl}/${apiPrefix}/${path}`;
        let headers: any;
        const user_token = localStorage.getItem("token");
        if (user_token) {
          headers = { authorization: `Bearer ${user_token}` };
        }
        axios({
          method,
          url,
          data,
          headers,
          ...config,
        })
          .then((response: AxiosResponse) => {
            if (response.status) {
              if (response.status === 200) {
                resolve({
                  status: response.status,
                  data: response.data,
                  statusText: "SUCCESS",
                });
              } else if (response.status === 201) {
                resolve({
                  status: response.status,
                  data: response.data.message,
                  statusText: "CREATED",
                  message: capitalizeFirstLetter(response.data.message),
                });
              } else if (response.status === 422) {
                reject({
                  status: response.status,
                  data: response.data?.data,
                  statusText: "ERROR",
                  message: capitalizeFirstLetter(response.data.message),
                  errors: response.data?.errors,
                });
              }
            } else {
              reject({
                status: 500,
                statusText: "UNKNOWN",
                message: "Something went wrong",
              });
            }
          })
          .catch((e: any) => {
            
            if (e.response) {
              console.log(e.response)
              if (e.response.status) {
                if (e.response.status === 403) {
                  localStorage.removeItem("token");
                  window.location.reload();
                }

                reject({
                  status: e.response.status,
                  statusText: "ERROR",
                  errors: e.response.data?.errors,
                  message: e.response.data.message ? capitalizeFirstLetter(e.response.data.message) : capitalizeFirstLetter(e.response.data.detail),
                });
              } else {
                reject({
                  statusText: "ERROR",
                  message: "Something went wrong",
                });
              }
            } else {
              reject({
                statusText: "ERROR",
                message: "Network error",
              });
            }
          });
      }) as any;
    },

    [token, serverSideUrl]
  );

  const post = useCallback(
    (path: string, data?: any, config?: AxiosRequestConfig) => {
      return send("post", path, data, config);
    },
    [send]
  );
  const put = useCallback(
    (path: string, data?: any, config?: AxiosRequestConfig) => {
      return send("put", path, data, config);
    },
    [send]
  );
  const get = useCallback(
    (path: string, config?: AxiosRequestConfig) => {
      return send("get", path, undefined, config);
    },
    [send]
  );
  const patch = useCallback(
    (path: string, data?: any, config?: AxiosRequestConfig) => {
      return send("patch", path, data, config);
    },
    [send]
  );
  const del = useCallback(
    (path: string, config?: AxiosRequestConfig) => {
      return send("delete", path, config);
    },
    [send]
  );

  const updateToken = useCallback(async (tokenx?: string) => {
    console.log("----------------------token-------------");
    if (tokenx != null) {
      await localStorage.setItem("token", tokenx);
      setToken(tokenx);
    } else {
      await localStorage.removeItem("token");
      setToken(undefined);
    }
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        user: {
          decoded,
          email,
          role,
          setDecoded,
          setEmail,
          setRole,
          setToken,
          token
        },
        connection: { post, put, get, patch, delete: del, updateToken },
        module:{moduleDetails,setModuleDetails},
        config: {globalSessionSave, setGlobalSessionSave},
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContext;

export const useConnection = () => {
  const context = useContext(ApplicationContext);

  return context.connection!;
};
export const useUser = () => {
  const context = useContext(ApplicationContext);

  return context.user!;
};

export const useModule = () => {
  const context = useContext(ApplicationContext);

  return context.module!;
};

export const useConfig = () => {
  const context = useContext(ApplicationContext);
  return context.config!;
}