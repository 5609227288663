import { Button, Image } from "antd";
import React, {FC, useContext, useEffect, useState} from "react";
import { useConnection } from "../../Services/ApplicationContext";
import { ImageContext } from "./ImageContext/ImageContext";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";

export const RgbAnalysis: FC = (props) => {
  const { image, stepTwoData, setData, resetFields } = useContext(ImageContext);
  const [rgb, setRGB] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { post } = useConnection();

  useEffect(() => {
    restoreStepTwoSession();
  }, [stepTwoData]);

  useEffect(() => {
    if(resetFields) {
      setRGB([]);
    }
  }, [resetFields]);

  const restoreStepTwoSession = () => {
    if(stepTwoData?.response_data?.data) {
      const base64 = stepTwoData?.request_data?.image.split(",")[0];
      const blue = base64 + "," + stepTwoData?.response_data.data?.b_base64_image;
      const green = base64 + "," + stepTwoData?.response_data.data?.g_base64_image;
      const red = base64 + "," + stepTwoData?.response_data.data?.r_base64_image;
      setRGB([blue, green, red]);
    }
  }

  const processImage = async () => {
    const base64 = image.split(",")[0];
    setLoading(true);
    try {
      const result = await post("modules/image/rgbAnalysis", {
        image: image.split(",")[1],
      });
      console.log(result);
      const blue = base64 + "," + result?.data?.data?.b_base64_image;
      const green = base64 + "," + result?.data?.data?.g_base64_image;
      const red = base64 + "," + result?.data?.data?.r_base64_image;
      setRGB([blue, green, red]);

      const step_two_data = {
        step: 'step 2',
        request_data: {
          image: image
        },
        response_data: {
          data: result.data.data
        }
      }
      await setData(step_two_data);

      successNotification("Success", "RGB successful!");
      setLoading(false);
    } catch (error) {
      errorNotification("Error", error.message)
      setLoading(false);
    }
  };

  return (
    <div className="component-box">
      <div>
        <h2>STEP 2 : RGB Analysis</h2>
        <Button
          type="primary"
          onClick={processImage}
          loading={loading}
          disabled={image.localeCompare("") == 0 ? true : false}
        >
          Convert
        </Button>
      </div>
      <div className={"img-cont"}>
        {rgb.map((image: any) => (
          <Image src={image} />
        ))}
      </div>
    </div>
  );
};
