import React, {FC, useContext, useEffect, useState} from "react";
import {errorNotification, successNotification} from "../../Services/NotificationService";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import {NavigationContext} from "../SideNavigation/SideNavigation";

export type ClusteringContextProps = {
  file: string;
  setFile: (val: string) => void;
  loadingGraph: boolean;
  setLoadingGraph: (val: boolean) => void;
  variableList: string[];
  setVariableList: (val: string[]) => void;
  plot: string;
  setPlot: (val: string) => void;
  tableData: any;
  setTableData: (val: any) => void;
  randomSeed: number;
  setRandomSeed: (val: number) => void;
  clusterCount: number;
  setClusterCount: (val: number) => void;
  columns : object[];
  setColumns : (val:any) => void
  axisValue : any;
  setAxisValue : (val:any) => void;
  sessionData: any;
  setSessionData: (val: any) => void;
  stepOneData: any;
  setStepOneData: (val: any) => void;
  stepTwoData: any;
  setStepTwoData: (val: any) => void;
  stepThreeData: any;
  setStepThreeData: (val: any) => void;
  sessionSavable: boolean;
  setSessionSavable: (val: boolean) => void;
  saveSession: () => Promise<void>;
  restoreSession: () => Promise<void>;
  resetFields: boolean;
  setResetFields: (val: boolean) => void;
};

export const ClusteringContext = React.createContext<ClusteringContextProps>({
  file: "",
  setFile: () => {},
  loadingGraph: false,
  setLoadingGraph: () => {},
  variableList: [],
  setVariableList: () => {},
  plot: "",
  setPlot: () => {},
  tableData: "",
  setTableData: () => {},
  randomSeed: 0,
  setRandomSeed: () => {},
  clusterCount: 1,
  setClusterCount: () => {},
  columns : [],
  setColumns : () => {},
  axisValue : {},
  setAxisValue: () => {},
  sessionData: {},
  setSessionData: () => {},
  stepOneData: {},
  setStepOneData: () => {},
  stepTwoData: {},
  setStepTwoData: () => {},
  stepThreeData: {},
  setStepThreeData: () => {},
  sessionSavable: false,
  setSessionSavable: () =>{},
  saveSession: async () => {},
  restoreSession: async () => {},
  resetFields: false,
  setResetFields: () =>{},
});

export const ClusteringContextProvider: FC = (props) => {
  const { post } = useConnection();
  const {setGlobalSessionSave} = useConfig();
  const [file, setFile] = useState<any>("");
  const [loadingGraph, setLoadingGraph] = useState<any>(false);
  const [variableList, setVariableList] = useState<any>([]);
  const [plot, setPlot] = useState<string>("");
  const [tableData, setTableData] = useState([]);
  const [randomSeed, setRandomSeed] = useState<number>(-1);
  const [clusterCount, setClusterCount] = useState<number>(0);
  const [columns,setColumns] = useState<any>([]);
  const [axisValue,setAxisValue] = useState<any>({x:"",y:""});
  const [sessionData, setSessionData] = useState<any>({
      module: "clustering",
      session_data: []
  });
  const [stepOneData, setStepOneData] = useState<any>({
      step: "step 1",
      request_data: {
          data: '',
          x: '',
          y: ''
      },
      response_data: {
          data:null
      }
  });
  const [stepTwoData, setStepTwoData] = useState<any>({
      step: "step 2",
      request_data: {
          data: '',
          clusters:0,
          random_seed:0,
          x: '',
          y: ''
      },
      response_data: {
          data:null
      }
  });
  const [stepThreeData, setStepThreeData] = useState<any>({
        step: "step 3",
        request_data: {
            data: '',
            clusters:0,
            random_seed:0,
            dimensions: []
        },
        response_data: {
            data:null
        }
    });
  const [sessionSavable, setSessionSavable] = useState<boolean>(false);
  const [resetFields, setResetFields] = useState<boolean>(false);

  const saveSession = async () => {
        console.log("save session")
        if(sessionSavable) {
            console.log("saving")
            try{
                const result = await post("session/save", sessionData);
                setGlobalSessionSave(false);
                successNotification("Success", "Session saved!")
            } catch (error) {
                console.log("Exception: save session: text ", error);
                errorNotification("Error", error.message);
            }
        }
  }
  const restoreSession = async () => {
        try{
            setResetFields(true);
            setResetFields(false);
            const result = await post("session/restore", { module: "clustering" });
            console.log(result)
            // setRestoreFieldClear(!restoreFieldClear);
            const s_data = result?.data?.data;
            for(let i=0; i<s_data.session_data?.length; i++) {
                const step = s_data.session_data[i];
                switch (step.step) {
                    case "step 1": {
                        console.log("restoring step 1");
                        setStepOneData(step);
                    } break;
                    case "step 2": {
                        console.log("restoring step 2");
                        setStepTwoData(step);
                    } break;
                    case "step 3": {
                        console.log("restoring step 3");
                        setStepThreeData(step);
                    } break;
                }
            }
            setSessionData(s_data);
            setSessionSavable(true);
            successNotification("Success", "Session restored!")
        } catch (error) {
            console.log("Exception: restore session: clustering ", error);
            errorNotification("Error", error.message);
        }
    }

  useEffect(() => {
      setGlobalSessionSave(sessionSavable);
  }, [sessionSavable]);

  return (
    <ClusteringContext.Provider
      value={{
        file,
        setFile,
        loadingGraph,
        setLoadingGraph,
        variableList,
        setVariableList,
        plot,
        setPlot,
        tableData,
        setTableData,
        randomSeed,
        setRandomSeed,
        clusterCount,
        setClusterCount,
        columns,
        setColumns,
        axisValue,
        setAxisValue,
        sessionData,
        setSessionData,
        stepOneData,
        setStepOneData,
        stepTwoData,
        setStepTwoData,
        stepThreeData,
        setStepThreeData,
        sessionSavable,
        setSessionSavable,
        saveSession,
        restoreSession,
        resetFields,
        setResetFields
      }}
    >
      {props.children}
    </ClusteringContext.Provider>
  );
};

export default ClusteringContextProvider;
