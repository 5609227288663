import React, { FC, useEffect, useState } from "react";
import {Button, Table, Spin, Modal} from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import {useHistory} from 'react-router-dom'
import "../styles.scss";

export const LicenseTable: FC = () => {
  const { get, post } = useConnection();
  const [classDetails, setClassDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible,setVisible] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    getClass();
  }, []);

  const getClass = async () => {
    setLoading(true);
    console.log('++++++++++++++++++++++++++++++++++++')
    try {
      const result = await get("classes/view_classes/");
      console.log('res', result)
      setClassDetails(
        result?.data?.classes.map((detail: any) => {
          console.log(detail)
          detail.end_date = moment(detail.end_date).format("YYYY-MM-DD");
          detail.start_date = moment(detail.start_date).format("YYYY-MM-DD");
          detail.licenses_left = (parseInt(detail.num_students) - parseInt(detail.enrolled_count.num_students)) + "/" + detail.num_students;
          return detail;
        })
      );
      console.log( result?.data?.classes);
      setLoading(false);
    } catch (error) {
      errorNotification("Error", error.message)
    }
  };

  //delete class
  const deleteClass = async (id: string) => {
    try {
      setLoading(true)
      console.log(typeof id);
      const result = await post("classes/delete_class/", {
        class_id: id,
      });

      setClassDetails(
        classDetails.filter((classDeatail: any) => classDeatail.class_id != id)
      );
      successNotification("Success", "Class deleted successfully!");
      console.log(result);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      errorNotification("Error", error.message)
    }
  };

  const confirmDelete = async (id: string) => {
    const modal = Modal.confirm({
      title: 'Warning',
      content: `Confirm delete?`,
      centered: true,
      onOk() {
        deleteClass(id);
        modal.destroy();
      },
      onCancel() {
        modal.destroy();
      }
    });
  }

  const columns = [
    {
      title: "Class Name",
      dataIndex: "license_name",
      key: "license_name",
      sorter: (a: any, b: any) => a.license_name.localeCompare(b.license_name),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      sorter: (a: any, b: any) =>
        moment(a.start_date).unix() - moment(b.start_date).unix(),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      sorter: (a: any, b: any) =>
        moment(a.end_date).unix() - moment(b.end_date).unix(),
    },
    {
      title: "Datasets",
      dataIndex: "datasets",
      key: "datasets",
      sorter: (a: any, b: any) => a.datasets.length - b.datasets.length,
      render: (datasets: any) => (
        <>
          {datasets.map((dataset: string) => {
            // const data = module.split("_");
            // module =
            //   data[0].charAt(0).toUpperCase() +
            //   data[0].slice(1) +
            //   " " +
            //   (data[1].charAt(0).toUpperCase() + data[1].slice(1));
            // {
            //   console.log(module);
            // }
            return (
              <div className="">
                <span>{dataset}</span>
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: "Modules",
      dataIndex: "modules",
      key: "modules",
      sorter: (a: any, b: any) => a.modules.length - b.modules.length,
      render: (modules: any) => (
        <>
          {modules.map((module: string) => {
            // const data = module.split("_");
            // module =
            //   data[0].charAt(0).toUpperCase() +
            //   data[0].slice(1) +
            //   " " +
            //   (data[1].charAt(0).toUpperCase() + data[1].slice(1));
            // {
            //   console.log(module);
            // }
            return (
              <div className="">
                <span>{module}</span>
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: "Remaining Licenses",
      dataIndex: "licenses_left",
      key: "licenses_left",
      // sorter: (a: any, b: any) => a.license_name.localeCompare(b.license_name),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      render: (_: any, record: any) => {
        return (
          <>
            <Button
                type="primary"
                onClick={() => history.push({
                  pathname : `/updateClass`,
                  state : {
                    id:record.class_id
                  }
                })}
            >
              Edit
            </Button>{" "}
            <Button
              type="primary"
              onClick={() => confirmDelete(record.class_id )}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  return (
     
       
      <Table
        columns={columns}
        dataSource={classDetails}
        bordered
        pagination={{
          pageSizeOptions: ["10", "15", "25"],
          showSizeChanger: true,
        }}
        loading={loading}
      />
     
  );
};
