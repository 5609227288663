import React, {FC, useContext} from "react";
import { DataComponent } from "../../Components/TextMiningComponent/DataComponent";
import { StepOneComponent } from "../../Components/TextMiningComponent/StepOneComponent";
import { StepTwoComponent } from "../../Components/TextMiningComponent/StepTwoComponent";
import { StepThreeComponent } from "../../Components/TextMiningComponent/StepThreeComponent";
import { StepFourComponent } from "../../Components/TextMiningComponent/StepFourComponent";
import { StepFiveComponent } from "../../Components/TextMiningComponent/StepFiveComponent";
import TextContext, { TextContextProvider } from "../../Components/TextMiningComponent/TextContext/TextContext";
import "../styles.scss";
import {Modal} from "antd";

export const TextContainer: FC = () => {
  return (
   <TextContextProvider>
      <TextContainerWrapper />
   </TextContextProvider>
  );
};

export const TextContainerWrapper: FC = () => {
    const { saveSession, restoreSession } = useContext(TextContext);
    const restoreWarn = () => {
        const modal = Modal.confirm({
            title: 'Warning',
            content: `Restore will clear all your unsaved data`,
            onOk() {
                restoreSession();
                modal.destroy();
            },
            onCancel() {
                modal.destroy();
            }
        });
    }
    return (
        <>
            <div>
                <div
                    style={{
                        marginBottom: 20,
                        justifyContent: "center",
                    }}
                >
                    <span
                        style={{
                            fontSize: 30,
                        }}
                    >
                        Text Mining
                    </span>
                    <div
                        style={{
                            float: "right"
                        }}
                    >
                        <button
                            onClick={saveSession}
                            className={"btn-save"}
                        > Save </button>
                        <button
                            onClick={restoreWarn}
                            className={"btn-restore"}
                        > Restore </button>
                    </div>
                    <div style={{clear: 'both'}}></div>
                </div>
                <DataComponent />
                <StepOneComponent />
                <StepTwoComponent />
                <StepThreeComponent />
                <StepFourComponent />
                <StepFiveComponent />
            </div>
        </>
    );
}
