import React, {FC, useContext, useEffect, useState} from "react";
import { Button, Image, Table } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import { ImageContext } from "./ImageContext/ImageContext";
import { CSVLink } from "react-csv";
import { errorNotification, successNotification } from "../../Services/NotificationService";
import "../../Pages/styles.scss";

export const ObjectDetectionComponent: FC = () => {
  const { image, setData, stepSixData, resetFields } = useContext(ImageContext);
  const { post } = useConnection();
  const [loading, setLoading] = useState<boolean>(false);
  const [objectDetection, setObjectDetection] = useState<any>();
  const [tableData, setTableData] = useState<any>([]);

  useEffect(() => {
    restoreStepSixSession();
  }, [stepSixData]);

  useEffect(() => {
    if(resetFields) {
      setObjectDetection('');
    }
  }, [resetFields]);

  const restoreStepSixSession = () => {
    if(stepSixData?.response_data?.data) {
      console.log(stepSixData.response_data.data.objects)
      setTableData(
          stepSixData?.response_data?.data?.objects.map((object: any) => {
            object.probability = parseFloat(object.probability).toFixed(2);
            return object;
          })
      );
      setObjectDetection(stepSixData?.response_data?.data?.processed_image);
    }
  }

  const processImage = async () => {
    const base64 = image.split(",")[0];
    setLoading(true);
    try {
      const result = await post("modules/image/objectDetection", {
        image: image.split(",")[1],
      });
      const dimage = base64 + "," + result?.data?.data?.processed_image;
      console.log(result?.data?.data);
      setTableData(
        result?.data?.data?.objects.map((object: any) => {
          object.probability = object.probability.toFixed(2);
          return object;
        })
      );
      setObjectDetection(dimage);
      setLoading(false);

      result.data.data.processed_image = dimage;
      const step_six_data = {
        step: 'step 6',
        request_data: {
          image: image
        },
        response_data: {
          data: result.data.data
        }
      }
      await setData(step_six_data);
      successNotification("Success","Objection Detection Successful!")
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message)
    }
  };

  const columns = [
    {
      title: "Object Type",
      dataIndex: "object_type",
      key: "object_type",
      sorter: (a: any, b: any) => a.object_type.localeCompare(b.object_type),
    },
    {
      title: "Probability",
      dataIndex: "probability",
      key: "probability",
      sorter: (a: any, b: any) => a.probability - b.probability,
    },
  ];
  return (
    <div className="component-box">
      <div>
        <h2>STEP 6 : Object Detection</h2>
        <Button type="primary" onClick={processImage} loading={loading} disabled = {image.localeCompare("") == 0 ? true:false}>
          Detect
        </Button>
      </div>
      <div className={"img-cont"}>
        {objectDetection && (
          <div>
            <Image src={objectDetection} />
            {tableData.length == 0 ? (
              <div style = {{margin:'10px 0px'}}>No objects detected</div>
            ) : (
              <Table columns={columns} dataSource={tableData} bordered />
            )}
            <div className="">
              <Button type="primary">
                <CSVLink
                  data={tableData}
                  filename={"Object_detetection_probability.csv"}
                >
                  Download
                </CSVLink>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
  
};
