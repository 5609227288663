import React, {FC, useContext, useEffect} from 'react'
import {Form,Radio, Button} from 'antd'
import {NetworkContext} from "./NetworkContext/NetworkContext";
import {successNotification} from "../../Services/NotificationService";
export const NetworkVisualize : FC = () => {
    const {
        options,
        setOptions,
        graphNet,
        sessionSavable,
        stepOneData,
        setStepOneData
    } = useContext(NetworkContext);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            layout: false
        })
    }, []);

    useEffect(() => {
        if(!sessionSavable) {
            form.setFieldsValue({
                layout: false
            })
        }
    }, [sessionSavable])

    useEffect(() => {
        if(stepOneData.restore) {
            form.setFieldsValue({
                layout: stepOneData.request_data.hierarchical
            });
            options.layout.hierarchical.enabled = stepOneData.request_data.hierarchical;
            setOptions(options);
            if(graphNet?.current) {
                graphNet.current.Network.setOption(options);
            }
        }
    }, [stepOneData]);

    const setHierarchical = () => {
        const hier = form.getFieldValue("layout");
        console.log(hier);
        options.layout.hierarchical.enabled = hier;
        options.edges.smooth = false;
        setOptions(options);
        if(graphNet) {
            graphNet.setOptions(options);
        }
        stepOneData.request_data.hierarchical = hier;
        setStepOneData(stepOneData);
        successNotification("Success", "Layout updated!");
    }
    return <div className="component-box">
        <h2>Step 1: Visualize</h2>
        <h1>Update layout</h1>
        <Form form={form}>
            <Form.Item name={"layout"}>
              <Radio.Group>
                <Radio value={false}>Non-Hierarchical</Radio>
                <Radio value={true}>Hierarchical</Radio>
              </Radio.Group>
            </Form.Item>
            <div className="" style = {{marginTop :20}}>
                <Button type="primary" onClick={setHierarchical} disabled={!sessionSavable}>Update</Button>
            </div>
        </Form>
    </div>
}