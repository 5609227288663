import React, { FC, useState, useContext, useEffect } from "react";
import { Button, Spin, Table } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import TextContext from "./TextContext/TextContext";
import ReactApexChart, { Props } from "react-apexcharts";
import { LoadingOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import { Bar } from "react-chartjs-2";
import "../../Pages/styles.scss";

export const StepFiveComponent: FC = () => {
  const { post } = useConnection();
  const { token, filtered, tempToken, sessionData, setSessionData, stepFiveData, resetFields, visualize } = useContext(TextContext);
  const [tableData, setTableData] = useState<any>();
  const [dictionaryData, setDictionaryData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [visibleDictionary, setVisibleDictionary] = useState<boolean>(false);
  const [uniqueTokens, setNoOfUniqueTokens] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [chart, setChart] = useState<any>({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
      },
      xaxis: {
        categories: [],
      },
    },
  });

  useEffect(() => {
    getDictionaryData();
  }, []);

  useEffect(() => {
    // set the data from stepOneData
    restoreStepFiveSession();
  }, [stepFiveData]);

  useEffect(() => {
    if(resetFields) {
      setVisible(false);
    }
  }, [resetFields]);

  const setData = (step_five_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_five_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == 'step 5') {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_five_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const restoreStepFiveSession = () => {
    if(stepFiveData?.response_data?.data) {
      setTableData(stepFiveData?.response_data?.data.token_list);

      const filteredArray = stepFiveData?.response_data?.data?.plot_data.filter(
          (data: any) => data.sentiment_score != "0"
      ).sort((a: any, b: any) => {
        a.sentiment_score = parseInt(a.sentiment_score);
        b.sentiment_score = parseInt(b.sentiment_score);

        if (a.sentiment_score > b.sentiment_score) {
          console.log(a.sentiment_score > b.sentiment_score);
          return 1;
        }
        if (a.sentiment_score < b.sentiment_score) {
          console.log(a.sentiment_score < b.sentiment_score);

          return -1;
        }
        return 0;
      });

      if (filteredArray.length > 0) {
        setChart({
          options: {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
                  },
                },
              ],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Sentiment Score",
                  },
                },
              ],
            },
          },
          data: {
            datasets: [
              {
                label: "Sentiment Analysis",
                backgroundColor: "rgba(0,0,255,0.2)",
                borderColor: "rgba(0,0,255,1)",
                borderWidth: 1,
                data: filteredArray.map((data: any) => {
                  console.log("data count---------", data.count);
                  return data.count;
                }),
              },
            ],
            labels: filteredArray
                .map((data: any) => {
                  console.log("Sentiment data ----- ", data.sentiment_score);
                  return data.sentiment_score;
                }),
          },
        });

        setNoOfUniqueTokens(
            stepFiveData?.response_data?.data.token_list.filter(
                (item: any, i: any, ar: any) => ar.indexOf(item) === i
            ).length
        );
      } else {
        setChart(null);
        setNoOfUniqueTokens(stepFiveData?.response_data?.data?.token_list?.length);
      }

      setVisible(true);
    }
  }

  const getDictionaryData = async () => {
    try {
      const result = await post("modules/text/sentimentAnalysis", {
        tokens: [""],
      });
      console.log(result?.data?.data?.dictionary);
      setDictionaryData(result?.data?.data?.dictionary);
    } catch (error) { errorNotification("Error", error.message)}
  };

  const calculate = async () => {
    setLoading(true);

    try {
      const result = await post("modules/text/sentimentAnalysis", {
        tokens: filtered ? token : tempToken,
      });

      setTableData(result?.data?.data.token_list);

      const filteredArray = result?.data?.data?.plot_data.filter(
        (data: any) => data.sentiment_score != "0"
      ).sort((a: any, b: any) => {
        a.sentiment_score = parseInt(a.sentiment_score);
        b.sentiment_score = parseInt(b.sentiment_score);

        if (a.sentiment_score > b.sentiment_score) {
          console.log(a.sentiment_score > b.sentiment_score);
          return 1;
        }
        if (a.sentiment_score < b.sentiment_score) {
          console.log(a.sentiment_score < b.sentiment_score);

          return -1;
        }
        return 0;
      });


      if (filteredArray.length > 0) {
        setChart({
          options: {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
                  },
                },
              ],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Sentiment Score",
                  },
                },
              ],
            },
          },
          data: {
            datasets: [
              {
                label: "Sentiment Analysis",
                backgroundColor: "rgba(0,0,255,0.2)",
                borderColor: "rgba(0,0,255,1)",
                borderWidth: 1,
                data: filteredArray.map((data: any) => {
                  console.log("data count---------", data.count);
                  return data.count;
                }),
              },
            ],
            labels: filteredArray
              .map((data: any) => {
                console.log("Sentiment data ----- ", data.sentiment_score);
                return data.sentiment_score;
              }),
          },
        });

        setNoOfUniqueTokens(
          result?.data?.data.token_list.filter(
            (item: any, i: any, ar: any) => ar.indexOf(item) === i
          ).length
        );
      } else {
        setChart(null);
        setNoOfUniqueTokens(result?.data?.data?.token_list?.length);
      }

      //stepFive session data
      const step_five_data = {
        step: 'step 5',
        request_data: {tokens: filtered ? token : tempToken},
        response_data: {
          data: result?.data?.data
        }
      }
      //set session data
      setData(step_five_data)

      setVisible(true);
      setLoading(false);
      successNotification("Successful", "Sentiment Analysis Successful!");
    } catch (error) {
      console.log(error.message);

      setLoading(false);
      errorNotification("Error", error.message)
    }
  };

  //defining column
  const columns = [
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      sorter: (a: any, b: any) => a.token.localeCompare(b.token),
    },
    {
      title: "Word Count",
      dataIndex: "word_count",
      key: "word_count",
      sorter: (a: any, b: any) => a.word_count - b.word_count,
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      sorter: (a: any, b: any) => a.sentiment - b.sentiment,
    },
  ];

  // defining dictionary column
  const dictionaryColumn = [
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      sorter: (a: any, b: any) => a.token.localeCompare(b.token),
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      sorter: (a: any, b: any) => a.score.localeCompare(b.score),
    },
  ];

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Step 5: Sentiment Analysis</h2>

        <Button type="primary" onClick={calculate} disabled={!visualize}>
          Calculate
        </Button>
        {visible ? (
          <>
            <Table
              columns={columns}
              pagination={{
                pageSizeOptions: ["10", "15", "25"],
                showSizeChanger: true,
              }}
              dataSource={tableData}
            />
            { chart ?
              <Bar data={chart.data} options={chart.options}/>
            : null}
            <div className="" style={{ marginBottom: 10, marginTop: 5 }}>
              <span style={{ fontWeight: "bold" }}>
                Total Number of Unique Tokens = {uniqueTokens}
              </span>
            </div>
            <div className="">
              <Button type="primary">
                <CSVLink data={tableData} filename={"sentimentAnalysis.csv"}>
                  Download
                </CSVLink>
              </Button>
            </div>
          </>
        ) : null}
        <div className="" style={{ marginBottom: "10px" }}>
          <Button
            type="primary"
            onClick={() => setVisibleDictionary(!visibleDictionary)}
            style={{ marginTop: 10 }}
          >
            {visibleDictionary ? "Hide Dictionary" : "Show Dictionary"}
          </Button>
        </div>
        {visibleDictionary ? (
          <>
            <Table
              columns={dictionaryColumn}
              bordered
              pagination={{
                pageSizeOptions: ["10", "15", "25"],
                showSizeChanger: true,
              }}
              dataSource={dictionaryData === null ? null : dictionaryData}
            />

            {/*<div className="" style={{ marginBottom: 10, marginTop: 5 }}>*/}
            {/*  <span style={{ fontWeight: "bold" }}>*/}
            {/*    Total Number of Unique Tokens = {uniqueTokens}*/}
            {/*  </span>*/}
            {/*</div>*/}
            <div className="">
              <Button type="primary">
                <CSVLink data={dictionaryData} filename={"dictionary.csv"}>
                  Download
                </CSVLink>
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </Spin>
  );
};
