import { notification } from 'antd';

const capitalizeFirstLetter = (str: any) => {
    if(str && typeof str === 'string') {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
        return "";
    }
}

export const successNotification = (message: string, description: string) => {
    description = capitalizeFirstLetter(description);
    notification.success({
        message: message,
        description: description,
    });
};
export const errorNotification = (message: string, description: string) => {
    description = capitalizeFirstLetter(description);
    notification.error({
        message: message,
        description: description,
    });
};

export const infoNotification = (message: string, description: string) => {
    description = capitalizeFirstLetter(description);
    notification.info({
        message: message,
        description: description,
    });
};