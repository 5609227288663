import React, {Children, createContext, FC, useContext, useEffect, useState} from "react";
import {Router, Route, Link, Redirect, useHistory} from "react-router-dom";
import {Button, Layout, Menu, Modal} from "antd";
import "./style.scss";
import { AddInstructor } from "../../Pages/AddInstructors/AddInstructor";
import { Navbar } from "../Navbar/Nav";
import { AddClass } from "../../Pages/AddClass/AddClass";
import { InstructorTable } from "../../Pages/InstructorTable/InstructorTable";
import { LicenseTable } from "../../Pages/LicenseTable/LicenseTable";
import { TextContainer } from "../../Pages/Text/TextContainer";
import {
  FontSizeOutlined,
  DotChartOutlined,
  RollbackOutlined,
  ClusterOutlined,
  UserOutlined,
  ProfileOutlined,
  UserAddOutlined,
  TeamOutlined,
  FileTextOutlined,
  FileAddOutlined,
  DatabaseOutlined,
  EnvironmentOutlined,
  LineChartOutlined,
  TableOutlined,
  PartitionOutlined
} from "@ant-design/icons";
import { ImageProcessingContainer } from "../../Pages/ImageProcessing/ImageProcessingContainer";
import { LocationContainer } from "../../Pages/Location/LocationContainer";
import { RegressionContainer } from "../../Pages/Regression/RegressionContainer";
import { Profile } from "../../Pages/AdminProfile/Profile";
import { ChangePassword } from "../../Pages/ChangePassword/ChangePassword";
import { StudentTable } from "../../Pages/StudentsTable/StudentTable";
import { UploadStudent } from "../../Pages/Students/UploadStudent";
import {
  useConnection,
  useUser,
  useModule, useConfig,
} from "../../Services/ApplicationContext";
import { ClusteringContainer } from "../../Pages/Clustering/ClusteringContainer";
import { Datasets } from "../../Pages/DataSets/Datasets";
import { DatasetTable } from "../../Pages/DataSets/DatasetTable";
import { DecisionTreeContainer } from "../../Pages/DecisionTree/DecisionTreeContainer";
import { Modules } from "../../Pages/Modules/Modules";
import {NetworkAnalysis} from "../../Pages/Network/NetworkAnalysisContainer";

const { SubMenu } = Menu;
const { Content, Footer, Sider } = Layout;

type navigationProps = {
  name: any;
  setName: (val: any) => void;
  defaultLoc: string;
  setDefaultLoc: (val: string) => void;
  globalSessionSave: boolean;
  setGlobalSessionSave: (val: boolean) => void;
};

export const NavigationContext = createContext<navigationProps>({
  name: {},
  setName: () => {},
  defaultLoc: "/",
  setDefaultLoc: () => {},
  globalSessionSave: false,
  setGlobalSessionSave: () => {},
});

const NavigationContextProvider: FC = (porps) => {
  const [name, setName] = useState<any>({
    firstName: "",
    lastName: "",
    default_pass: true
  });
  const [defaultLoc, setDefaultLoc] = useState<string>("/");
  const [globalSessionSave, setGlobalSessionSave] = useState<boolean>(false);

  return (
    <NavigationContext.Provider
      value={{
        name,
        setName,
        defaultLoc,
        setDefaultLoc,
        globalSessionSave,
        setGlobalSessionSave
      }}
    >
      {porps.children}
    </NavigationContext.Provider>
  );
};

export const SideNavigation: FC = () => {
  const { role } = useUser();
  const { moduleDetails, setModuleDetails } = useModule();
  const { get } = useConnection();
  const {globalSessionSave, setGlobalSessionSave} = useConfig();
  const history = useHistory();

  useEffect(() => {
   loadModuleDetails();
  }, []);

  const warnBeforeUnload = (e:any) => {
    switch (window.location.pathname) {
      case "/clustering":
      case "/network":
      case "/location":
      case "/text":
      case "/decisiontree":
      case "/image":
      case "/regression": {
        console.log(globalSessionSave)
        if(globalSessionSave) {
          e.preventDefault()
          e.returnValue = "Hello"
          return "Hello"
        } else {
          return "";
        }
      }
      default: {
        return ""
      }
    }
  }

  const warnBeforeModuleChange = async (e: any) => {
    console.log(e);
    console.log("sss", globalSessionSave);
    switch (window.location.pathname) {
      case "/clustering":
      case "/network":
      case "/location":
      case "/text":
      case "/decisiontree":
      case "/image":
      case "/regression": {
        console.log(globalSessionSave)
        if(globalSessionSave) {
          const target = e.target;
          console.log(e.path)
          console.log(target.pathname)
          // location.href = target.href;
          e.preventDefault();
          e.stopPropagation();
          const modal = await Modal.confirm({
            title: 'Warning',
            content: `You will lose any unsaved data!`,
            onOk() {
              setGlobalSessionSave(false);
              console.log(target.parentNode.parentNode)
              target.parentNode.parentNode.click();
              history.push(target.pathname);
              modal.destroy();
            },
            onCancel() {
              modal.destroy();
            }
          });
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", warnBeforeUnload);
    return () => {window.removeEventListener("beforeunload", warnBeforeUnload)}
  }, [globalSessionSave]);

  const setData = (result: any, r: number) => {
    console.log(result.data.modules);
    setModuleDetails(
        result.data.modules.map((module: any) => {
          switch (module.module_id) {
            case 1:
              module = {
                ...module,
                module_name: module.module_name,
                icon: <FontSizeOutlined />,
                link: "text",
              };

              return module;

            case 2:
              module = {
                ...module,
                module_name: module.module_name,
                icon: <TableOutlined />,
                link: "image",
              };
              return module;

            case 3:
              module = {
                ...module,
                module_name: module.module_name,
                icon: <LineChartOutlined />,
                link: "regression",
              };
              return module;

            case 4:
              module = {
                ...module,
                module_name: module.module_name,
                icon: <PartitionOutlined />,
                link: "network"
              };
              return module;

            case 5:
              module = {
                ...module,
                module_name: module.module_name,
                icon: <EnvironmentOutlined />,
                link: "location",
              };
              return module;

            case 6:
              module = {
                ...module,
                module_name: module.module_name,
                icon: <DotChartOutlined />,
                link: "clustering",
              };
              return module;

            case 7:
              module = {
                ...module,
                module_name: module.module_name,
                icon: <ClusterOutlined />,
                link: "decisiontree",
              };
              return module;

            default:
              module = {
                ...module,
                module_name: module.module_name,
                icon: <FontSizeOutlined />,
                link: "default",
              };
          }
        })
    );
  }

  const loadModuleDetails = async () => {
    const role: any = localStorage.getItem("role")
    const r = localStorage.getItem("role") ? parseInt(role) : 3;
    if(r == 1) {
      await get("classes/view_modules/").then((result) => {
        setData(result, r);
      });
    } else {
      await get("users/view_modules/").then((result) => {
        setData(result, r);
      });
    }
  };

  return (
    <div>
      <Router history={history}>
        <NavigationContextProvider>
          <Navbar />
          <Layout>
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
              }}
            >
              <div className="logo" />
              <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
                {role === "1" && (
                  <SubMenu
                    key="sub1"
                    icon={<UserOutlined />}
                    title=" Instructors"
                  >
                    {" "}
                    <Menu.Item key="2">
                      <TeamOutlined />
                      View Instructors
                      <span>
                        <Link to="/instructorsall" onClick={event => warnBeforeModuleChange(event)}/>
                      </span>
                    </Menu.Item>
                    <Menu.Item key="1">
                      <UserAddOutlined /> Add Instructor
                      <span>
                        <Link to="/instructors" onClick={event => warnBeforeModuleChange(event)}/>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )}
                {role === "1" && (
                  <SubMenu
                    key="sub2"
                    icon={<FileTextOutlined />}
                    title=" Class"
                  >
                    <Menu.Item key="4" icon={<ProfileOutlined />}>
                      View Class
                      <span>
                        <Link to="/licenseall" onClick={event => warnBeforeModuleChange(event)}/>
                      </span>
                    </Menu.Item>{" "}
                    <Menu.Item key="3" icon={<FileAddOutlined />}>
                      Add Class
                      <span>
                        <Link to="/license" onClick={event => warnBeforeModuleChange(event)}/>
                      </span>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<ProfileOutlined />}>
                      Manage Modules
                      <span>
                        <Link to="/modules" onClick={event => warnBeforeModuleChange(event)}/>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )}
                {(role === "1" || role === "2") && (
                  <SubMenu
                    key="sub3"
                    icon={<FileTextOutlined />}
                    title="Manage Students"
                  >
                    <Menu.Item key="5" icon={<FileAddOutlined />}>
                      View Students
                      <span>
                        <Link to="/viewstudents" onClick={event => warnBeforeModuleChange(event)}/>
                      </span>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<ProfileOutlined />}>
                      Upload Students
                      <span>
                        <Link to="/uploadstudents" onClick={event => warnBeforeModuleChange(event)}/>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )}
                {role === "1" && (
                  <SubMenu
                    key="sub4"
                    icon={<DatabaseOutlined />}
                    title="Manage Datasets"
                  >
                    {" "}
                    <Menu.Item key="15" icon={<FileTextOutlined />}>
                      View Datasets
                      <span>
                        <Link to="/viewDatasets" onClick={event => warnBeforeModuleChange(event)}/>
                      </span>
                    </Menu.Item>
                    <Menu.Item key="14" icon={<DatabaseOutlined />}>
                      Add Dataset
                      <span>
                        <Link to="/datasets" onClick={event => warnBeforeModuleChange(event)}/>
                      </span>
                    </Menu.Item>
                  </SubMenu>
                )}

                {moduleDetails.map((module: any) => (
                  <Menu.Item icon={module.icon}>
                    {module.module_name}
                    <Link to={module.link} onClick={event => warnBeforeModuleChange(event)}/>
                  </Menu.Item>
                ))}
              </Menu>
            </Sider>
            <Layout>
              <Content className="main-container">
                <div className="site-layout-background">
                  <Route
                      exact
                      path="/"
                      render={() => {
                        const path: any = localStorage.getItem("path");
                        return <Redirect to={path} />;
                      }}
                  />
                  <Route exact path="/instructors" component={AddInstructor} />
                  <Route
                    exact
                    path="/instructorsall"
                    component={InstructorTable}
                  />
                  <Route exact path="/license" component={AddClass} />
                  <Route exact path="/licenseall" component={LicenseTable} />
                  <Route exact path="/modules" component={Modules} />
                  <Route exact path="/datasets" component={Datasets} />
                  <Route exact path="/viewDatasets" component={DatasetTable} />
                  <Route exact path="/text" component={TextContainer} />
                  <Route
                    exact
                    path="/image"
                    component={ImageProcessingContainer}
                  />
                  <Route exact path="/location" component={LocationContainer} />
                  <Route exact path ='/network' component = {NetworkAnalysis} />
                  <Route
                    exact
                    path="/regression"
                    component={RegressionContainer}
                  />
                  <Route
                    exact
                    path="/decisiontree"
                    component={DecisionTreeContainer}
                  />
                  <Route exact path="/profile" component={Profile} />
                  <Route
                    exact
                    path="/changePassword"
                    component={ChangePassword}
                  />

                  <Route exact path="/viewstudents" component={StudentTable} />
                  <Route
                    exact
                    path="/uploadstudents"
                    component={UploadStudent}
                  />
                  {/*<Route*/}
                  {/*  exact*/}
                  {/*  path="/license/updateclass/:id"*/}
                  {/*  component={UpdateClass}*/}
                  {/*/>*/}
                  <Route
                    exact
                    path="/clustering"
                    component={ClusteringContainer}
                  />
                  <Route
                    path={`/editDataset`}
                    component={() => <Datasets title={"Update Dataset"} />}
                  />
                  <Route
                      path={`/updateClass`}
                      component={() => <AddClass title={"Update Class"} />}
                  />

                </div>
              </Content>
              <Footer style={{ textAlign: "center" }}>
                Powered By Xeptagon
              </Footer>
            </Layout>
          </Layout>
        </NavigationContextProvider>
      </Router>
    </div>
  );
};
