import React, {FC, useContext, useState} from "react";
import { Button, Form, Input, message, Radio, Spin, Upload } from "antd";

import { useRegression } from "../../Pages/Regression/RegressionContext";
import CSVReader from "react-csv-reader";
import { IFileInfo } from "react-csv-reader/src/index";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import { CSVLink } from "react-csv";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import {NavigationContext} from "../SideNavigation/SideNavigation";
import {RcFile, UploadChangeParam} from "antd/es/upload";
import { UploadOutlined } from '@ant-design/icons';

export const UploadFiles: FC = () => {
  const [form] = Form.useForm();
  const {
    dataFile,
    downloadSample,
    setVariableList,
    setDataPoints,
    setSelectedDataFile,
    samples,
    setResetFields,
    sessionData,
    setSessionData,
    setSessionSavable,
    setMetaParams,
    setPredictedData,
    setEstimated,
    loading
  } = useRegression();
  const {setGlobalSessionSave} = useConfig();
  const { post } = useConnection();
  const [selectedRadio, setSelectedRadio] = useState<string>();

  //get base64
  const getBase64FromCsv = (file: string) => {
    const buff = new Buffer(file);
    return buff.toString("base64");
  };

  const onChangeRadio = async (e: any) => {
    console.log(e.target.value);
    setResetFields(true);
    setVariableList([]);
    setDataPoints('');
    setPredictedData('');
    setMetaParams({
      alpha: 25792.2001986687,
      beta: 9449.96232145508,
    });
    setEstimated('');
    if (e.target.value === "custom") {
      setSelectedRadio("custom");
      return;
    } else {
      setSelectedRadio("");
    }

    try {
      const result = await post("datasets/get_dataset_data/", {
        dataset_id: e.target.value,
      });

      setSelectedDataFile(result.data.dataset);
      const decoded: string = atob(result.data.dataset);
      setVariableList(decoded.split("\n")[0].split(","));
      successNotification("Success", "Dataset changed!");
    } catch (error) {
      console.log(error);
      errorNotification("Error", error.message);
    }
    setResetFields(false);
    setSessionSavable(false);
    setGlobalSessionSave(false);
    sessionData.session_data = [];
    setSessionData(sessionData);
  };
  const arrayToCsv = (arr: string[][]) => {
    const lineArray: string[] = [];
    setVariableList(arr[0]);
    console.log("fcu ", arr[0]);
    arr.forEach(function (infoArray, index) {
      const line = infoArray.join(",");
      lineArray.push(line);
      // lineArray.push(index == 0 ? "data:text/csv;charset=utf-8," + line:  line);
    });
    return lineArray.join("\n");
  };
  const handleForce = (data: Array<any>, fileInfo: IFileInfo) => {
    console.log("On handle force meh");
    if (fileInfo.size / 1024 / 1024 > 2) {
      errorNotification("Error", "File must be smaller than 2MB!");
      return;
    }
    const csv = arrayToCsv(data);
    const temp = dataFile.slice();
    temp[2] = getBase64FromCsv(csv);
    // setDataFile(temp);
    console.log(temp[2]);
    setSelectedDataFile(temp[2]);
    console.log(temp[2]);
  };

  const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
    if (file.size / 1024 / 1024 > 2) {
      errorNotification("Error", "File must be smaller than 2MB!");
      return false;
    }

    const reader = new FileReader();
    reader.onload = ev => {
      const data = ev.target?.result;
      if(data) {
        console.log(data)
        const rows = data.toString().split("\n")
        if(rows.length < 1) {
          errorNotification("Error", "Empty file");
          return false;
        }
        setVariableList(rows[0].split(","))
        const b64 = getBase64FromCsv(data.toString())
        setSelectedDataFile(b64);
      }
    }

    reader.readAsText(file);

    return false;
  }

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Upload Files</h2>
        <Form form={form}>
          {!loading && (
            <CSVLink data={atob(downloadSample)} filename={"sampleDataset.csv"}>
              Sample File
            </CSVLink>
          )}
          <Form.Item label="Select from sample dataset">
            <Radio.Group onChange={onChangeRadio}>
              {samples.map((sample: any) => (
                <Radio value={sample.dataset_id}>{sample.dataset_name}</Radio>
              ))}
              <Radio value={"custom"}>Upload custom dataset</Radio>
            </Radio.Group>
          </Form.Item>
          {selectedRadio === "custom" && (
            <Form.Item>
              <span>Data File</span>
              <br/>
              <Upload
                  accept={".csv,text/plain"}
                  showUploadList={true}
                  beforeUpload={beforeUpload}
              >
                <Button icon={<UploadOutlined />}>Choose File</Button>
              </Upload>
            </Form.Item>
          )}
        </Form>
      </div>
    </Spin>
  );
};
