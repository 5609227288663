import {
  Table,
  Spin,
  Input,
  Form,
  Popconfirm,
  InputNumber,
  Button,
  Select, Modal,
} from "antd";
import React, { FC, useEffect, useState, useRef, useContext } from "react";
import { useConnection, useUser } from "../../Services/ApplicationContext";
import sha256 from "sha256";
import { errorNotification, successNotification } from "../../Services/NotificationService";
import "../styles.scss";

const EditableContext = React.createContext<any>({});

type Item = {
  key: string;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  program: string;
  password: string;
  metric_number: string;
  english_name: string;
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
  loading: boolean;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  loading,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        dataIndex === "password" ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        ) : (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  );
};
export const StudentTable = () => {
  const { get, post } = useConnection();
  const [loading, setLoading] = useState<boolean>();
  const [form] = Form.useForm();
  const [data, setData] = useState<any>([]);
  const [classDetails, setClassDetails] = useState<any>([""]);
  const [editingKey, setEditingKey] = useState("");
  const { Option } = Select;
  const { role } = useUser();
  const isEditing = (record: Item) => record.user_id === editingKey;
  const [enrolledNo, setEnrolledNo] = useState<number>(0);
  const [licenseCount, setLicenseCount] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);

  const edit = (record: Item) => {
    form.setFieldsValue({
      first_name: "",
      last_name: "",
      english_name: "",
      email: "",
      password: "",
      ...record,
    });
    setEditingKey(record.user_id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: string) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.user_id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        await handleSave({
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        await handleSave(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  useEffect(() => {
    // getStudent(14);
    const r = localStorage.getItem("role") ? localStorage.getItem("role") : role
    getClass(r);
  }, []);

  const getStudent = async (id: any): Promise<void> => {
    setLoading(true);
    try {
      const result = await post("instructors/view_students/", {
        class_id: id,
      });
      console.log(result?.data?.students);
      setData(result?.data?.students);
      const result2 = await post("classes/get_class/", {
        class_id: id
      });
      console.log("students", result2?.data?.class?.num_students);
      setLicenseCount(result2?.data?.class?.num_students);
      setEnrolledNo(result?.data?.students.length);
      setLoading(false);
      setVisible(true);
    } catch (error) {

      console.log(error);
      setLoading(false);
      errorNotification("Error", error.message)
    }
  };

  const validate = (firstName: string, lastName: string, englishName: string) => {
    if(!firstName || firstName === "") {
      errorNotification("Error", "Invalid first name!");
      return false;
    }
    if(!lastName || lastName === "") {
      errorNotification("Error", "Invalid last name!");
      return false;
    }
    if(!englishName || englishName === "") {
      errorNotification("Error", "Invalid english name!");
      return false;
    }
    return true;
  }

  const handleSave = async (val: any) => {
    setLoading(true);
    try {
      if (val.password !== undefined && val.password !== "") {
        val.password = sha256(val.password);
      } else {
        delete val.password;
      }
      console.log("iiiii ", { ...val });
      const res = validate(val.first_name, val.last_name, val.english_name);
      if(!res) {
        setLoading(false);
        return;
      }
      await post("instructors/update_student/", { ...val });
      setLoading(false);
      successNotification("Success", "Student updated success!");
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message)
    }
  };

  //getting class details
  const getClass = async (role: any) => {
    setLoading(true);
    let result;
    console.log(role)
    role = parseInt(role);
    try {
      if (role === 1) {
        result = await get("classes/view_classes/");
        console.log(result?.data?.classes)
        setClassDetails(result?.data?.classes);
        setLoading(false)
      } else {
        const id = parseInt(localStorage.getItem("id")!) 
        console.log('+++++',typeof id);
        result = await post("instructors/view_instructor_classes/",{
          user_id: id
        })
        console.log(result?.data)
        setClassDetails(result?.data?.classes);
        setLoading(false);
        // setVisible(true);
      }
    } catch (error) {
      errorNotification("Error", error.message)
      setLoading(false)
    }
  };

  //delete student
  const deleteStudent = async (email: string) => {
    try {
      setLoading(true)
      console.log(typeof email);
      const result = await post("instructors/delete_student/", {
        email: email,
      });

      setData(
          data.filter((student: any) => student.email != email)
      );
      setEnrolledNo(enrolledNo - 1);
      successNotification("Success", "Student deleted successfully!");
      console.log(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message);
    }
  };

  const confirmDelete = async (email: string) => {
    const modal = Modal.confirm({
      title: 'Warning',
      content: `Confirm delete?`,
      centered: true,
      onOk() {
        deleteStudent(email);
        modal.destroy();
      },
      onCancel() {
        modal.destroy();
      }
    });
  }

  const columnsX = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a: any, b: any) => a.first_name.localeCompare(b.first_name),
      editable: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a: any, b: any) => a.last_name.localeCompare(b.last_name),
      editable: true,
    },
    {
      title: "English Name",
      dataIndex: "english_name",
      key: "english_name",
      sorter: (a: any, b: any) => a.english_name.localeCompare(b.english_name),
      editable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      // editable: true,
    },
    {
      title: "Program",
      dataIndex: "program",
      key: "program",
      sorter: (a: any, b: any) => a.program.localeCompare(b.program),
      // editable: true,
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.user_id)}
              style={{ marginRight: 8 }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
            <>
            <Button type="primary" disabled={editingKey !== ""} onClick={() => edit(record)}>
              Edit
            </Button> {" "}
              <Button
                  type="primary"
                  onClick={() => confirmDelete(record.email)}
              >
                Delete
              </Button>
            </>
        );
      },
    },
  ];
  const mergedColumns = columnsX.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "user_id" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <Spin spinning={loading} tip="Loading...">
        <div className="" style={{ marginBottom: 10, width: "18%", float: "left" }}>
          <Form>
            <Form.Item label="Select a Class">
              <Select
                onChange={(value) => getStudent(value)}
                placeholder="Select a class"
              >
                {classDetails.map((detail: any) => (
                  <Option value={detail.class_id}>{detail.license_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
        {visible? (
            <span style={{float: "right", height: 32, alignItems: "center", verticalAlign: "middle"}}>
              <label style={{height: 32, alignItems: "center", verticalAlign: "middle"}}>Remaining Licenses: </label>
              <label style={{height: 32, alignItems: "center", verticalAlign: "middle"}}>
                {licenseCount - enrolledNo}/{licenseCount}
              </label>
            </span>
        ): null}
        <Form form={form} component={false}>
          <Table
            pagination={{
              pageSizeOptions: ["10", "15", "25"],
              showSizeChanger: true,
            }}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            // pagination={{
            //   onChange: cancel,
            // }}
            style={{ rowGap: 0, columnGap: 0, padding: 0 }}
          />
        </Form>
      </Spin>
    </>
  );
};
