import React, { FC, useState } from "react";
import { Form, Input, Button, Radio, Row, Spin } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import sha256 from "sha256";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";

export const AddInstructor: FC = () => {
  const { post } = useConnection();
  const [form] = Form.useForm();
  const [loading,setLoading] = useState<boolean>(false)

  const registerInstructor = async (values: any): Promise<void> => {
    values.role = 2;
    values.password = sha256(values.password);

    console.log(values);

    try {
      setLoading(true)
      const result = await post("instructors/add_instructor/", values);
      console.log(result);
      successNotification("Registration", "User Registered Successfully!");
      form.setFieldsValue({
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        number: "",
      });
      setLoading(false)
    } catch (error) {
      setLoading(false)
      errorNotification("Error", error.message)

      console.log(error);
    }
  };

  return (
    <Spin spinning = {loading}>
    <div className="component-box">
      <div
        style={{
          marginBottom: 20,
          justifyContent: "center",
        }}
      >
        <span
          style={{
            fontSize: 30,
          }}
        >
          Add Instructor
        </span>
      </div>
      <Form layout="vertical" onFinish={registerInstructor} form={form}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              transform: (value) => value.trim(),
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              transform: (value) => value.trim(),
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="First Name"
          name="first_name"
          rules={[{ transform: (value) => value.trim(), required: true, message: "Please Enter First Name" }]}
        >
          <Input placeholder="Instructor Name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last_name"
          rules={[{ transform: (value) => value.trim(), required: true, message: "Please Enter Last Name" }]}
        >
          <Input placeholder="Instructor Name" />
        </Form.Item>
        {/* <Form.Item
          label="Contact Number"
          name="number"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <Input placeholder="Contact Number" />
        </Form.Item> */}
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please Enter a Password!",
            },
          ]}
        >
          <Input placeholder="Password" />
        </Form.Item>
        <Row >
          <Form.Item>
            <Button
              style={{
                width: 150,
              }}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div></Spin>
  );
};
