import React, {FC, useContext, useEffect, useState} from "react";
import {Form, Radio, Button, Table} from 'antd';
import {useConnection} from "../../Services/ApplicationContext";
import {NetworkContext} from "./NetworkContext/NetworkContext";
import {errorNotification, infoNotification, successNotification} from "../../Services/NotificationService";
import {CSVLink} from "react-csv";

export const NetworkStatistics: FC = () => {
    const [form] = Form.useForm();
    const {get, post} = useConnection();
    const {graph , setGraph, sessionSavable, stepTwoData, setStepTwoData, setData, resetFields} = useContext(NetworkContext);

    const [transitivity, setTransitivity] = useState<number>();
    const [viewGlobal, setViewGlobal] = useState<boolean>(false);
    const [tableData, setTableData] = useState<[]>([]);
    const columns = [
        {
            title: "Node List",
            dataIndex: "node_label",
            key: "node_label",
            sorter: (a: any, b: any) => a.node_label - b.node_label,
        },
        {
            title: "Betweenness",
            dataIndex: "betweenness",
            key: "betweenness",
            sorter: (a: any, b: any) => a.betweenness - b.betweenness
        },
        {
            title: "Closeness",
            dataIndex: "closeness",
            key: "closeness",
            sorter: (a: any, b: any) => a.closeness - b.closeness,
        },
        {
            title: "Degree",
            dataIndex: "degree",
            key: "degree",
            sorter: (a: any, b: any) => a.degree - b.degree,
        },
        {
            title: "Local Transitivity",
            dataIndex: "local_transitivity",
            key: "local_transitivity",
            sorter: (a: any, b: any) => a.local_transitivity - b.local_transitivity,
        },
    ];

    useEffect(() => {
        if(!sessionSavable) {
            setViewGlobal(false);
            setTableData([]);
        }
    }, [sessionSavable]);

    useEffect(() => {
        if(resetFields) {

            setViewGlobal(false);
            setTableData([]);
        }
    }, [resetFields]);

    useEffect(() => {
        console.log("restore stats")
        console.log(stepTwoData);
        if(stepTwoData.restore && stepTwoData.response_data.data.global_measures) {
            setViewGlobal(true);
            setTransitivity(stepTwoData.response_data.data.global_measures.transitivity);
        }
        if(stepTwoData.restore && stepTwoData.response_data.data.central_measures?.table_data.length > 0) {
            setTableData(stepTwoData.response_data.data.central_measures.table_data);
        }
    }, [stepTwoData]);

    const getGlobalMeasures = async () => {
        try {
            const result:any = await post("modules/network/getGlobalMeasures", {graph: graph});
            setTransitivity(result?.data?.data.transitivity);
            setViewGlobal(true);
            stepTwoData.response_data.data.global_measures = {transitivity:  result?.data?.data.transitivity};
            setStepTwoData(stepTwoData);
            setData(stepTwoData);
            successNotification("Success", "Calculated global measures!");
        } catch (error) {
            if(error.status == 400) {
                errorNotification("Error", "Incorrect dataset!");
            } else {
                errorNotification("Error", error.message);
            }
        }
    }

    const getCentralityMeasures = async () => {
        try {
            const result:any = await post("modules/network/getCentralityMeasures", {graph: graph});
            setTableData(result?.data?.data);
            console.log("res: ", result?.data?.data)
            stepTwoData.response_data.data.central_measures.table_data = result?.data?.data;
            setStepTwoData(stepTwoData);
            console.log(stepTwoData)
            setData(stepTwoData);
            if(result.data.message == "Success") {
                successNotification("Success", "Calculated centrality measures!");
            } else {
                infoNotification("Info", result.data.message);
            }

        } catch (error) {
            if(error.status == 400) {
                errorNotification("Error", "Incorrect dataset!");
            } else {
                errorNotification("Error", error.message);
            }
        }
    }

    return (
        <div className="component-box">
            <h2>Step 2 : Network Statistics</h2>
            <Form form={form}>
                <h1>Global measures</h1>
                { viewGlobal ?
                    <div>
                        Transitivity: {"" + transitivity}
                    </div>
                : null}
                <div className="" style={{ marginTop: 20 }}>
                    <Button type="primary" disabled={!sessionSavable} onClick={()=>{
                        getGlobalMeasures();
                    }}>Calculate</Button>
                </div>
                <h1 style={{marginTop: 15}}>Centrality measures</h1>
                <div className="" style={{ marginTop: 20 }}>
                    <Button type="primary" disabled={!sessionSavable} onClick={()=>{
                        getCentralityMeasures();
                    }}>Calculate</Button>
                </div>
                <div style={{marginTop: 10}}>
                    {tableData.length > 0 && (
                        <>
                            <Table
                                columns={columns}
                                bordered
                                pagination={{
                                    pageSizeOptions: ["10", "15", "25"],
                                    showSizeChanger: true,
                                }}
                                dataSource={tableData}
                            />
                            <div className="" style={{ marginTop: 15 }}>
                                <Button type="primary">
                                    <CSVLink data={tableData} filename={"Network_data.csv"}>
                                        Download
                                    </CSVLink>
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Form>
        </div>
    );
};
