import React, {FC, useEffect} from "react";
import { Button, Table } from "antd";
import { useRegression } from "../../Pages/Regression/RegressionContext";
import { CSVLink } from "react-csv";
import "../../Pages/styles.scss";

export const StepThreeEstimate: FC = () => {
  const {
    estimated,
    fetchEstimation,
    independentVariable,
    dataFile,
    selectedDataFile,
    dependentVariable,
    variableList,
    stepThreeData
  } = useRegression();

  useEffect(() => {
    restoreStepThreeSession();
  }, [stepThreeData]);

  const restoreStepThreeSession = async () => {
    if(stepThreeData?.response_data?.data) {
      await fetchEstimation();
    }
  }

  return (
    <div className="component-box">
      <h2>Step 3: Estimate</h2>
      <Button
        type={"primary"}
        onClick={fetchEstimation}
        disabled={
           variableList.length == 0 ||
          selectedDataFile === undefined ||
          independentVariable === undefined ||
          dependentVariable === undefined
        }
      >
        Estimate regression equation
      </Button>
      {estimated && (
        <>
          <div className={"estimate-panel"}>
            <h3>Regression Coefficients:</h3>
            {estimated?.coefficients.alpha || estimated?.coefficients.alpha == 0 ? (
              <span>Alpha (Intercept) = {estimated?.coefficients.alpha}</span>
            ): null}
            {estimated?.coefficients.beta || estimated?.coefficients.beta == 0 ? (
              <span>Beta = {estimated?.coefficients.beta}</span>
            ): null}
            {estimated?.coefficients.t_value || estimated?.coefficients.t_value == 0 ? (
              <span>t Value = {estimated?.coefficients.t_value}</span>
            ): null}
            {estimated?.coefficients.p_value || estimated?.coefficients.p_value == 0 ? (
              <span>p Value = {estimated?.coefficients.p_value}</span>
            ): null}
            {estimated?.coefficients.r2 || estimated?.coefficients.r2 == 0 ? (
              <span>R Squared = {estimated?.coefficients.r2}</span>
            ): null}
            {estimated?.coefficients.adj_r2 || estimated?.coefficients.adj_r2 == 0 ? (
              <span>Adjusted R Squared = {estimated?.coefficients.adj_r2}</span>
            ): null}
            {estimated?.coefficients.dof || estimated?.coefficients.dof == 0 ? (
              <span>Degrees of Freedom = {estimated?.coefficients.dof}</span>
            ): null}
          </div>
          <div>
            {estimated?.comparison && (
              <div>
                <Table
                  dataSource={estimated.comparison.map((val) => ({
                    ...val,
                    name: val.name === "x" ? independentVariable : val.name,
                  }))}
                  bordered
                  columns={columns}
                />
                <Button type="primary">
                  <CSVLink
                    data={estimated.comparison.map((val) => ({
                      ...val,
                      name: val.name === "x" ? independentVariable : val.name,
                    }))}
                    filename={"descriptive_analysis.csv"}
                  >
                    Download
                  </CSVLink>
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Estimate",
    dataIndex: "estimate",
    key: "estimate",
    sorter: (a: any, b: any) => a.estimate - b.estimate,
  },
  {
    title: "Std. Error",
    dataIndex: "std_error",
    key: "std_error",
    sorter: (a: any, b: any) => a.std_error - b.std_error,
  },
  {
    title: "t Value",
    dataIndex: "t_value",
    key: "t_value",
    sorter: (a: any, b: any) => a.t_value - b.t_value,
  },
  {
    title: "Pr(<|t|)",
    dataIndex: "p_value",
    key: "p_value",
    sorter: (a: any, b: any) => a.p_value - b.p_value,
  },
];
