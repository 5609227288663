import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Form, Input, Row, Tag, Spin, Alert } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import {
  errorNotification,
  infoNotification,
  successNotification,
} from "../../Services/NotificationService";
import { NavigationContext } from "../../Components/SideNavigation/SideNavigation";

export const Profile: FC = () => {
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const { post } = useConnection();
  const { name, setName } = useContext(NavigationContext);
  const [form] = Form.useForm();
  useEffect(() => {
    getUserDetails();
  }, []);

  const updateProfile = async (values: any): Promise<void> => {
    values.role = user.role;
    values.user_id = user.user_id;
    console.log(values);
    try {
      const result = await post("users/update_user_details/", values);
      setName({
        ...name,
        firstName: values.first_name,
        lastName: values.last_name,
      });
      successNotification("Sucess", result.data.message);
    } catch (error) {
      if (
        error.message === "No need to update. User has been updated already!"
      ) {
        infoNotification("Info", error.message);
      } else {
        errorNotification("Error", error.message);
      }
      console.log(error);
    }
  };

  const getUserDetails = async (): Promise<void> => {
    setLoading(true);
    const email = localStorage.getItem("email");
    console.log(email);
    try {
      const result = await post("users/get_user/", {
        email,
      });
      setUser(result?.data?.data);
      form.setFieldsValue({
        first_name: result.data.data.first_name,
        last_name: result.data.data.last_name,
        email: result.data.data.email,
      });
      setLoading(false);
    } catch (error) {
      errorNotification("Error", error.message)
      console.log(error);
    }
  };

  const userRole = () => {
    console.log(user.role);
    switch (user.role) {
      case 1:
        return "Admin";
      case 2:
        return "Instructor";
      case 3:
        return "Student";
    }
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <div className="component-box">
        {loading ? (
          <div className="spinner"> </div>
        ) : (
          <>
            <div
              style={{
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: 30,
                }}
              >
                My Profile
              </span>
              <span>
                {" "}
                <Tag color="green">{userRole()}</Tag>
              </span>
            </div>
            <Form
              form={form}
              layout="vertical"
              onFinish={updateProfile}
              initialValues={user}
            >
              <Form.Item label="First Name" name="first_name">
                <Input />
              </Form.Item>
              <Form.Item label="Last Name" name="last_name">
                <Input />
              </Form.Item>
              {/* <Form.Item label="English Name" name="englishname">
              <Input />
            </Form.Item> */}
              <Form.Item label="Email" name="email">
                <Input readOnly />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Update Profile{" "}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </Spin>
  );
};
