import React, {FC, useContext} from "react";
import ClusteringContextProvider, {ClusteringContext} from "../../Components/ClusteringComponents/ClusteringContext";
import { StepOnePlot } from "../../Components/ClusteringComponents/StepOnePlot";
import { StepTwoKmeans } from "../../Components/ClusteringComponents/StepTwoKmeans";
import { UploadClusterFile } from "../../Components/ClusteringComponents/UploadClusterFile";
import "../styles.scss";
import {Modal} from "antd";
import {StepThreeMDCluster} from "../../Components/ClusteringComponents/StepThreeMDCluster";

export const ClusteringContainer: FC = () => {
  return (
    <ClusteringContextProvider>
      <ClusteringContainerWrapper />
    </ClusteringContextProvider>
  );
};

export const ClusteringContainerWrapper: FC = () => {
  const { saveSession, restoreSession } = useContext(ClusteringContext);
    const restoreWarn = () => {
        const modal = Modal.confirm({
            title: 'Warning',
            content: `Restore will clear all your unsaved data`,
            onOk() {
                restoreSession();
                modal.destroy();
            },
            onCancel() {
                modal.destroy();
            }
        });
    }
  return (
      <>
        <div className="">
          <div
              style={{
                marginBottom: 20,
                justifyContent: "center",
              }}
          >
            <span
                style={{
                  fontSize: 30,
                }}
            >
              Clustering
            </span>
              <div
                  style={{
                      float: "right"
                  }}
              >
                  <button
                      onClick={saveSession}
                      className={"btn-save"}
                  > Save </button>
                  <button
                      onClick={restoreWarn}
                      className={"btn-restore"}
                  > Restore </button>
              </div>
              <div style={{clear: 'both'}}></div>
          </div>
          <UploadClusterFile/>
          <StepOnePlot/>
          <StepTwoKmeans/>
          <StepThreeMDCluster/>
        </div>
      </>
  )
}
