import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Col,
  DatePicker,
  Row,
  InputNumber,
  Select,
  Spin,
} from "antd";
import {
  successNotification,
  errorNotification,
} from "../../Services/NotificationService";
import moment from "moment";
import { useConnection } from "../../Services/ApplicationContext";
import {useLocation} from "react-router-dom";
const { Option } = Select;

interface ClassProps {
  title: string;
}
interface LocationState {
  pathname : string,
  id : string
}

export const AddClass: FC<ClassProps> = ({title}) => {
  const [classDetails, setClassDetails] = useState<any>();
  const [datasets, setDataSets] = useState<any>([]);
  const [modules, setModuies] = useState<any>([]);
  const [instructors, setInstrcutors] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sDate, setSDate] = useState<any>(moment());
  const location = useLocation<LocationState>();
  const [form] = Form.useForm();
  const { get, post } = useConnection();

  useEffect(() => {
    getDatasets();
    getModules();
    getInstructors();

    if(location.state) {
      console.log("state id", location.state.id);
      setFormFields(parseInt(location.state.id));
    }
  }, []);

  const setFormFields = async (class_id: number) => {
    const result = await post("classes/get_class/", {class_id: class_id});
    console.log("class res", result);
    const cls = result?.data?.class;
    if(cls) {
      form.setFieldsValue({
        license_name: cls.license_name,
        start_date: moment(cls.start_date),
        end_date: moment(cls.end_date),
        dataset_ids: cls.dataset_ids,
        module_ids: cls.module_ids,
        num_students: cls.num_students,
        instructor_ids: cls.instructor_ids
      })

      setSDate(moment(cls.start_date));
    }
  }

  const onKeyPress = (event: any) => {
    if(!event.key.match(/[0-9]/)) {
      console.log("doesnt match")
      event.preventDefault();
    }
  };

  const submitClass = async (values: any) => {
    setLoading(true);
    try {
      values.start_date = moment(values.start_date._d).format("YYYY-MM-DD");
      values.end_date = moment(values.end_date._d).format("YYYY-MM-DD");
      values.num_students = parseInt(values.num_students);

      if(!values.module_ids || values.module_ids.length < 1) {
        errorNotification("Error", "At least one module needs to be selected!");
        setLoading(false);
        return;
      }

      // if(!values.dataset_ids || values.dataset_ids.length < 1) {
      //   errorNotification("Error", "At least one dataset needs to be selected!");
      //   setLoading(false);
      //   return;
      // }
      if(!values.dataset_ids) {
        values.dataset_ids = []
      }

      if(values.num_students < 1) {
        errorNotification("Error", "No. of students must be higher than 1!");
        setLoading(false);
        return;
      }

      if (values.start_date > values.end_date) {
        errorNotification("Error", "Start Date should be before the End Date!");
        setLoading(false);
        return;
      }

      values = { ...values, ...classDetails };
      console.log(values);

      if (title == "Add Class") {
        const result = await post("classes/add_class/", values);
        console.log(result);
        form.resetFields();
        successNotification("Success", "Class added successfully!");
      } else {
        values.class_id = location.state.id
        const result = await post("classes/update_class/", values);
        console.log(result);
        successNotification("Success", "Class updated successfully!");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message);
    }
  };

  //getting datasets from the api
  const getDatasets = async () => {
    setLoading(true);
    try {
      const result = await get("datasets/view_datasets/");
      console.log(result);
      setDataSets(result?.data?.datasets);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getModules = async () => {
    setLoading(true);
    try {
      const result = await get("classes/view_modules/");
      console.log(result);
      setModuies(result?.data?.modules);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //getting instructors from the api
  const getInstructors = async () => {
    setLoading(true);
    try {
      const result = await get("instructors/view_instructors/");
      console.log(result);
      setInstrcutors(result?.data?.users);

      setLoading(false);
    } catch (error) {
      errorNotification("Error", error.message);
    }
  };

  const selectSDate = (sd: any, sdStr:string) => {
    if(sd) {
      setSDate(sd);
      form.resetFields(["end_date"]);
    }
  };

  const disableDates = (curDate: moment.Moment) => {
    return curDate && (curDate < sDate || curDate < moment().subtract(1, "days"));
  };

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <div className="add-class-heading">
          <span
            style={{
              fontSize: 30,
            }}
          >
            {title}
          </span>
        </div>
        <div  >
          <Form
            layout="vertical"
            className="form-container"
            onFinish={submitClass}
            form={form}
          >
            <h1>License Name</h1>
            <Form.Item
              name="license_name"
              rules={[
                { transform: (value) => value.trim(), required: true, message: "Please input the license Name" },
              ]}
            >
              <Input placeholder="License Name" />
            </Form.Item>

            <Form.Item>
              <Row gutter={14}>
                <Col>
                  <h1>Dataset</h1>
                  <Form.Item name="dataset_ids">
                    <Checkbox.Group

                    // onChange={onDatasetChange}
                    >
                      {datasets.map((dataset: any) => (
                        <Col>
                          <Checkbox value={dataset.dataset_id}>
                            {dataset.dataset_name}
                          </Checkbox>
                        </Col>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <h1>Modules</h1>
                  <Form.Item name="module_ids">
                    <Checkbox.Group
                    //  onChange={onModuleChange}
                    >
                      {modules.map((module: any) => (
                        <Col>
                          <Checkbox value={module.module_id}>
                            {module.module_name}
                          </Checkbox>
                        </Col>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <h1>Start Date</h1>
            <Form.Item
              label=""
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Please Select a Starting date",
                },
              ]}
            >
              <DatePicker
                  style={{ width: "100%" }}
                  onChange={selectSDate}
              />
            </Form.Item>

            <h1>End Date</h1>
            <Form.Item
              name="end_date"
              rules={[
                {
                  required: true,
                  message: "Please Select a End date",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} disabledDate={disableDates}/>
            </Form.Item>

            <h1>No of Students</h1>
            <Form.Item
              label=""
              name="num_students"
              rules={[
                {
                  required: true,
                  message: "Please enter number of students",
                },
              ]}
            >
              <Input onKeyPress={onKeyPress} style={{ width: "100%" }} min={0} />
            </Form.Item>

            <h1>Instructors</h1>
            <Form.Item
              label=""
              name="instructor_ids"
              rules={[
                {
                  required: true,
                  message: "Please select an Instructor",
                },
              ]}
            >
              <Select
                mode="multiple"
                showArrow={true}
                placeholder="Select a Instructor"
              >
                {instructors.map((instructor: any) => (
                  <Option value={instructor.user_id}>
                    {instructor.first_name} {instructor.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" style={{ width: 120 }} htmlType="submit">
                {title == "Add Class" ? "Submit" : "Edit"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

AddClass.defaultProps = {
  title: "Add Class",
};