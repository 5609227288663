import React, {FC, useContext, useEffect, useState} from "react";
import { Button, Form, Image, Radio, Row, Spin } from "antd";
import { LocationContext } from "./LocationContext/LocationContext";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import {NavigationContext} from "../SideNavigation/SideNavigation";

export const LocationMap: FC = () => {
  const { map, setMap,loading,setLoading, stepOneData, setSessionSavable } = useContext(LocationContext);
  const {setGlobalSessionSave} = useConfig();
  const { post } = useConnection();
  const [visual,setVisual] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
      if(stepOneData.response_data.data) {
          form.setFieldsValue({
              location: stepOneData.request_data.location
          });
          setVisual(true);
          setSessionSavable(true);
          setGlobalSessionSave(true);
      }
  }, [stepOneData]);

  const image = require("../../Assets/images/image1.jpg");
  const setMapImage = async (value: any) => {
    setLoading(true);
    try {
      let result = await post("modules/location/getMap", value);
      result.data.data.map =
        "data:image/jpeg;base64," + result?.data?.data?.map;
      setMap(result.data.data.map);
      console.log(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
  
    <div className="component-box" style = {{width:"100%"}}>
      <h2>Select Location Dataset</h2>
   
   <Form form={form} layout="vertical" onFinish={setMapImage}>
        <Form.Item name="location">
          <Radio.Group>
            <Radio value="airport" onChange = {() =>  {setVisual(true); setSessionSavable(false); setGlobalSessionSave(false);}} >Airport</Radio>
          </Radio.Group>
        </Form.Item>
        <Button htmlType="submit" type="primary" style={{ marginBottom: 15 }} disabled = {!visual}>
          Visualize Map
        </Button>
      </Form>
      <Row justify='center'>
          <Spin spinning={loading} style={{textAlign:'center'}}>
            {/* <img src = {test}/> */}
            {map && <Image src={map} width={900} height={850} />}
          </Spin>
      </Row>
    </div>
      
     
  );
};
