import React, { FC, useEffect, useState } from "react";
import {Button, Modal, Spin, Table} from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import { errorNotification, successNotification } from "../../Services/NotificationService";
import {useHistory} from 'react-router-dom'
import "../styles.scss";

export const DatasetTable: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [datasets, setDataSets] = useState<any>([]);
  const [modules, setModules] = useState<any>([]);
  const history = useHistory()
  const { get, post } = useConnection();

  useEffect(() => {
    getDatasetInformation();
  }, []);

  const getDatasetInformation = async () => {
    setLoading(true);
    try {
      const result = await get("datasets/view_datasets/");
      const result2 = await get("classes/view_modules/");
      const result3 = await get("modules/network/getSamples");
      console.log(result);
      console.log("+++++++++++++++++", result2?.data?.modules);
      result?.data?.datasets.map((dataset: any) => {
        //const id = dataset.dataset_id
        result2?.data?.modules.map((module: any) => {
          if (dataset.module_id === module.module_id) {
            dataset.module_name = module.module_name;
            return dataset;
          }
        });
      });
      console.log(result.data.datasets);
      let datasets = result?.data?.datasets;
      const net_datasets = [];
      // collect network dataset ids
      for(const d in datasets) {
          if(datasets[d].module_id == 4) {
              net_datasets.push(d);
          }
      }
      // remove network datasets
      for(const i in net_datasets) {
          delete datasets[net_datasets[i]];
      }
      // add network datasets
      for(const i in result3?.data?.data) {
          const dt = result3?.data?.data[i];
          const dataset = {
              dataset_name: dt.name,
              download_permission: dt.download_permission,
              module_id: 4,
              module_name: "Network Module",
              dataset_id: {
                  nodes_id: dt.nodes_id,
                  edges_id: dt.edges_id
              }
          }
          datasets.push(dataset);
      }
      console.log("processed: ", datasets);
      setDataSets(datasets);
      setModules(result2?.data?.modules);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const confirmDelete = async (id: any, module_id:number) => {
    const modal = Modal.confirm({
        title: 'Warning',
        content: `Confirm delete?`,
        centered: true,
        onOk() {
            deleteDataset(id, module_id);
            modal.destroy();
        },
        onCancel() {
            modal.destroy();
        }
    });
  }

  const deleteDataset = async (dataset_id:any, module_id:number) => {
    setLoading(true)  
    try {
        if(module_id != 4) {
            await post("datasets/delete_dataset/",{
                dataset_id:dataset_id
            });
        } else {
            await post("datasets/delete_dataset/",{
                dataset_id:dataset_id.nodes_id
            });
            await post("datasets/delete_dataset/",{
                dataset_id:dataset_id.edges_id
            });
        }
        setDataSets(datasets.filter((dataset:any) => dataset.dataset_id != dataset_id));
        setLoading(false);
        successNotification("Successful",'Dataset deleted successfully!');
          
      } catch (error) {
        setLoading(false);
        errorNotification('Error',error.message);
      }
  }

  const columns = [
    {
      title: "Dataset Name",
      dataIndex: "dataset_name",
      key: "dataset_name",
    },
    {
      title: "Download Permission",
      dataIndex: "download_permission",
      key: "download_permission",
      render: (text: any, record: any) =>
        record.download_permission === 1 ? (
          <span>Downloadable</span>
        ) : (
          <span>Cannot Download</span>
        ),
    },
    {
      title: "Module",
      dataIndex: "module_name",
      key: "module_name",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      render: (_: any, record: any) => {
        return (
          <>
           
            <Button
              type="primary"
                onClick={() => history.push({
                  pathname : `/editDataset`,
                  state : {
                    id:record.dataset_id,
                    module_id: record.module_id,
                    dataset_name: record.dataset_name,
                    download_permission: record.download_permission
                  }
                })}
            >
              Edit
            </Button>{" "}
            <Button
              type="primary"
                onClick={() => confirmDelete(record.dataset_id, record.module_id)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <div className="">
        <Table
          columns={columns}
          dataSource={datasets}
          bordered
          pagination={{
            pageSizeOptions: ["10", "15", "25"],
            showSizeChanger: true,
          }}
        />
      </div>
    </Spin>
  );
};
