import { Button, Image } from "antd";
import React, {FC, useContext, useEffect, useState} from "react";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import { errorNotification, successNotification } from "../../Services/NotificationService";
import { ImageContext } from "./ImageContext/ImageContext";
import {NavigationContext} from "../SideNavigation/SideNavigation";

export const GrayScaleImageComponent: FC = () => {
  const { image, setSessionSavable, setData, sessionData, setSessionData, stepOneData, resetFields } = useContext(ImageContext);
  const {setGlobalSessionSave} = useConfig();
  const [grayScale, setGrayScale] = useState<string>();
  const { post } = useConnection();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    restoreStepOneSession();
  }, [stepOneData]);

  useEffect(() => {
    if(resetFields) {
      setGrayScale('');
    }
  }, [resetFields]);

  const restoreStepOneSession = () => {
    if(stepOneData?.response_data?.data) {
      setGrayScale(stepOneData.response_data.data.processed_image);
    }
  }

  const processImage = async () => {
    const base64 = image.split(",")[0];
    setLoading(true);
    try {
      const data = {
        image: image.split(",")[1],
      }
      const result = await post("modules/image/convertToGrayScale", data);
      const dimage = base64 + "," + result?.data?.data?.processed_image;

      setGrayScale(dimage);
      setLoading(false);

      result.data.data.processed_image = dimage
      const step_one_data = {
        step: 'step 1',
        request_data: {
          image: image
        },
        response_data: {
          data: result.data.data
        }
      }

      await setData(step_one_data);
      setSessionSavable(true);
      setGlobalSessionSave(true);
      successNotification("Success","Gray Scaling successful!")
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message)
    }
  };

  return (
    <div className="component-box">
      <div>
        <h2>STEP 1 : Gray Scale</h2>
        <Button type="primary" onClick={processImage} loading={loading} disabled = {image.localeCompare("") == 0 ? true:false}>
          Convert
        </Button>
      </div>
      <div className={"img-cont"}>
        {grayScale && <Image src={grayScale} />}
      </div>
    </div>
  );
};
