import React, { FC } from "react";
import { UploadFiles } from "../../Components/RegressionComponents/UploadFile";
import { Plot } from "../../Components/RegressionComponents/StepOnePlot";
import { RegressionEquation } from "../../Components/RegressionComponents/StepTwoRegressionEquation";
import { StepThreeEstimate } from "../../Components/RegressionComponents/StepThreeEstimate";
import { MultiVariateRegression } from "../../Components/RegressionComponents/StepFourMultivariateRegression";
import {RegressionContextProvider, useRegression} from "./RegressionContext";
import "../styles.scss";
import {Modal} from "antd";

export const RegressionContainer: FC = () => {
  return (
    <RegressionContextProvider>
      <WrapperItem />
    </RegressionContextProvider>
  );
};

const WrapperItem: FC = () => {
    const { saveSession, restoreSession } = useRegression();
    const restoreWarn = () => {
        const modal = Modal.confirm({
            title: 'Warning',
            content: `Restore will clear all your unsaved data`,
            onOk() {
                restoreSession();
                modal.destroy();
            },
            onCancel() {
                modal.destroy();
            }
        });
    }
  return (
    <>
        <div
            style={{
                marginBottom: 20,
                justifyContent: "center",
            }}
        >
            <span
                style={{
                    fontSize: 30,
                }}
            >
              Regression
            </span>
            <div
                style={{
                    float: "right"
                }}
            >
                <button
                    onClick={saveSession}
                    className={"btn-save"}
                > Save </button>
                <button
                    onClick={restoreWarn}
                    className={"btn-restore"}
                > Restore </button>
            </div>
            <div style={{clear: 'both'}}></div>
        </div>
      <UploadFiles />
      <Plot />
      <RegressionEquation />
      <StepThreeEstimate />
      <MultiVariateRegression />
    </>
  );
};
