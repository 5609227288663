import React, { FC } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Login } from "../Pages/Login/Login";
import { SideNavigation } from "../Components/SideNavigation/SideNavigation";
import { ProtectedRoute } from "../Routes/ProtectedRoute";

export const MainLayout: FC = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute path="/" component={SideNavigation} exact={true} />
          <ProtectedRoute exact path="/*" component={SideNavigation} />
        </Switch>
      </Router>
    </>
  );
};
