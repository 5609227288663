import React, {FC, useContext} from "react";
import { LocationMap } from "../../Components/LocationComponent/LocationMap";
import { LocationForm } from "../../Components/LocationComponent/LocationForm";
import {Col, Modal, Row} from "antd";
import LocationContextProvider, {LocationContext} from "../../Components/LocationComponent/LocationContext/LocationContext";
import "../styles.scss";
export const LocationContainer: FC = () => {
  return (
    <LocationContextProvider>
      <LocationContainerWrapper />
    </LocationContextProvider>
  );
};

export const LocationContainerWrapper: FC = () => {
    const { saveSession, restoreSession } = useContext(LocationContext);
    const restoreWarn = () => {
        const modal = Modal.confirm({
            title: 'Warning',
            content: `Restore will clear all your unsaved data`,
            onOk() {
                restoreSession();
                modal.destroy();
            },
            onCancel() {
                modal.destroy();
            }
        });
    }
    return (
        <>
            <div
                style={{
                    marginBottom: 20,
                    justifyContent: "center",
                }}
            >
                <span
                    style={{
                        fontSize: 30,
                    }}
                >
                  Location
                </span>
                <div
                    style={{
                        float: "right"
                    }}
                >
                    <button
                        onClick={saveSession}
                        className={"btn-save"}
                    > Save </button>
                    <button
                        onClick={restoreWarn}
                        className={"btn-restore"}
                    > Restore </button>
                </div>
                <div style={{clear: 'both'}}></div>
            </div>
            <div>

                <div>
                    <Row  >
                        <LocationMap />
                    </Row>
                    <Row>
                        <LocationForm />
                    </Row>
                    {/* <Row style={{ justifyContent: "space-between " }}>
          <Col>
            <LocationMap />
          </Col>
          <Col>
            <LocationForm />
          </Col>
        </Row> */}
                </div>
            </div>
        </>
    )
}
