import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Form, Input, Radio, Spin } from "antd";
import { CSVLink } from "react-csv";
import { ClusteringContext } from "./ClusteringContext";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import {
  errorNotification,
  infoNotification,
  successNotification,
} from "../../Services/NotificationService";
import {NavigationContext} from "../SideNavigation/SideNavigation";
export const UploadClusterFile: FC = () => {
  const [dataSetOption, setDataSetOption] = useState<any>(-1);
  const [sampleFile, setSampleFile] = useState<string>("");
  const {
    setFile,
    setVariableList,
    setPlot,
    setTableData,
    setAxisValue,
    setRandomSeed,
    stepOneData,
    sessionData,
    setSessionData,
    resetFields,
    setResetFields,
    setSessionSavable
  } = useContext(ClusteringContext);
  const {setGlobalSessionSave} = useConfig();
  const { post , get} = useConnection();
  const [loading, setLoading] = useState<boolean>(false);
  const [datasets,setDatasets] = useState<any>([''])
  const [form] = Form.useForm();

  //get base64
  const getBase64 = (file: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const onChange = (e: any) => {
    setPlot("");
    setTableData([]);
    setAxisValue({ x: "", y: "" });
    setRandomSeed(-1);
    setVariableList([""]);
    setResetFields(true);
    if (e.target.files[0]) {
      getBase64(e.target.files[0], (fileBase64: string) => {
        fileBase64 = fileBase64.split(",")[1];

        let decode = window.atob(fileBase64).split("\n")[0].split(",");
        
        if (decode[0].split("ï»¿")[0].localeCompare("") == 0) {
          decode[0] = decode[0] = decode[0].split("ï»¿")[1];
        }

        console.log(decode);
        if (parseFloat(decode[0])) {
          errorNotification("Error", "Upload files with headers!");
          setDataSetOption(-1);
          setFile("");
          setPlot("");
          setTableData([]);
          form.resetFields();
          return;
        }
        successNotification("Success", "Dataset changed!");
        setVariableList(decode);
        setFile(fileBase64);
      });
    }
    setResetFields(false);
    setSessionSavable(false);
    setGlobalSessionSave(false);
    sessionData.session_data = [];
    setSessionData(sessionData);
  };

  useEffect(() => {
    setSampleFile(atob("eCx5CjAuODUsMTcuNDUKMC43NSwxNS42CjMuMywxNS40NQo1LjI1LDE0LjIKNC45LDE1LjY1CjUuMzUsMTUuODU="))
    getDataSets();
  }, []);

  useEffect(() => {
    restoreStepZeroSession();
  }, [stepOneData]);

  const restoreStepZeroSession = () => {
    console.log(stepOneData.request_data.text);
    if(stepOneData?.request_data?.data) {
      setFile(stepOneData?.request_data?.data);
    }
  }

  const onRadioChange = async (e: any) => {
    console.log(e.target.value);
    setPlot("");
    setTableData([]);
    setAxisValue({ x: "", y: "" });
    setRandomSeed(-1);
    setVariableList([""]);
    try {
      setDataSetOption(e.target.value);
      setLoading(true);
      if (e.target.value !== 'custom') {
        const result = await post("datasets/get_dataset_data/", {
          dataset_id: e.target.value,
        });
        console.log(result)
        let decode = atob(result.data.dataset).split("\n")[0].split(",");

        if (decode[0].split("ï»¿")[0].localeCompare("") == 0) {
          decode[0] =  decode[0].split("ï»¿")[1];
          console.log(decode)
        }
       setVariableList(decode);
       console.log("atob----", decode[0]);
        setFile(result.data.dataset);
        successNotification("Success", "Dataset changed!");
      } else {
      }
      // infoNotification("Dataset", "Dataset Uploaded");
      setLoading(false);
    } catch (error) {
      errorNotification("Error", error.message);
      setLoading(false);
    }
  };

  const getDataSets = async () => {
    try {
      // const result2 = await get("datasets/view_datasets/")
      // const data = result2?.data?.datasets.filter(
      //   (dataset: any) => dataset.module_id == 6
      // );
      // console.log(result2)
      const result = await get("modules/clustering/getSamples");
      const data = result?.data?.data;
      setDatasets(data)
    } catch (error) {
      errorNotification("Error", error.message)
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Upload Files</h2>
        <CSVLink data={sampleFile} filename={"clusteringSample.csv"}>
          Sample File
        </CSVLink>
        <Form form={form}>
          <Form.Item label="Select from sample dataset" name="sample">
            <Radio.Group onChange={(e: any) => onRadioChange(e)}>
              {
                datasets.map((dataset:any) => <Radio value={dataset.dataset_id}>{dataset.dataset_name}</Radio>)
              }
              <Radio value={'custom'}>Upload custom dataset</Radio>
            </Radio.Group>
          </Form.Item>
          {dataSetOption === 'custom' && (
            <Form.Item name="file">
              <h3>Data File</h3>
              <Input type="file" accept=".csv,text/plain" onChange={onChange} />
            </Form.Item>
          )}
        </Form>

        {/* </Spin> */}
      </div>
    </Spin>
  );
};
