import { Button, Image } from "antd";
import React, {FC, useContext, useEffect, useState} from "react";
import { useConnection } from "../../Services/ApplicationContext";
import { ImageContext } from "./ImageContext/ImageContext";
import { LoadingOutlined } from "@ant-design/icons";
import { errorNotification, successNotification } from "../../Services/NotificationService";

export const FaceDetectionComponent: FC = () => {
  const { image, setData, stepFiveData, resetFields } = useContext(ImageContext);
  const [faceDetection, setFaceDetection] = useState<string>();
  const { post } = useConnection();
  const [loading, setLoading] = useState<boolean>(false);
  const [faceCount, setFaceCount] = useState<number>(0);

  useEffect(() => {
    restoreStepFiveSession();
  }, [stepFiveData]);

  useEffect(() => {
    if(resetFields) {
      setFaceDetection('');
    }
  }, [resetFields]);

  const restoreStepFiveSession = () => {
    if(stepFiveData?.response_data?.data) {
      setFaceDetection(stepFiveData?.response_data?.data?.processed_image);
    }
  }

  const processImage = async () => {
    const base64 = image.split(",")[0];
    setLoading(true);
    try {
      const result = await post("modules/image/faceDetection", {
        image: image.split(",")[1],
      });
      const dimage = base64 + "," + result?.data?.data?.processed_image;

      setFaceDetection(dimage);
      setFaceCount(result.data.data.face_array.length);
      setLoading(false);
      result.data.data.processed_image = dimage;
      const step_five_data = {
        step: 'step 5',
        request_data: {
          image: image
        },
        response_data: {
          data: result.data.data
        }
      }
      await setData(step_five_data);
      successNotification("Success", "Face detection successful!");
    } catch (error) {
      console.log("exep face detection : ", error);
      setLoading(false);
      errorNotification("Error", error.message)

    }
  };

  return (
    <div className="component-box">
      <div>
        <h2>Step 5: Face Detection</h2>
        <Button type="primary" onClick={processImage} loading={loading} disabled = {image.localeCompare("") == 0 ? true:false}>
          Detect
        </Button>
      </div>
      <div className={"img-cont"}>
        {faceDetection && <Image src={faceDetection} />}
      </div>
      <div>
        {faceDetection && faceCount == 0 && <span>No faces detected!</span>}
      </div>
    </div>
  );
};
