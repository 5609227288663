import React, { FC, useContext, useEffect, useState } from "react";
import { Form, Input, Radio, Spin } from "antd";
import "./styles.scss";
import TextContext from "./TextContext/TextContext";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import {errorNotification, successNotification} from "../../Services/NotificationService";
import {NavigationContext} from "../SideNavigation/SideNavigation";

export const DataComponent: FC = () => {
  const { get, post } = useConnection();
  const token = localStorage.getItem("token");
  const { text, setText, stepOneData, setSessionSavable, setResetFields, sessionData, setSessionData, setVisualize } = useContext(TextContext);
  const {setGlobalSessionSave} = useConfig();
  const [sampleData, setSampleData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    getSamples();
  }, []);

  useEffect(() => {
    // set the data from stepOneData
    restoreStepZeroSession();
  }, [stepOneData])

  const restoreStepZeroSession = () => {
    console.log(stepOneData.request_data.text);
    if(stepOneData?.request_data?.text) {
      setText(stepOneData?.request_data?.text);
    }
  }

  const getSamples = async () => {
    setLoading(true);
    const headers = { authorization: `Bearer ${token}` };
    try {
      const result = await get("modules/text/getSamples");
      console.log('hs', result);
      const data = result?.data?.data;
      console.log(data);
      setSampleData(data);
    } catch (error) {
      console.log(error);
      errorNotification("Error", error.message)
    }
    setLoading(false);
  };

  const onTextChange = async (e: any) => {
    console.log(e.target.value);
    setResetFields(true);
    setVisualize(false);
    const headers = { authorization: `Bearer ${token}` };
    try {
      setLoading(true);
      const result = await post(
        "datasets/get_dataset_data/",
        {
          dataset_id: e.target.value,
        },
        {
          headers: headers,
        }
      );
      console.log(result);

      setText(result.data.dataset);
      successNotification("Success", "Dataset changed!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message)
      console.log(error);
    }
    setResetFields(false);
    setSessionSavable(false);
    setGlobalSessionSave(false);
    sessionData.session_data = [];
    setSessionData(sessionData);
  };

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Enter Text</h2>
        <Form>
          <Form.Item>
            <Input.TextArea
              rows={5}
              maxLength={25000}
              placeholder="Enter text here..."
              defaultValue={text}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Or select from sample text">
            <Radio.Group onChange={onTextChange}>
              {sampleData.map((data: any) => (
                <Radio value={data.dataset_id}>{data.dataset_name}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};
