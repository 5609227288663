import React, { FC, useContext, useEffect, useState } from "react";
import {useConfig, useConnection} from "../../../Services/ApplicationContext";
import {errorNotification, successNotification} from "../../../Services/NotificationService";
import {NavigationContext} from "../../SideNavigation/SideNavigation";

export type LocationContextProps = {
  map: string;
  setMap: (val: string) => void;
  loading:boolean;
  setLoading:(val:boolean) => void;
  sessionData: any;
  setSessionData: (val: any) => void;
  stepOneData: any;
  setStepOneData: (val: any) => void;
  stepTwoData: any;
  setStepTwoData: (val: any) => void;
  sessionSavable: boolean;
  setSessionSavable: (val: boolean) => void;
  saveSession: () => Promise<void>;
  restoreSession: () => Promise<void>;
  resetFields: boolean;
  setResetFields: (val: boolean) => void;
};

export const LocationContext = React.createContext<LocationContextProps>({
  map: "",
  setMap: () => {},
  loading:false,
  setLoading:() => {},
  sessionData: {},
  setSessionData: () => {},
  stepOneData: {},
  setStepOneData: () => {},
  stepTwoData: {},
  setStepTwoData: () => {},
  sessionSavable: false,
  setSessionSavable: () =>{},
  saveSession: async () => {},
  restoreSession: async () => {},
  resetFields: false,
  setResetFields: () =>{},
});

export const LocationContextProvider: FC = (props) => {
  const { post } = useConnection();
  // const {setGlobalSessionSave} = useConfig();
  const {setGlobalSessionSave} = useContext(NavigationContext);
  const [map, setMap] = useState<any>("");
  const [loading,setLoading] = useState<any>(false);
  const [sessionSavable, setSessionSavable] = useState<boolean>(false);
  const [sessionData, setSessionData] = useState<any>({
    module: "location",
    session_data: []
  });
  const [stepOneData, setStepOneData] = useState<any>({
    step: "step 1",
    request_data: {
      hour_list: [],
      location: '',
      show_labels: false,
      type_list: []
    },
    response_data: {
      data:null
    }
  });
  const [stepTwoData, setStepTwoData] = useState<any>({
    step: "step 2",
    request_data: {
      individual: '',
      location: '',
      path_order: false,
      radius: 20,
      show_labels: false,
      start_end: false
    },
    response_data: {
      data:null
    }
  });
  const [resetFields, setResetFields] = useState<boolean>(false);

  const saveSession = async () => {
    console.log("save session")
    if(sessionSavable) {
      console.log("saving")
      try{
        const result = await post("session/save", sessionData);
        setGlobalSessionSave(false);
        successNotification("Success", "Session Saved")
      } catch (error) {
        console.log("Exception: save session: location ", error);
        errorNotification("Error", error.message);
      }
    }
  }
  const restoreSession = async () => {
    try{
      setResetFields(true);
      const result = await post("session/restore", { module: "location" });
      console.log(result)
      // setRestoreFieldClear(!restoreFieldClear);
      const s_data = result?.data?.data;
      for(let i=0; i<s_data.session_data?.length; i++) {
        const step = s_data.session_data[i];
        switch (step.step) {
          case "step 1": {
            console.log("restoring step 1");
            setStepOneData(step);
          } break;
          case "step 2": {
            console.log("restoring step 2");
            setStepTwoData(step);
          } break;
        }
      }
      setSessionData(s_data);
      setSessionSavable(true);
      setGlobalSessionSave(true);
      successNotification("Success", "Session restored")
    } catch (error) {
      console.log("Exception: restore session: location ", error);
      errorNotification("Error", error.message);
    }
    setResetFields(false);
  }

  useEffect(() => {
    setGlobalSessionSave(sessionSavable);
  }, [sessionSavable]);

  return (
    <LocationContext.Provider
        value={{
          map,
          setMap,
          loading,
          setLoading,
          sessionData,
          setSessionData,
          stepOneData,
          setStepOneData,
          stepTwoData,
          setStepTwoData,
          sessionSavable,
          setSessionSavable,
          saveSession,
          restoreSession,
          resetFields,
          setResetFields
        }}>
      {props.children}
    </LocationContext.Provider>
  );
};

export default LocationContextProvider;
