import React, { FC } from "react";
import { Form, Input, Button, Checkbox, Space, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { useConnection, useUser } from "../../Services/ApplicationContext";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import sha256 from "sha256";
import jwt_decode from "jwt-decode";
import "./Login.scss";

export const Login: FC = () => {
  const history = useHistory();
  const { get, post, updateToken } = useConnection();
  const { setToken, setRole, setEmail, setDecoded } = useUser();

  const login = async (values: any): Promise<void> => {
    localStorage.removeItem("token");
    values.password = sha256(values.password);

    try {
      const result = await post("users/login/", values);
      updateToken(result?.data.token);
    
      const tokenx: any = jwt_decode(result?.data.token);
       console.log(tokenx)
      localStorage.setItem("id", tokenx.uid);
      localStorage.setItem("role", tokenx.usr);
      localStorage.setItem("email", tokenx.sub);
      localStorage.setItem("decoded", tokenx);
      setDecoded(tokenx);
      setEmail(tokenx.sub);
      setRole(tokenx.usr);
      const r: any = localStorage.getItem("role");
      if (r == 1) {
        history.push("/licenseall");
        localStorage.setItem("path", "/licenseall");
      } else if(r== 2) {
        history.push("/viewstudents");
        localStorage.setItem("path", "/viewstudents");
      } else {
        const result2 = await get("users/view_modules/");
        const mod = result2.data.modules;
        if(mod.length > 0) {
          const module_id = mod[0].module_id;
          switch (module_id) {
            case 1: {
              history.push("/text");
              localStorage.setItem("path", "/text");
            }break;
            case 2: {
              history.push("/image");
              localStorage.setItem("path", "/image");
            }break;
            case 3: {
              history.push("/regression");
              localStorage.setItem("path", "/regression");
            }break;
            case 4: {
              history.push("/network");
              localStorage.setItem("path", "/network");
            }break;
            case 5: {
              history.push("/location");
              localStorage.setItem("path", "/location");
            }break;
            case 6: {
              history.push("/clustering");
              localStorage.setItem("path", "/clustering");
            }break;
            case 7: {
              history.push("/decisiontree");
              localStorage.setItem("path", "/decisiontree");
            }break;
          }
        } else {
          history.push("/");
          localStorage.setItem("path", "/");
        }
      }
      successNotification("Success", "Login Successful!");
    } catch (error) {
      errorNotification("Error", error.message)
      console.log(error);
    }
  };

  return (
    <div className="container">
      <div className="login-box">
        <div className="sign-in-box">
          <h2>Welcome Back!</h2>
          <span>Sign in to continue dashboard</span>
          <div className="sign-in-round"></div>
        </div>

        <div className="login-form-content">
          <Form
            className="login-form"
            onFinish={login}
            layout="vertical"
            name="basic"
            // onFinish={login}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  required: true,
                  message: "Please input valid email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password className="login-form-password" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-button">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
