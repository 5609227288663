import React, {FC, useContext, useEffect, useState} from "react";
import {errorNotification, successNotification} from "../../../Services/NotificationService";
import {useConfig, useConnection} from "../../../Services/ApplicationContext";
import {NavigationContext} from "../../SideNavigation/SideNavigation";

export type ImageContextProps = {
  image: string;
  setImage: (val: string) => void;
  sessionData: any;
  setSessionData: (val: any) => void;
  stepOneData: any;
  setStepOneData: (val: any) => void;
  stepTwoData: any;
  setStepTwoData: (val: any) => void;
  stepThreeData: any;
  setStepThreeData: (val: any) => void;
  stepFourData: any;
  setStepFourData: (val: any) => void;
  stepFiveData: any;
  setStepFiveData: (val: any) => void;
  stepSixData: any;
  setStepSixData: (val: any) => void;
  sessionSavable: boolean;
  setSessionSavable: (val: boolean) => void;
  saveSession: () => Promise<void>;
  restoreSession: () => Promise<void>;
  setData: (val: any) => Promise<void>;
  resetFields: boolean;
  setResetFields: (val: boolean) => void;
};

export const ImageContext = React.createContext<ImageContextProps>({
  image: "",
  setImage: () => {},
  sessionData: {},
  setSessionData: () => {},
  stepOneData: {},
  setStepOneData: () => {},
  stepTwoData: {},
  setStepTwoData: () => {},
  stepThreeData: {},
  setStepThreeData: () => {},
  stepFourData: {},
  setStepFourData: () => {},
  stepFiveData: {},
  setStepFiveData: () => {},
  stepSixData: {},
  setStepSixData: () => {},
  sessionSavable: false,
  setSessionSavable: () =>{},
  saveSession: async () => {},
  restoreSession: async () => {},
  setData: async (val: any) => {},
  resetFields: false,
  setResetFields: () =>{},
});

export const ImageContextProvider: FC = (props) => {
  const { post } = useConnection();
  const {setGlobalSessionSave} = useConfig();
  const [image, setImage] = useState<string>("");
  const [sessionData, setSessionData] = useState<any>({
    module: "image",
    session_data: []
  });
  const [stepOneData, setStepOneData] = useState<any>({
    step: "step 1",
    request_data: {
      image: ''
    },
    response_data: {
      data:null
    }
  });
  const [stepTwoData, setStepTwoData] = useState<any>({
    step: "step 2",
    request_data: {
      data: '',
      clusters:0,
      random_seed:0,
      x: '',
      y: ''
    },
    response_data: {
      data:null
    }
  });
  const [stepThreeData, setStepThreeData] = useState<any>({
    step: "step 3",
    request_data: {
      data: '',
      x: '',
      y: ''
    },
    response_data: {
      data:null
    }
  });
  const [stepFourData, setStepFourData] = useState<any>({
    step: "step 4",
    request_data: {
      data: '',
      clusters:0,
      random_seed:0,
      x: '',
      y: ''
    },
    response_data: {
      data:null
    }
  });
  const [stepFiveData, setStepFiveData] = useState<any>({
    step: "step 5",
    request_data: {
      data: '',
      x: '',
      y: ''
    },
    response_data: {
      data:null
    }
  });
  const [stepSixData, setStepSixData] = useState<any>({
    step: "step 6",
    request_data: {
      data: '',
      clusters:0,
      random_seed:0,
      x: '',
      y: ''
    },
    response_data: {
      data:null
    }
  });
  const [sessionSavable, setSessionSavable] = useState<boolean>(false);
  const [resetFields, setResetFields] = useState<boolean>(false);

  const saveSession = async () => {
    console.log("save session")
    if(sessionSavable) {
      console.log("saving")
      try{
        const result = await post("session/save", sessionData);
        setGlobalSessionSave(false);
        successNotification("Success", "Session Saved!")
      } catch (error) {
        console.log("Exception: save session: text ", error);
        errorNotification("Error", error.message);
      }
    }
  }
  const restoreSession = async () => {
    try{
      setResetFields(true);
      setResetFields(false);
      const result = await post("session/restore", { module: "image" });
      console.log(result)
      // setRestoreFieldClear(!restoreFieldClear);
      const s_data = result?.data?.data;
      for(let i=0; i<s_data.session_data?.length; i++) {
        const step = s_data.session_data[i];
        switch (step.step) {
          case "step 1": {
            console.log("restoring step 1");
            setImage(step.request_data.image);
            setStepOneData(step);
          } break;
          case "step 2": {
            console.log("restoring step 2");
            setStepTwoData(step);
          } break;
          case "step 3": {
            console.log("restoring step 3");
            setStepThreeData(step);
          } break;
          case "step 4": {
            console.log("restoring step 4");
            setStepFourData(step);
          } break;
          case "step 5": {
            console.log("restoring step 5");
            setStepFiveData(step);
          } break;
          case "step 6": {
            console.log("restoring step 6");
            setStepSixData(step);
          } break;
        }
      }
      setSessionData(s_data);
      setSessionSavable(true);
      setGlobalSessionSave(false);
      successNotification("Success", "Session restored!")
    } catch (error) {
      console.log("Exception: restore session: clustering ", error);
      errorNotification("Error", error.message);
    }
  }

  const setData = async (step_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == step_data.step) {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_data);
      setSessionData(s_data);
    }
    console.log(sessionData);
  }

  useEffect(() => {
    setGlobalSessionSave(sessionSavable);
  }, [sessionSavable]);

  return (
    <ImageContext.Provider
        value={{
          image,
          setImage,
          sessionData,
          setSessionData,
          stepOneData,
          setStepOneData,
          stepTwoData,
          setStepTwoData,
          stepThreeData,
          setStepThreeData,
          stepFourData,
          setStepFourData,
          stepFiveData,
          setStepFiveData,
          stepSixData,
          setStepSixData,
          sessionSavable,
          setSessionSavable,
          saveSession,
          restoreSession,
          setData,
          resetFields,
          setResetFields
        }}
    >
      {props.children}
    </ImageContext.Provider>
  );
};

export default ImageContextProvider;
