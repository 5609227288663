import React, {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import {errorNotification, infoNotification, successNotification} from "../../Services/NotificationService";

export type DataPointProps = { x: number; y: number };
export type MetaParameterProps = {
  alpha: number;
  beta: number;
  twoPoints?: DataPointProps[];
};
export type PredictedDataProps = {
  error: number;
  prediction: number;
  x: number;
  y: number;
};
export type EstimatedProps = {
  coefficients: {
    adj_r2: number;
    alpha: number;
    beta: number;
    dof: number;
    p_value: number;
    r2: number;
    t_value: number;
  };
  comparison: [
    {
      estimate: number;
      name: string;
      p_value: number;
      std_error: number;
      t_value: number;
    }
  ];
  data_points: [
    {
      x: 0;
      y: 0;
    }
  ];
};
export type RegressionContextProps = {
  dataFile: string[];
  setDataFile: (val: string[]) => void;
  selectedDataFile?: string;
  setSelectedDataFile: (val?: string) => void;
  variableList: string[];
  setVariableList: (val: string[]) => void;
  dependentVariable?: string;
  independentVariable?: string;
  setDependentVariable: (val: string) => void;
  setIndependentVariable: (val: string) => void;
  dataPoints?: DataPointProps[];
  setDataPoints: (val: any) => void;
  fetchDataPoints: (val: DataPointProps) => Promise<void>;
  fetchTestRegressionPoints: (val: MetaParameterProps) => Promise<void>;
  fetchEstimation: () => Promise<void>;
  metaParams: MetaParameterProps;
  setMetaParams: (val: MetaParameterProps) => void;
  predictedData?: PredictedDataProps[];
  setPredictedData: (val: any) => void;
  estimated?: EstimatedProps;
  setEstimated: (val: any) => void;
  samples: string[];
  setSamples: (val: string[]) => void;
  downloadSample: string;
  setDownLoadSample: (val: string) => void;
  sessionData: any;
  setSessionData: (val: any) => void;
  stepOneData: any;
  setStepOneData: (val: any) => void;
  stepTwoData: any;
  setStepTwoData: (val: any) => void;
  stepThreeData: any;
  setStepThreeData: (val: any) => void;
  stepFourData: any;
  setStepFourData: (val: any) => void;
  sessionSavable: boolean;
  setSessionSavable: (val: boolean) => void;
  saveSession: () => Promise<void>;
  restoreSession: () => Promise<void>;
  setData: (val: any) => Promise<void>;
  resetFields: boolean;
  setResetFields: (val: boolean) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
};

export const RegressionContext = createContext<{
  current: RegressionContextProps;
}>({ current: {} as any });

export const RegressionContextProvider: FC = ({ children }) => {
  const [dataFile, setDataFile] = useState<string[]>([]);
  const [variableList, setVariableList] = useState<string[]>([]);
  const [selectedDataFile, setSelectedDataFile] = useState<string>();
  const [dependentVariable, setDependentVariable] = useState<string>();
  const [independentVariable, setIndependentVariable] = useState<string>();
  const [dataPoints, setDataPoints] = useState<DataPointProps[]>();
  const [predictedData, setPredictedData] = useState<PredictedDataProps[]>();
  const [estimated, setEstimated] = useState<EstimatedProps>();
  const [samples,setSamples] = useState<string[]>([])
  const [downloadSample,setDownLoadSample] = useState<string>('')
  // const [metaParams, setMetaParams] = useState<MetaParameterProps>();
  const [metaParams, setMetaParams] = useState<MetaParameterProps>({
    alpha: 25792.2001986687,
    beta: 9449.96232145508,
  });
  const { get, post } = useConnection();
  const {setGlobalSessionSave} = useConfig();
  const [sessionData, setSessionData] = useState<any>({
    module: "regression",
    session_data: []
  });
  const [stepOneData, setStepOneData] = useState<any>({
    step: "step 1",
    request_data: {
      image: ''
    },
    response_data: {
      data:null
    }
  });
  const [stepTwoData, setStepTwoData] = useState<any>({
    step: "step 2",
    request_data: {
      data: '',
      clusters:0,
      random_seed:0,
      x: '',
      y: ''
    },
    response_data: {
      data:null
    }
  });
  const [stepThreeData, setStepThreeData] = useState<any>({
    step: "step 3",
    request_data: {
      data: '',
      x: '',
      y: ''
    },
    response_data: {
      data:null
    }
  });
  const [stepFourData, setStepFourData] = useState<any>({
    step: "step 4",
    request_data: {
      data: '',
      clusters:0,
      random_seed:0,
      x: '',
      y: ''
    },
    response_data: {
      data:null
    }
  });
  const [sessionSavable, setSessionSavable] = useState<boolean>(false);
  const [resetFields, setResetFields] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setGlobalSessionSave(sessionSavable);
  }, [sessionSavable]);

  const fetchDataSamples = async () => {
    try {
      setLoading(true);
      const result2 = await get("modules/regression/getSamples");
      const data = result2?.data?.data;
      console.log('result2', result2)
      if(data.length > 0) {
        setSamples(data);
      }
      setDownLoadSample("WWVhcnNFeHBlcmllbmNlX2NoYW5nZWQsU2FsYXJ5X2NoYW5nZWQNCjEuMSwzOTM0Mw0KMS4zLDQ2MjA1DQoxLjUsMzc3MzENCjIsNDM1MjUNCjIuMiwzOTg5MQ0KMi45LDU2NjQyDQozLDYwMTUwDQozLjIsNTQ0NDUNCjMuMiw2NDQ0NQ0KMy43LDU3MTg5DQozLjksNjMyMTgNCjQsNTU3OTQNCjQsNTY5NTcNCjQuMSw1NzA4MQ0KNC41LDYxMTExDQo0LjksNjc5MzgNCjUuMSw2NjAyOQ0KNS4zLDgzMDg4DQo1LjksODEzNjMNCjYsOTM5NDANCjYuOCw5MTczOA0KNy4xLDk4MjczDQo3LjksMTAxMzAyDQo4LjIsMTEzODEyDQo4LjcsMTA5NDMxDQo5LDEwNTU4Mg0KOS41LDExNjk2OQ0KOS42LDExMjYzNQ0KMTAuMywxMjIzOTENCjEwLjUsMTIxODcyDQoxMC41LDEyMTg3Mg==");
      setLoading(false);
    } catch (e) {
      console.log("Exception: get data samples: regression: ", e);
      errorNotification("Error", e.message);
      setLoading(false);
    }
  };
  const fetchDataPoints = async (val: DataPointProps) => {
    try {
      const result = await post("modules/regression/getDataPoints", {
        base64_text: selectedDataFile,
        ...val,
      });
      const temp = result.data.data;
      setDataPoints(temp?.data_points);
      setMetaParams({
        ...metaParams,
        alpha: temp?.alpha,
        beta: temp?.beta,
        twoPoints: [],
      });

      const step_one_data = {
        step: 'step 1',
        request_data: {
          base64_text: selectedDataFile,
            ...val
        },
        meta_params: metaParams,
        response_data: {
          data: result.data.data
        }
      }
      await setData(step_one_data);
      setSessionSavable(true);
      setGlobalSessionSave(true);
    } catch (e) {
      console.log("Exception: get data samples: regression: ", e);
      errorNotification("Error", e.message);
    }
  };
  const fetchTestRegressionPoints = async (val: MetaParameterProps) => {
    try {
      console.log(val);
      const result = await post("modules/regression/getTestRegressionPoints", {
        data_points: dataPoints,
        ...val,
      });
      const temp = result.data?.data;
      if (Array.isArray(temp?.data_points) && temp?.data_points.length === 0) {
        infoNotification("Information", "Regression line is out of the view!");
      }
      setMetaParams({ ...val, twoPoints: temp.data_points });
      setPredictedData(temp.predictions);

      const step_two_data = {
        step: 'step 2',
        request_data: {
          data_points: dataPoints,
          alpha: val.alpha,
          beta: val.beta
        },
        meta_params: metaParams,
        response_data: {
          data: result.data.data
        }
      }

      await setData(step_two_data);
    } catch (e) {
      console.log("Exception: get data samples: regression: ", e);
    }
  };
  const fetchEstimation = async () => {
    try {
      const result = await post("modules/regression/getEstimation", {
        data_points: dataPoints,
      });
      const temp = result.data?.data;
      setEstimated(temp);
      if (Array.isArray(temp?.data_points) && temp?.data_points.length === 0) {
        infoNotification("Information", "Regression line is out of the view!");
      }
      setMetaParams({
        twoPoints: temp.data_points,
        alpha: temp?.coefficients?.alpha ?? metaParams.alpha,
        beta: temp?.coefficients?.beta ?? metaParams.beta,
      });

      const step_three_data = {
        step: 'step 3',
        request_data: {
          data_points: dataPoints,
        },
        meta_params: metaParams,
        response_data: {
          data: result.data.data
        }
      }

      await setData(step_three_data);
      successNotification("Success", "Estimated successfully!")
    } catch (e) {
      console.log("Exception: get data samples: regression: ", e);
    }
  };

  const saveSession = async () => {
    console.log("save session")
    if(sessionSavable) {
      console.log("saving")
      try{
        const result = await post("session/save", sessionData);
        setGlobalSessionSave(false);
        successNotification("Success", "Session Saved!")
      } catch (error) {
        console.log("Exception: save session: text ", error);
        errorNotification("Error", error.message);
      }
    }
  }
  const restoreSession = async () => {
    try{
      setResetFields(true);
      setResetFields(false);
      const result = await post("session/restore", { module: "regression" });
      console.log(result)
      // setRestoreFieldClear(!restoreFieldClear);
      const s_data = result?.data?.data;
      for(let i=0; i<s_data.session_data?.length; i++) {
        const step = s_data.session_data[i];
        switch (step.step) {
          case "step 1": {
            console.log("restoring step 1");
            setSelectedDataFile(step.request_data.base64_text);
            setDataFile(step.request_data.base64_text);
            const decoded: string = atob(step.request_data.base64_text);
            setVariableList(decoded.split("\n")[0].split(","));
            setDataPoints(step.response_data.data.data_points);
            await setStepOneData(step);
          } break;
          case "step 2": {
            console.log("restoring step 2");
            await setStepTwoData(step);
          } break;
          case "step 3": {
            console.log("restoring step 3");
            await setStepThreeData(step);
          } break;
          case "step 4": {
            console.log("restoring step 4");
            await setStepFourData(step);
          } break;
        }
      }
      setSessionData(s_data);
      setSessionSavable(true);
      setGlobalSessionSave(true);
      successNotification("Success", "Session restored!")
    } catch (error) {
      console.log("Exception: restore session: clustering ", error);
      errorNotification("Error", error.message);
    }
  }
  const setData = async (step_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == step_data.step) {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_data);
      setSessionData(s_data);
    }
    console.log(sessionData);
  }

  useEffect(() => {
    fetchDataSamples();
  }, []);
  return (
    <RegressionContext.Provider
      value={{
        current: {
          dataFile,
          setDataFile,
          selectedDataFile,
          setSelectedDataFile,
          variableList,
          dependentVariable,
          independentVariable,
          setIndependentVariable,
          setDependentVariable,
          setVariableList,
          dataPoints,
          setDataPoints,
          fetchDataPoints,
          fetchTestRegressionPoints,
          fetchEstimation,
          metaParams,
          setMetaParams,
          predictedData,
          setPredictedData,
          estimated,
          setEstimated,
          samples,
          setSamples,
          downloadSample,
          setDownLoadSample,
          sessionData,
          setSessionData,
          stepOneData,
          setStepOneData,
          stepTwoData,
          setStepTwoData,
          stepThreeData,
          setStepThreeData,
          stepFourData,
          setStepFourData,
          sessionSavable,
          setSessionSavable,
          saveSession,
          restoreSession,
          setData,
          resetFields,
          setResetFields,
          loading,
          setLoading
        },
      }}
    >
      {children}
    </RegressionContext.Provider>
  );
};
export const useRegression = () => {
  return useContext<{ current: RegressionContextProps }>(RegressionContext)
    .current;
};
