import React, { FC, useContext, useEffect, useState } from "react";
import {Button, Col, Form, Input, InputNumber, Row, Select, Slider} from "antd";
import { ClusteringContext } from "./ClusteringContext";
import { useConnection } from "../../Services/ApplicationContext";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";

export const StepTwoKmeans: FC = () => {
  const {
    file,
    variableList,
    setPlot,
    randomSeed,
    setRandomSeed,
    clusterCount,
    setClusterCount,
    setTableData,
    setColumns,
    loadingGraph,
    setLoadingGraph,
    axisValue,
    sessionData,
    setSessionData,
    stepTwoData,
    setStepTwoData,
    resetFields
  } = useContext(ClusteringContext);
  const { post } = useConnection();
  const { Option } = Select;
  const [form] = Form.useForm();
 

  const columnX = [
    {
      title: "Point ID",
      dataIndex: "point_id",
      key: "point_id",
      sorter: (a: any, b: any) => a.point_id - b.point_id,
    },
    {
      title: "Cluster ID",
      dataIndex: "cluster_id",
      key: "cluster_id",
      sorter: (a: any, b: any) => a.cluster_id - b.cluster_id,
    },
    {
      title: "X",
      dataIndex: "x",
      key: "x",
      sorter: (a: any, b: any) => a.x - b.x,
    },
    {
      title: "Y",
      dataIndex: "y",
      key: "y",
      sorter: (a: any, b: any) => a.y - b.y,
    },
  ];

  useEffect(() => {
    restoreStepTwoSession();
  }, [stepTwoData]);

  const setData = (step_two_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_two_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == 'step 2') {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_two_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const restoreStepTwoSession = async () => {
    console.log(stepTwoData)
    if(stepTwoData?.response_data?.data) {
      console.log(stepTwoData.request_data.clusters, stepTwoData.request_data.random_seed);
      setRandomSeed(stepTwoData.request_data.random_seed);
      setClusterCount(stepTwoData.request_data.clusters);
      form.setFieldsValue({
        clusters: stepTwoData.request_data.clusters,
        random_seed: stepTwoData.request_data.random_seed
      });
      setColumns(columnX);
      setPlot(stepTwoData?.response_data?.data.plot);
      setTableData(stepTwoData?.response_data?.data.plot_data);
    }
  }

  const onSubmit = async (values: any) => {
    console.log(values?.random_seed);
    if (values.random_seed < 0 || values.random_seed > 100) {
      errorNotification("Error", "Random seed should be between 0 and 100!");
      return;
    }
    if (values.clusters < 1 || values.clusters > 10) {
      errorNotification(
        "Error",
        "Number of clusters should be between 1 and 10!"
      );
    }
    console.log(values);
    console.log("--- file", file);
    console.log("---***", axisValue.x)

    try {
      setRandomSeed(values?.random_seed);
      setClusterCount(values.clusters);
      setLoadingGraph(true);
      const data = {
        data: file,
        clusters: parseInt(values.clusters),
        random_seed: parseInt(values.random_seed),
        y: axisValue.y,
        x: axisValue.x,
      };
      const result = await post("modules/clustering/generateKMeansPlot", data);
      result.data.data.plot = "data:image/jpeg;base64," + result.data.data.plot;
      setPlot(result.data.data.plot);
      setColumns(columnX);
      setTableData(result.data.data.plot_data);
      console.log(result);
      setLoadingGraph(false);

      const step_two_data = {
        step: 'step 2',
        request_data: data,
        response_data: {
          data: result.data.data
        }
      }

      setData(step_two_data);

      successNotification("Success", "Plot generated successfully!");
    } catch (error) {
      errorNotification("Error", error.message)
      setLoadingGraph(false);
    }
  };

  useEffect(() => {
    form.resetFields()
  },[variableList]);

  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      setTableData([]);
      setPlot('');
    }
  }, []);

  return (
    <div className="component-box">
      <h2>Step 2: Kmeans</h2>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        {...form.setFieldsValue({
          clusters: clusterCount === 0 ? 2: clusterCount,
          random_seed: randomSeed === -1 ? "" : randomSeed,
        })}
      >
        <Form.Item
          style={{ width: "100%" }}
          label={
            <span>
              Number of clusters:{" "}
              <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                (Number of clusters must be between 1 and 10)
              </span>
            </span>
          }
          name="clusters"
          rules={[
            { required: true, message: "Please Enter a Cluster Number" },

            // {
            //   min: 1,
            //   max: 10,
            //   message: "Number of Clusters should be between 1 and 10",
            // },
          ]}
        >
          <Slider
              min={1}
              max={10}
              marks={{
                1: "1",
                2: "2",
                3: "3",
                4: "4",
                5: "5",
                6: "6",
                7: "7",
                8: "8",
                9: "9",
                10: "10",
              }}
              defaultValue={2}
              onChange={setClusterCount}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please Enter a random Seed between 0 and 100",
            },
          ]}
          label={
            <span>
              Random seed:{" "}
              <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                (Random seed must be between 0 and 100)
              </span>
            </span>
          }
          name="random_seed"
          style={{ width: "100%" }}
        >
          <Input type="number" min={0} max={100} />
        </Form.Item>
        <Button
          type="primary"
          style={{ width: "100px" }}
          htmlType="submit"
          disabled={
             
            !(randomSeed > -1)
          }
        >
          Plot
        </Button>
      </Form>
    </div>
  );
};
