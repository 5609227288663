import React, { FC, useContext, useEffect, useState } from "react";
import { Form, Input, Radio, Spin } from "antd";
import { CSVLink } from "react-csv";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import { DecisionTreeContext } from "./DecisionTreeContext";
import {errorNotification, successNotification} from "../../Services/NotificationService";
import {NavigationContext} from "../SideNavigation/SideNavigation";

export const UploadFile: FC = () => {
  const { post, get } = useConnection();
  const {
    setFile,
    setTargetVariable,
    setFeatures,
    stepOneData,
    resetFields,
    setResetFields,
    sessionData,
    setSessionData,
    setSessionSavable } = useContext(
    DecisionTreeContext
  );
  const {setGlobalSessionSave} = useConfig();
  const [datasets, setDatasets] = useState<any>([""]);
  const [sampleFile, setSampleFile] = useState<any>("");
  const [datasetOption, setDatasetOption] = useState<any>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setSampleFile(atob("Y29tcGFueSxqb2IsZGVncmVlLHNhbGFyeV9tb3JlX3RoZW5fMTAwawpnb29nbGUsc2FsZXMgZXhlY3V0aXZlLGJhY2hlbG9ycywwCmdvb2dsZSxzYWxlcyBleGVjdXRpdmUsbWFzdGVycywwCmdvb2dsZSxidXNpbmVzcyBtYW5hZ2VyLGJhY2hlbG9ycywxCmdvb2dsZSxidXNpbmVzcyBtYW5hZ2VyLG1hc3RlcnMsMQpnb29nbGUsY29tcHV0ZXIgcHJvZ3JhbW1lcixiYWNoZWxvcnMsMApnb29nbGUsY29tcHV0ZXIgcHJvZ3JhbW1lcixtYXN0ZXJzLDEKYWJjIHBoYXJtYSxzYWxlcyBleGVjdXRpdmUsbWFzdGVycywwCmFiYyBwaGFybWEsY29tcHV0ZXIgcHJvZ3JhbW1lcixiYWNoZWxvcnMsMAphYmMgcGhhcm1hLGJ1c2luZXNzIG1hbmFnZXIsYmFjaGVsb3JzLDAKYWJjIHBoYXJtYSxidXNpbmVzcyBtYW5hZ2VyLG1hc3RlcnMsMQpmYWNlYm9vayxzYWxlcyBleGVjdXRpdmUsYmFjaGVsb3JzLDEKZmFjZWJvb2ssc2FsZXMgZXhlY3V0aXZlLG1hc3RlcnMsMQpmYWNlYm9vayxidXNpbmVzcyBtYW5hZ2VyLGJhY2hlbG9ycywxCmZhY2Vib29rLGJ1c2luZXNzIG1hbmFnZXIsbWFzdGVycywxCmZhY2Vib29rLGNvbXB1dGVyIHByb2dyYW1tZXIsYmFjaGVsb3JzLDEKZmFjZWJvb2ssY29tcHV0ZXIgcHJvZ3JhbW1lcixtYXN0ZXJzLDE="))
    getDataSets();
  }, []);

  useEffect(() => {
    setFile(stepOneData.file);
  }, [stepOneData]);

  const getBase64 = (file: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };
  const onUploadFileChange = (e: any) => {
    getBase64(e.target.files[0], (fileBase64: string) => {
      fileBase64 = fileBase64.split(",")[1];
      if(fileBase64) {
        setFile(fileBase64);
      }
    });
  };


  const onRadioChnage = async (e: any) => {
    setTargetVariable([]);
    setFeatures([]);
    console.log(e.target.value);
    setDatasetOption(e.target.value);
    setResetFields(true);
    try {
      if (e.target.value != "custom") {
        setLoading(true);
        const result = await post("datasets/get_dataset_data/", {
          dataset_id: e.target.value,
        });
        console.log(result.data.dataset);
        setFile(result.data.dataset);
        setLoading(false);
      }
      successNotification("Success", "Dataset changed!")
    } catch (error) {
      errorNotification("Error", error.message);
      console.log(error);
    }
    setResetFields(false);
    setSessionSavable(false);
    setGlobalSessionSave(false);
    sessionData.session_data = [];
    setSessionData(sessionData);
  };

  const getDataSets = async () => {
    try {
      const result = await get("modules/tree/getSamples");
      const data = result.data.data;
      setDatasets(data);
    } catch (error) {
      errorNotification("Error", error.message);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="component-box">
          <h2>Upload Files</h2>
          <Form>
            <CSVLink data={sampleFile} filename={"sampleTree.csv"}>
              Sample File
            </CSVLink>

            <Form.Item label="Select from sample dataset">
              <Radio.Group onChange={onRadioChnage}>
                {datasets.map((dataset: any) => (
                  <Radio value={dataset.dataset_id}>
                    {dataset.dataset_name}
                  </Radio>
                ))}
                <Radio value={"custom"}>Upload custom dataset</Radio>
              </Radio.Group>
            </Form.Item>
            {datasetOption === "custom" && (
              <Form.Item name="file">
                <h3>Data File</h3>
                <Input type="file" accept=".csv,text/plain" onChange={onUploadFileChange} />
              </Form.Item>
            )}
          </Form>
        </div>
      </Spin>
    </>
  );
};
