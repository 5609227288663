import React, { FC, useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Spin } from "antd";
import { Option } from "antd/es/mentions";
import { Scatter, ChartData } from "react-chartjs-2";

import ReactApexChart, { Props } from "react-apexcharts";
import { useRegression } from "../../Pages/Regression/RegressionContext";

export const Plot: React.FC<Props> = (props: Props) => {
  const [chartData, setChartData] = useState<ChartData<any>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const {
    dataPoints,
    fetchDataPoints,
    variableList,
    setDependentVariable,
    dependentVariable,
    setIndependentVariable,
    independentVariable,
    metaParams,
    dataFile,
    setMetaParams,
    selectedDataFile,
    setSessionSavable,
    stepOneData,
    setStepOneData,
    setData,
    setDataFile,
    resetFields
  } = useRegression();
  useEffect(() => {
    if (dataPoints && dataPoints.length > 0) {
      updateDataset();
    }
  }, [dataPoints, metaParams.twoPoints]);

  useEffect(() => {
    restoreStepOneSession();
  }, [stepOneData]);
  useEffect(() => {
    if(resetFields) {
      form.resetFields();
    }
  }, [resetFields]);

  const restoreStepOneSession = () => {
    if(stepOneData?.response_data?.data) {
      form.setFieldsValue({
        independentVariable: stepOneData.request_data.x,
        dependentVariable: stepOneData.request_data.y
      });
      const val = {
        independentVariable: stepOneData.request_data.x,
        dependentVariable: stepOneData.request_data.y
      }
      onSubmit(val);
    }
  }

  const renderVariableList = () => {
    return variableList.map((value, index) => (
      <Option key={value} value={value}>
        {value}{" "}
      </Option>
    ));
  };
  const updateDataset = () => {
    const structure = {
      labels: ["Scatter"],
      datasets: [
        {
          label: "Scatter plot",
          fill: false,
          showLine: false,
          backgroundColor: "rgba(75,192,192,1)",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "rgba(75,192,192,1)",
          pointBorderWidth: 1,
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "rgb(5,40,40)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 13,
          data: dataPoints ?? [],
        },
        {
          type: "line",
          label: "Line",
          data: metaParams.twoPoints ?? [],
          backgroundColor: "rgba(26,56,56,0.58)",
          fill: false,
          pointBorderWidth: 1,
          borderColor: "rgba(167,167,167,0.6)",
          pointBackgroundColor: "rgb(125,213,213)",
          showLine: true,
        },
      ],
    };
    setChartData(structure);
  };
  const onSubmit = (val: any) => {
    console.log(val)
    setLoading(true);
    setDependentVariable(val.dependentVariable);
    setIndependentVariable(val.independentVariable);
    setMetaParams({ ...metaParams, twoPoints: undefined });
    fetchDataPoints({ x: val.independentVariable, y: val.dependentVariable })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    form.resetFields();
    setMetaParams({ ...metaParams, twoPoints: undefined });
  }, [selectedDataFile]);
  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Step 1: Plot</h2>
        <Form onFinish={onSubmit} name={"basic"} form={form}>
          <Row gutter={16}>
            <Col className="gutter-row" span={10}>
              <h3>Dependent variable</h3>
              <Form.Item
                name={"dependentVariable"}
                rules={[
                  {
                    required: true,
                    message: "Please select the dependent variable!",
                  },
                ]}
              >
                <Select>
                  {variableList?.length > 0 && renderVariableList()}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={10}>
              <h3>Independent variable</h3>
              <Form.Item
                name={"independentVariable"}
                rules={[
                  {
                    required: true,
                    message: "Please select the independent variable!",
                  },
                ]}
              >
                <Select>
                  {variableList?.length > 0 && renderVariableList()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                variableList.length ==0
                }
              >
                Plot Dataset
              </Button>
            </Form.Item>
          </Row>
        </Form>

        <div className="chart-one">
          {dataPoints && dataPoints.length > 0 && chartData ? (
            <Scatter
              data={chartData}
              options={{
                scales: {
                  yAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: dependentVariable,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: independentVariable,
                      },
                    },
                  ],
                },
              }}
            />
          ) : null}
        </div>
      </div>
    </Spin>
  );
};
