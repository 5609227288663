import React, { FC, useEffect, useState } from "react";
import { Button, Col, Form, Radio, Row, Select, Spin, Table } from "antd";
import { Option } from "antd/es/mentions";
import { useRegression } from "../../Pages/Regression/RegressionContext";
import { useConnection } from "../../Services/ApplicationContext";
import {
  infoNotification,
  errorNotification, successNotification,
} from "../../Services/NotificationService";
import { CSVLink } from "react-csv";
import "../../Pages/styles.scss";

export type EstimatedMultiProps = {
  coefficients: {
    adj_r2: number;
    alpha: number;
    beta: number;
    dof: number;
    p_value: number;
    r2: number;
    t_value: number;
  };
  comparison: [
    {
      estimate: number;
      name: string;
      p_value: number;
      std_error: number;
      t_value: number;
    }
  ];
};
export const MultiVariateRegression: FC = () => {
  const { variableList, selectedDataFile, dataFile, setData, stepFourData, resetFields } = useRegression();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { post } = useConnection();
  const [data, setDatas] = useState<EstimatedMultiProps>();
  const [independentV, setIndependentV] = useState<string>();
  const renderVariableList = () => {
    return variableList.map((value, index) => (
      <Option key={value} value={value}>
        {value}{" "}
      </Option>
    ));
  };

  useEffect(() => {
    restoreStepFourSession();
  }, [stepFourData]);
  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      setDatas(undefined);
    }
  }, [resetFields]);

  const restoreStepFourSession = () => {
    if(stepFourData?.response_data?.data) {
      form.setFieldsValue({
        logistic: stepFourData.request_data.logistic,
        x_variables: stepFourData.request_data.x_variables,
        y: stepFourData.request_data.y
      });
      onSubmit(stepFourData.request_data);
    }
  }

  const onSubmit = async (val: any) => {
    setLoading(true);
    console.log("fuse ", {
      ...val,
      logistic: val.logistic === undefined ? false : val.logistic,
    });
    setIndependentV(val.y);
    try {
      const result = await post("modules/regression/multivariateRegression", {
        base64_text: selectedDataFile,
        ...val,
        logistic: val.logistic === undefined ? false : val.logistic,
      });
      const temp = result?.data?.data;
      console.log("multi ", temp);
      setDatas(temp);
      setLoading(false);
      // if (temp && temp.message) {
      //   infoNotification("Information", temp.message);
      // }

      const step_four_data = {
        step: 'step 4',
        request_data: {
          ...val
        },
        response_data: {
          data: result.data.data
        }
      }

      await setData(step_four_data);

      successNotification("Success", "Multivariate regression calculated successfully!")
    } catch (e) {
      console.log("Exception: multi: ", e);
      errorNotification("Error", e.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    form.resetFields();
  }, [selectedDataFile]);
  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Step 4 : Multivariate Regression</h2>
        <Form onFinish={onSubmit} form={form}>
          <Row gutter={16}>
            <Col className="gutter-row" span={10}>
              <h3>Dependent variable</h3>
              <Form.Item
                name={"y"}
                rules={[
                  {
                    required: true,
                    message: "Please select the dependent variable!",
                  },
                ]}
              >
                <Select>
                  {variableList?.length > 0 && renderVariableList()}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={10}>
              <h3>Independent variables</h3>
              <Form.Item
                name={"x_variables"}
                rules={[
                  {
                    required: true,
                    message: "Please select independent variable/s",
                  },
                ]}
              >
                <Select mode="multiple" allowClear>
                  {variableList?.length > 0 && renderVariableList()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="Or select from sample text" name={"logistic"}>
                <Radio.Group defaultValue={false}>
                  <Radio value={false}>Linear Regression</Radio>
                  <Radio value={true}>Logistic Regression</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row >
            <Button
              type="primary"
              htmlType={"submit"}
              disabled={variableList.length === 0 || selectedDataFile === undefined}
            >
              Estimate regression Equation
            </Button>
          </Row>
        </Form>
        {data &&
          <>
            {data.coefficients && <div className={"estimate-panel"}>
              <h3>Regression Coefficients:</h3>
              {data?.coefficients.alpha || data?.coefficients.alpha == 0 ? (
                  <span>Alpha (Intercept) = {data?.coefficients.alpha}</span>
              ): null}
              {data?.coefficients.beta || data?.coefficients.beta == 0 ? (
                  <span>Beta = {data?.coefficients.beta}</span>
              ): null}
              {data?.coefficients.t_value || data?.coefficients.t_value == 0 ? (
                  <span>t Value = {data?.coefficients.t_value}</span>
              ): null}
              {data?.coefficients.p_value || data?.coefficients.p_value == 0 ? (
                  <span>p Value = {data?.coefficients.p_value}</span>
              ): null}
              {data?.coefficients.r2 || data?.coefficients.r2 == 0 ? (
                  <span>R Squared = {data?.coefficients.r2}</span>
              ): null}
              {data?.coefficients.adj_r2 || data?.coefficients.adj_r2 == 0 ? (
                  <span>Adjusted R Squared = {data?.coefficients.adj_r2}</span>
              ): null}
              {data?.coefficients.dof || data?.coefficients.dof == 0 ? (
                  <span>Degrees of Freedom = {data?.coefficients.dof}</span>
              ): null}
            </div>}
            <div>
              {data?.comparison && (
                <>
                  <Table
                    dataSource={data.comparison.map((val) => ({
                      ...val,
                      name: val.name === "x" ? independentV : val.name,
                    }))}
                    bordered
                    columns={columns}
                  />
                  <Button type="primary">
                    <CSVLink
                      data={data.comparison.map((val) => ({
                        ...val,
                        name: val.name === "x" ? independentV : val.name,
                      }))}
                      filename={"descriptive_analysis.csv"}
                    >
                      Download
                    </CSVLink>
                  </Button>
                </>
              )}
            </div>
          </>
        }
      </div>
    </Spin>
  );
};
const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Estimate",
    dataIndex: "estimate",
    key: "estimate",
    sorter: (a: any, b: any) => a.estimate - b.estimate,
  },
  {
    title: "Std. Error",
    dataIndex: "std_error",
    key: "std_error",
    sorter: (a: any, b: any) => a.std_error - b.std_error,
  },
  {
    title: "t Value",
    dataIndex: "t_value",
    key: "t_value",
    sorter: (a: any, b: any) => a.t_value - b.t_value,
  },
  {
    title: "Pr(<|t|)",
    dataIndex: "p_value",
    key: "p_value",
    sorter: (a: any, b: any) => a.p_value - b.p_value,
  },
];
