import React, { FC, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  useHistory,
} from "react-router-dom";
import "./styles.scss";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import {Badge, Dropdown, Menu, Modal} from "antd";
import { useConnection, useUser } from "../../Services/ApplicationContext";
import { NavigationContext } from "../SideNavigation/SideNavigation";

export const Navbar: FC = () => {
  const {post} = useConnection()
  const {name,setName, defaultLoc} = useContext(NavigationContext)
  const history = useHistory();
  const { role } = useUser();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("decoded");
    localStorage.removeItem("email");

    window.location.reload();
  };

    const logoutWarn = () => {
        const modal = Modal.confirm({
            title: 'Warning',
            content: `Are you sure you want to logout?`,
            centered: true,
            onOk() {
                logout()
                modal.destroy();
            },
            onCancel() {
                modal.destroy();
            }
        });
    }

  const menu = (
    <Menu>
      <Menu.Item>
        <NavLink to="/profile"> Profile</NavLink>
      </Menu.Item>

      <Menu.Item>
          {name.default_pass ? (
              <>
              <Badge dot>
                  <NavLink to="/changePassword" style={{marginRight: 7}}> Change Password</NavLink>
              </Badge>
              </>
          ) : (
              <NavLink to="/changePassword"> Change Password</NavLink>
          )}
      </Menu.Item>
      {/* {role === "1" && <Menu.Item>Add Admin</Menu.Item>} */}
      <Menu.Item>
        <a onClick={() => logoutWarn()}>Logout</a>
      </Menu.Item>
    </Menu>
  );

    useEffect(() => {
      getUserDetails()
    },[]);

    const getUserDetails = async (): Promise<void> => {
      
      const email = localStorage.getItem("email");
      console.log(email);
      try {
        const result = await post("users/get_user/", {
          email,
        });
        console.log('here',result?.data?.data);
         setName({...name,
           firstName : result?.data?.data.first_name,
           lastName : result?.data?.data.last_name,
           default_pass: result?.data?.data.default_pass
         })
        // console.log('pass', name.default_pass);
         
      } catch (error) {
        console.log(error);
      }
    };
  


  return (
    <div className="nav-bar">
      <img
        src={require("../../Assets/images/Logo2.ico")}
        style={{
          width: "30px",
          height: "30px",
          marginBottom: "10px",
          marginLeft: "10px",
          backgroundImage: "cover",
        }}
      />

      <span style={{ cursor: "pointer" }} onClick={() => {
          const r: any = localStorage.getItem("path");
          history.push(r);
      }}>
        Analytics for Executives
      </span>

      <div className="nav-drop-down">
          <Dropdown overlay={menu} placement="bottomRight" arrow>
              <div>
                  <span className="nav-icons" style={{ fontSize: 15 }}>
                    {name.firstName} {" "}{name.lastName}
                  </span>
                  <span className="nav-icons" style={{ fontSize: 12 }}>
                    ( {localStorage.getItem("email")} )
                  </span>
                  <UserOutlined className="nav-icons" style={{ fontSize: "20px" }} />
                  <DownOutlined className="nav-icons" style={{ fontSize: "10px" }} />
                  {name.default_pass ? (
                      <Badge dot>
                          <></>
                      </Badge>
                  ) : null}
              </div>
          </Dropdown>
      </div>
    </div>
  );
};
