import { Button, Image } from "antd";
import React, {FC, useContext, useEffect, useState} from "react";
import { useConnection } from "../../Services/ApplicationContext";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import { ImageContext } from "./ImageContext/ImageContext";

export const EdgeDetectionComponent: FC = () => {
  const { image, setData, stepThreeData, resetFields } = useContext(ImageContext);
  const [edgeDetection, setEdgeDetection] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { post } = useConnection();

  useEffect(() => {
    restoreStepThreeSession();
  }, [stepThreeData]);

  useEffect(() => {
    if(resetFields) {
      setEdgeDetection('');
    }
  }, [resetFields]);

  const restoreStepThreeSession = () => {
    if(stepThreeData?.response_data?.data) {
      setEdgeDetection(stepThreeData?.response_data?.data.processed_image);
    }
  }

  const processImage = async () => {
    const base64 = image.split(",")[0];
    setLoading(true);
    try {
      const result = await post("modules/image/edgeDetection", {
        image: image.split(",")[1],
      });
      const dimage = base64 + "," + result?.data?.data?.processed_image;
      console.log(dimage);

      setEdgeDetection(dimage);
      setLoading(false);

      result.data.data.processed_image = dimage;
      const step_three_data = {
        step: 'step 3',
        request_data: {
          image: image
        },
        response_data: {
          data: result.data.data
        }
      }

      await setData(step_three_data);
      successNotification("Success", "Edge detection successful!");
      //console.log(imageURL)
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message)
    }
  };

  return (
    <div className="component-box">
      <div>
        <h2>Step 3: Edge Detection</h2>
        <Button type="primary" onClick={processImage} loading={loading} disabled = {image.localeCompare("") == 0 ? true:false}>
          Detect
        </Button>
      </div>
      <div className={"img-cont"}>
        {edgeDetection && (
          <Image src={edgeDetection} />
        )}
      </div>
    </div>
  );
};
