import React, { FC, useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Spin, Table } from "antd";
import {
  MetaParameterProps,
  useRegression,
} from "../../Pages/Regression/RegressionContext";
import { CSVLink } from "react-csv";
import "../../Pages/styles.scss";
import {successNotification} from "../../Services/NotificationService";

export const RegressionEquation: FC = () => {
  const {
    fetchTestRegressionPoints,
    metaParams,
    predictedData,
    independentVariable,
    dependentVariable,
    dataFile,
    variableList,
    setMetaParams,
    selectedDataFile,
    stepTwoData,
    resetFields
  } = useRegression();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const columns = [
    {
      title: "ID",
      dataIndex: "index",
      key: "index",
    },
    {
      title: independentVariable,
      dataIndex: "x",
      key: "x",
      sorter: (a: any, b: any) => a.x - b.x,
    },
    {
      title: dependentVariable,
      dataIndex: "y",
      key: "y",
      sorter: (a: any, b: any) => a.y - b.y,
    },
    {
      title: "Prediction",
      dataIndex: "prediction",
      key: "prediction",
      sorter: (a: any, b: any) => a.prediction - b.prediction,
    },
    {
      title: "Absolute Error",
      dataIndex: "error",
      key: "error",
      sorter: (a: any, b: any) => a.error - b.error,
    },
  ];

  const onKeyPress = (event: any) => {
    if(event.key.match(/[0-9]/) || event.key.match(/\./) || event.key.match(/-/)) {
      //check whether if there's no other '.' or the input is currently empty
      if(event.key.match(/\./)) {
        if(event.target.value.match(/\./) || !event.target.value.match(/[0-9]/)) {
          event.preventDefault()
        }
      } else if(event.key.match(/-/)) {
        if(event.target.value.match(/-/) || (event.target.value.match(/[0-9]/) && event.target.selectionStart != 0)) {
          if (window.getSelection()) {
            // @ts-ignore
            if (window.getSelection().toString() != event.target.value) {
              event.preventDefault()
            }
          } else {
            event.preventDefault()
          }
        }
      }

    } else {
      event.preventDefault();
    }
  };

  useEffect(() => {
    restoreStepTwoSession();
  }, [stepTwoData]);
  useEffect(() => {
    if(resetFields) {
      form.resetFields();
    }
  }, [resetFields]);

  const restoreStepTwoSession = () => {
    if(stepTwoData?.response_data?.data) {
      console.log(stepTwoData)
      const val = {
        alpha: stepTwoData.request_data.alpha,
        beta: stepTwoData.request_data.beta
      }
      onSubmit(val);
    }
  }

  const onSubmit = (val: any) => {
    setMetaParams({ alpha: Number(val.alpha), beta: Number(val.beta) });
    form.setFieldsValue({ alpha: Number(val.alpha), beta: Number(val.beta) });
    setLoading(true);
    fetchTestRegressionPoints({
      alpha: Number(val.alpha),
      beta: Number(val.beta),
    })
      .then(() => {
        successNotification("Success", "Successfully test regression equation!")
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    form.setFieldsValue(metaParams);
  }, [form, metaParams]);
  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Step 2: Test regression equations</h2>
        <Form
          onFinish={onSubmit}
          initialValues={{ alpha: metaParams.alpha, beta: metaParams.beta }}
          form={form}
        >
          <Row gutter={16}>
            <Col className="gutter-row" span={10}>
              <h3>Alpha :</h3>
              <Form.Item name={"alpha"}>
                <Input onKeyPress={onKeyPress}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={10}>
              <h3>Beta :</h3>
              <Form.Item name={"beta"}>
                <Input onKeyPress={onKeyPress}/>
              </Form.Item>
            </Col>
          </Row>
          <Row  >
            <Form.Item>
              <Button
                // onClick={plotGraph}
                type="primary"
                htmlType={"submit"}
                disabled={
                  variableList.length === 0 ||
                  selectedDataFile === undefined ||
                  independentVariable === undefined ||
                  dependentVariable === undefined
                }
                style={{
                  width: "107px",
                }}
              >
                Plot{" "}
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <div>
          {predictedData && (
            <div className="">
              <h1>Actual vs Prediction</h1>
              <Table
                dataSource={predictedData.map((val, index) => ({
                  ...val,
                  index,
                  error: Math.abs(val.error)
                }))}
                bordered
                rowClassName={"row-x"}
                columns={columns}
              />
              <Button type="primary">
                <CSVLink
                  data={predictedData.map((val, index) => ({
                    ...val,
                    index,
                    error: Math.abs(val.error)
                  }))}
                  filename={"TestRegression.csv"}
                >
                  Download
                </CSVLink>
              </Button>
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};
