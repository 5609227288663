import React, {FC, useContext, useEffect, useState} from "react";
import {useConfig, useConnection} from "../../../Services/ApplicationContext";
import {errorNotification, successNotification} from "../../../Services/NotificationService";
import {NavigationContext} from "../../SideNavigation/SideNavigation";

export type TextContextProps = {
  text: string;
  setText: (val: string) => void;
  token: any;
  setToken: (val: any) => void;
  tempToken: any;
  setTempToken: (val: any) => void;
  stopWords: any;
  setStopWords: (val: any) => void;
  filtered: boolean;
  setFilter: (val: boolean) => void;
  sessionData: any;
  setSessionData: (val: any) => void;
  stepOneData: any;
  setStepOneData: (val: any) => void;
  stepTwoData: any;
  setStepTwoData: (val: any) => void;
  stepThreeData: any;
  setStepThreeData: (val: any) => void;
  stepFourData: any;
  setStepFourData: (val: any) => void;
  stepFiveData: any;
  setStepFiveData: (val: any) => void;
  sessionSavable: boolean;
  setSessionSavable: (val: boolean) => void;
  saveSession: () => Promise<void>;
  restoreSession: () => Promise<void>;
  resetFields: boolean;
  setResetFields: (val: boolean) => void;
  visualize: boolean;
  setVisualize: (val: boolean) => void;
};

export const TextContext = React.createContext<TextContextProps>({
  text: "",
  setText: () => {},
  token: "",
  setToken: () => {},
  tempToken: "",
  setTempToken: () => {},
  stopWords: {},
  setStopWords: () => {},
  filtered: true,
  setFilter: () => {},
  sessionData: {},
  setSessionData: () => {},
  stepOneData: {},
  setStepOneData: () => {},
  stepTwoData: {},
  setStepTwoData: () => {},
  stepThreeData: {},
  setStepThreeData: () => {},
  stepFourData: {},
  setStepFourData: () => {},
  stepFiveData: {},
  setStepFiveData: () => {},
  sessionSavable: false,
  setSessionSavable: () =>{},
  saveSession: async () => {},
  restoreSession: async () => {},
  resetFields: false,
  setResetFields: () =>{},
  visualize: false,
  setVisualize: () => {},
});

export const TextContextProvider: FC = ({ children}) => {
  const { post } = useConnection();
  const {setGlobalSessionSave} = useConfig();
  const [text, setText] = useState<string>("");
  const [token, setToken] = useState<any>();
  const [tempToken, setTempToken] = useState<any>();
  const [filtered, setFilter] = useState<boolean>(true);
  const [visualize, setVisualize] = useState<boolean>(false);
  const [stopWords, setStopWords] = useState<any>([]);
  const [sessionData, setSessionData] = useState<any>({
      module: "text",
      session_data: []
  });
  const [stepOneData, setStepOneData] = useState<any>({
      step: "step 1",
      request_data: {
          custom_token: '',
          text: '',
          tokens_selected: {
              custom: false,
              punctuations: false,
              white_space: true,
          }
      },
      response_data: {
          data:null
      }
  });
  const [stepTwoData, setStepTwoData] = useState<any>({
      step: "step 2",
      request_data: {
          custom_word_list: [],
          tokens: [],
          stop_word_list: []
      },
      response_data: {
          data:null
      }
  });
  const [stepThreeData, setStepThreeData] = useState<any>({
      step: "step 3",
      request_data: {
          remove_stop_words: false,
          tokens: [],
          stop_word_list: [],
          custom_word_list: []
      },
      response_data: {
          data:null
      }
  });
  const [stepFourData, setStepFourData] = useState<any>({
      step: "step 4",
      request_data: {
          tokens: []
      },
      response_data: {
          data:null
      }
  });
  const [stepFiveData, setStepFiveData] = useState<any>({
      step: "step 1",
      request_data: {
          tokens: []
      },
      response_data: {
          data:null
      }
  });
  const [sessionSavable, setSessionSavable] = useState<boolean>(false);
  const [resetFields, setResetFields] = useState<boolean>(false);

  const saveSession = async () => {
      console.log("save session")
      if(sessionSavable) {
          console.log("saving")
          try{
              const result = await post("session/save", sessionData);
              setGlobalSessionSave(false);
              successNotification("Success", "Session Saved!")
          } catch (error) {
              console.log("Exception: save session: text ", error);
              errorNotification("Error", error.message);
          }
      }
  }
  const restoreSession = async () => {
      try{
          setResetFields(true);
          const result = await post("session/restore", { module: "text" });
          console.log(result)
          const s_data = result?.data?.data;
          for(let i=0; i<s_data.session_data?.length; i++) {
              const step = s_data.session_data[i];
              switch (step.step) {
                  case "step 1": {
                      console.log("restoring step 1");
                      setStepOneData(step);
                  } break;
                  case "step 2": {
                      console.log("restoring step 2");
                      setStepTwoData(step);
                  } break;
                  case "step 3": {
                      console.log("restoring step 3");
                      setStepThreeData(step);
                  } break;
                  case "step 4": {
                      console.log("restoring step 4");
                      setStepFourData(step);
                  } break;
                  case "step 5": {
                      console.log("restoring step 5");
                      setStepFiveData(step);
                  } break;
              }
          }
          setSessionData(s_data);
          setSessionSavable(true);
          setGlobalSessionSave(true);
          successNotification("Success", "Session restored!")
      } catch (error) {
          console.log("Exception: restore session: text ", error);
          errorNotification("Error", error.message);
      }
      setResetFields(false);
  }

  useEffect(() => {
        setGlobalSessionSave(sessionSavable);
    }, []);

  return (
    <TextContext.Provider
      value={{
        text,
        setText,
        token,
        setToken,
        stopWords,
        setStopWords,
        tempToken,
        setTempToken,
        filtered,
        setFilter,
        sessionData,
        setSessionData,
        stepOneData,
        setStepOneData,
        stepTwoData,
        setStepTwoData,
        stepThreeData,
        setStepThreeData,
        stepFourData,
        setStepFourData,
        stepFiveData,
        setStepFiveData,
        sessionSavable,
        setSessionSavable,
        saveSession,
        restoreSession,
        resetFields,
        setResetFields,
        visualize,
        setVisualize,
      }}
    >
      {children}
    </TextContext.Provider>
  );
};

export default TextContext;