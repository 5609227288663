import React, { FC, useEffect, useState } from "react";
import {Table, Spin, Button, Modal} from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import { useHistory } from "react-router-dom";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import "../styles.scss";

export const InstructorTable: FC = () => {
  const [instructors, setInstructors] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  const { get, post } = useConnection();

  useEffect(() => {
    try {
      getInstructors();
    } catch (error) {}
  }, []);

  const getInstructors = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await get("instructors/view_instructors/");
     
      const instructors = result?.data.users.filter((user: any) => user.role == 2);
      console.log(result);

      setInstructors(instructors);
      console.log(typeof result.data);
      console.log(result?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = async (email: string) => {
    const modal = Modal.confirm({
      title: 'Warning',
      content: `Confirm delete?`,
      centered: true,
      onOk() {
        deleteInstructor(email);
        modal.destroy();
      },
      onCancel() {
        modal.destroy();
      }
    });
  }

  const deleteInstructor = async (email: string) => {
    console.log(email);
    setLoading(true);
    try {
      const result = await post("instructors/delete_instructor/", { email });
      setInstructors(
        instructors.filter((instructor: any) => instructor.email != email)
      );
      successNotification("Successful", "Instructor deleted successfully!");
      console.log(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorNotification("Error", "Could not delete the Instructor!");
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      sorter: (a: any, b: any) => a.user_id - b.user_id,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a: any, b: any) => a.first_name.localeCompare(b.first_name),
      
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a: any, b: any) => a.last_name.localeCompare(b.last_name),

    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),

    },
    {
      title: "Delete",
      dataIndex: "view",
      key: "view",
      render: (text: any, record: any) => (
        <>
        {/* <Button type ='primary'>Edit</Button>{" "} */}
        <Button type="primary" onClick={() => confirmDelete(record.email)}>
          Delete
        </Button>
        </>
      ),
    },
  ];
  return (
  
      
        <Table columns={columns} dataSource={instructors} bordered pagination={{
          pageSizeOptions: ["10", "15", "25"],
          showSizeChanger: true,
        }}
        loading={loading}
        />
    
  );
};

// dataSource={instructors}
