import React, {FC, useContext} from "react";
import { Button, Form, Input } from "antd";
import sha256 from "sha256";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import { useConnection } from "../../Services/ApplicationContext";
import { NavigationContext } from "../../Components/SideNavigation/SideNavigation";
export const ChangePassword: FC = () => {
  const { post } = useConnection();
  const { name, setName } = useContext(NavigationContext);
  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    try {
      console.log(values);
      if(!values.new_password || !values.old_password || !values.retype_password) {
        errorNotification(
            "Error",
            "Password fields cannot be empty!"
        );
        return;
      }
      if (values.new_password.localeCompare(values.retype_password) != 0) {
        errorNotification(
          "Error",
          "New password and retype password does not match!"
        );
        return;
      }
      values.new_password = sha256(values.new_password);
      values.old_password = sha256(values.old_password);

      const result = await post("users/update_user_password/", {
        email: localStorage.getItem("email"),
        new_password: values.new_password,
        old_password: values.old_password,
      });

      setName({...name, default_pass: false});

      form.setFieldsValue({
        new_password: "",
        old_password: "",
        retype_password: "",
      })

      successNotification("Success", "Password Change Successfully!");
    } catch (error) {
      errorNotification("Error", error.message)
    }
  };

  return (
    <div className="component-box">
      <div
        style={{
          marginBottom: 20,
          justifyContent: "center",
        }}
      >
        <span
          style={{
            fontSize: 30,
          }}
        >
          Change Password
        </span>
      </div>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item label="Current Password" name="old_password">
          <Input.Password />
        </Form.Item>
        <Form.Item label="New Password" name="new_password">
          <Input.Password />
        </Form.Item>
        <Form.Item label="Re-type New Password" name="retype_password">
          <Input.Password />
        </Form.Item>
        <Button type="primary" block htmlType="submit">
          Update Password
        </Button>
      </Form>
    </div>
  );
};
