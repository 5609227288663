import React, {FC, useContext, useEffect, useState} from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Spin,
  Table,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {useConfig, useConnection} from "../../Services/ApplicationContext";
import { TextContext } from "./TextContext/TextContext";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import { CSVLink, CSVDownload } from "react-csv";
import "../../Pages/styles.scss";
import {NavigationContext} from "../SideNavigation/SideNavigation";

export const StepOneComponent: FC = () => {
  const { post } = useConnection();
  const [tokenize, setTokenize] = useState<any>({
    custom: false,
    white_space: true,
    punctuations: false,
  });
  const [tableData, setTableData] = useState<any>([{}]);
  const [customToken, setCustomToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [noOfTokens, setNoOfTokens] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const { text, setToken, setTempToken, sessionData, setSessionData, stepOneData, setSessionSavable, resetFields, setVisualize } = useContext(
    TextContext
  );
  const {setGlobalSessionSave} = useConfig();
  const [form] = Form.useForm();

  useEffect(() => {
    // set the data from stepOneData
    restoreStepOneSession();
  }, [stepOneData]);

  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      setVisible(false);
    }
  }, [resetFields]);

  //method to select a token
  const onTokenSelect = (value: any) => {
    console.log(value);
    setTokenize({
      ...tokenize,
      ...{
        custom: value.includes("custom"),
        punctuations: value.includes("punctuation"),
        white_space: value.includes("whitepace"),
      },
    });
  };

  const setData = (step_one_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_one_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == 'step 1') {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_one_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const setSelectedFields = (selectedTokens: any, customToken: string) => {
    let ticks = [];
    let c_token = '';
    setTokenize(selectedTokens);
    if(selectedTokens.white_space) {
      ticks.push("whitepace");
    }
    if(selectedTokens.punctuations) {
      ticks.push("punctuation");
    }
    if(selectedTokens.custom) {
      ticks.push("custom");
      c_token = customToken;
      setCustomToken(customToken);
    }
    console.log(ticks)
    form.setFieldsValue({
      checkboxes:ticks,
      customtoken: c_token
    })
  }

  const restoreStepOneSession = () => {
    if(stepOneData?.response_data?.data) {
      setNoOfTokens(stepOneData?.response_data?.data.length);
      setToken(stepOneData?.response_data?.data);
      setTempToken(stepOneData?.response_data?.data);

      setSelectedFields(stepOneData.request_data.tokens_selected, stepOneData.request_data.custom_token);

      setTableData(
          stepOneData?.response_data?.data.map((data: any, index: number) => {
            const newdata = {
              index: index + 1,
              token: data,
            };
            return newdata;
          })
      );
      setVisible(true);
      setVisualize(true);
    }
  }

  //on custom text change
  const onCustomTextChange = (e: any) => {
    // console.log(e.target.value)
    setCustomToken(e.target.value);
  };

  //form to  submit the token and to call the api
  const submitTokenize = async (e: any) => {
    console.log("fired");
    console.log(tokenize);
    console.log(text);
    console.log("-----------", customToken);

    if (customToken.trim() === "" && tokenize.custom == true) {
      errorNotification("Error", "Please add custom token!");
      return;
    }
    setLoading(true);
    try {
      console.log(e?.target?.value);
      const result = await post("modules/text/tokenizing", {
        custom_token: customToken.trim(),
        text: text,
        tokens_selected: tokenize,
      });
      console.log(result?.data?.data.length);
      setNoOfTokens(result?.data?.data.length);
      setToken(result?.data?.data);
      setTempToken(result?.data?.data);
      setTableData(
        result?.data?.data.map((data: any, index: number) => {
          const newdata = {
            index: index + 1,
            token: data,
          };
          return newdata;
        })
      );

      // stepOne session data
      const step_one_data = {
        step: "step 1",
        request_data:{
          custom_token: customToken,
          text: text,
          tokens_selected: tokenize
        },
        response_data: {
          data: result?.data?.data
        },
      }
      //set session data
      setData(step_one_data);
      // session can be saved
      setSessionSavable(true);
      setGlobalSessionSave(true);
      successNotification("Successful", "Tokenize Successful!");
      setLoading(false);
      setVisible(true);
      setVisualize(true);
    } catch (error) {
      console.log(error);
      errorNotification("Error", error.message)

      setLoading(false);
    }
  };

  //table to display information
  const columns = [
    {
      title: "ID",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      sorter: (a: any, b: any) => a.token.localeCompare(b.token),
    },
  ];

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Step 1: Tokenize</h2>
        <Form form={form} onFinish={submitTokenize} initialValues={{checkboxes: ["whitepace"]}}>
          <h4>Select tokenization method</h4>
          <Form.Item name="checkboxes">
            <Checkbox.Group
              style={{ width: "200px" }}
              onChange={onTokenSelect}
              defaultValue={["whitepace"]}
            >
              <Col>
                <Checkbox value="whitepace">White space</Checkbox>
              </Col>
              <Col>
                <Checkbox value="punctuation">Punctuations (,.!?\-) </Checkbox>
              </Col>
              <Col>
                <Checkbox value="custom">Custom (Enter Below)</Checkbox>
              </Col>
            </Checkbox.Group>
          </Form.Item>
          <h4>Custom token (Maximum 5 characters)</h4>
          <Form.Item name="customtoken">
            <Input
              onChange={(e) => onCustomTextChange(e)}
              maxLength={5}
              disabled={!tokenize.custom}
            />
          </Form.Item>
          <Row justify="start">
            <Button type="primary" htmlType="submit">
              Tokenize
            </Button>
          </Row>
        </Form>
        {visible ? (
          <>
            <Table
              columns={columns}
              bordered
              pagination={{
                pageSizeOptions: ["10", "15", "25"],
                showSizeChanger: true,
              }}
              dataSource={tableData}
            />
            <div className="">
              <div className="" style={{ marginBottom: 10, marginTop:5 }}>
                <span style = {{fontWeight:"bold"}}>Total Number of Tokens = {noOfTokens}</span>
              </div>

              <Button type="primary">
                <CSVLink data={tableData} filename={"tokenize.csv"}>
                  Download
                </CSVLink>
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </Spin>
  );
};
