import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, Radio, Select, Spin } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import {useParams, useLocation, useHistory} from "react-router-dom";
interface DatasetProps {
  title: string;
}
interface LocationState {
  pathname : string,
  id : any
  module_id: number,
  dataset_name: string,
  download_permission: number
}

export const Datasets: FC<DatasetProps> = ({ title }) => {
  const { Option } = Select;
  const [file, setFile] = useState<any>();
  const [edgesFile, setEdgesFile] = useState<any>();
  const [modules, setModules] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { get, post } = useConnection();
  // const { id } = useParams();
  const location = useLocation<LocationState>();
  const [moduleId, setModuleId] = useState<number>();
  const history = useHistory()

  useEffect(() => {
    getModules();
    // const datasetId = id
    console.log(location?.state?.id)
   if (location?.state?.id != undefined) {
      if(location.state.module_id != 4) {
        getDataset(parseInt(location.state.id));
      } else {
        setNetworkDataFields(location.state.dataset_name, location.state.download_permission);
      }
   }
  }, []);

  const setNetworkDataFields = (dataset_name:string, download_permission: number) => {
    setModuleId(4);
    form.setFieldsValue({
      dataset_name: dataset_name,
      module_id: 4,
      download_permission: download_permission === 1,
    })
  }

  const getDataset = async (datasetId: number) => {
    setLoading(true);
    try {
      const result = await get("datasets/view_datasets/");
      // const result_2 = await post("datasets/get_dataset/", {
      //   dataset_id: datasetId,
      // });
      // console.log(result_2.data);
      console.log(result.data.datasets);
      const data = result.data.datasets.filter(
        (dataset: any) => dataset.dataset_id == datasetId
      );
      form.setFieldsValue({
        dataset_name: data[0].dataset_name,
        module_id: data[0].module_id,
        download_permission: data[0].download_permission === 1 ? true : false,
      });
      setLoading(false);
      console.log(data);
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message);
    }
  };

  const getModules = async () => {
    setLoading(true);
    try {
      const result = await get("classes/view_modules/");
      console.log(result?.data?.modules);
      setModules(
        result?.data?.modules.filter((module: any) => module.module_id !== 5)
      );
      setLoading(false);
    } catch (error) {
      errorNotification("Error", error.message);
      setLoading(false);
    }
  };

  const onChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const onEdgeFileChange = (e: any) => {
    setEdgesFile(e.target.files[0]);
  };

  const moduleSelect = (val: any) => {
    setModuleId(val);
  }

  const onSubmitDataSet = async (value: any) => {
    setLoading(true);
    const data = new FormData();
    data.append("dataset_id", location?.state?.id);
    data.append("dataset_name", value.dataset_name);
    data.append("download_permission", value.download_permission);
    data.append("fileName", file);
    data.append("module_id", value.module_id);
    if(moduleId == 4) {
      data.append("edgesFileName", edgesFile);
    }
    try {
      if (title === "Update Dataset") {
        if(moduleId != 4) {
          const result = await post("datasets/update_dataset/", data);
          console.log(result);
        } else {
          // update nodes dataset
          data.set("dataset_id", location?.state?.id.nodes_id);
          data.set("dataset_name", value.dataset_name + "_nodes");
          await post("datasets/update_dataset/", data);

          // update edges dataset
          data.set("dataset_id", location?.state?.id.edges_id);
          data.set("fileName", edgesFile);
          data.set("dataset_name", value.dataset_name + "_edges");
          await post("datasets/update_dataset/", data);
        }
        successNotification("Success", "Dataset updated Successfully!");
        // form.resetFields();
        setLoading(false);
        history.push("/viewDatasets")
      } else {
        const result = await post("datasets/add_dataset/", data);
        successNotification("Success", "Dataset Uploaded Successfully!");
        console.log(result);
        form.resetFields();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      errorNotification("Error", error?.message);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <div
          style={{
            marginBottom: 20,
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontSize: 30,
            }}
          >
            {title}
          </span>
        </div>

        <Form layout="vertical" onFinish={onSubmitDataSet} form={form}>
          <Form.Item
            label="Dataset name"
            name="dataset_name"
            rules={[{ transform: (value) => value.trim(),required: true, message: "Please input Dataset Name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Select module{" "}
                <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                  (Add datasets of correct format to the module (eg: Upload only images files for Image module)
                </span>
              </span>
            }
            name="module_id"
            rules={[{ required: true, message: "Please Select a Module" }]}
          >
            <Select onChange={moduleSelect}>
              {modules.map((module: any) => (
                <Option value={module.module_id}>{module.module_name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={moduleId==4 ?
                <span>
                  Upload nodes file{" "}
                  <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                    (Make sure nodes file represent the correct edges file)
                  </span>
                </span>
                :
                <span>
                  Upload dataset{" "}
                  <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                  (Text-base datasets must be UTF-8 encoded)
                </span>
              </span>
            }
            name="fileName"
            rules={[{ required: true, message: "Please add files" }]}
          >
            <Input type="file" onChange={(e: any) => onChange(e)} />
          </Form.Item>
          { moduleId == 4 ? (
              <Form.Item
                  label={
                    <span>
                      Upload edges file{" "}
                      <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                        (Make sure edges file represent the correct nodes file)
                      </span>
                    </span>
                  }
                  name="edgesFileName"
                  rules={[{ required: true, message: "Please add files" }]}
              >
                <Input type="file" onChange={(e: any) => onEdgeFileChange(e)} />
              </Form.Item>
          ): null}

          <Form.Item
            label="Downloadable dataset"
            name="download_permission"
            rules={[
              { required: true, message: "Please Select a Download option" },
            ]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {title === "Add Dataset" ? "Submit" : "Edit"}
          </Button>
        </Form>
      </div>
    </Spin>
  );
};

Datasets.defaultProps = {
  title: "Add Dataset",
};
