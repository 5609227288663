import React, {FC, useContext} from "react";
import DecisionTreeContextProvider, {DecisionTreeContext} from "../../Components/DecisionTreeComponents/DecisionTreeContext";
import { GenerateDecisionTree } from "../../Components/DecisionTreeComponents/GenerateDecisionTree";
import { StepOneExplore } from "../../Components/DecisionTreeComponents/StepOneExplore";
import { UploadFile } from "../../Components/DecisionTreeComponents/UploadFile";
import "../styles.scss";
import {Modal} from "antd";

export const DecisionTreeContainer: FC = () => {
  return (
    <DecisionTreeContextProvider>
        <DecisionTreeContainerWrapper />
    </DecisionTreeContextProvider>
  );
};

export const DecisionTreeContainerWrapper: FC = () => {
    const { saveSession, restoreSession } = useContext(DecisionTreeContext);
    const restoreWarn = () => {
        const modal = Modal.confirm({
            title: 'Warning',
            content: `Restore will clear all your unsaved data`,
            onOk() {
                restoreSession();
                modal.destroy();
            },
            onCancel() {
                modal.destroy();
            }
        });
    }
    return (
      <>
          <div className="">
              <div
                  style={{
                      marginBottom: 20,
                      justifyContent: "center",
                  }}
              >
                <span
                    style={{
                        fontSize: 30,
                    }}
                >
                 Decision Tree
                </span>
                  <div
                      style={{
                          float: "right"
                      }}
                  >
                      <button
                          onClick={saveSession}
                          className={"btn-save"}
                      > Save </button>
                      <button
                          onClick={restoreWarn}
                          className={"btn-restore"}
                      > Restore </button>
                  </div>
                  <div style={{clear: 'both'}}></div>
              </div>
              <UploadFile />
              <StepOneExplore/>
              <GenerateDecisionTree/>
          </div>
      </>
    );
}
