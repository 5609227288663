import React, {FC, useContext, useEffect, useState} from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Spin, Table } from "antd";
import TextContext from "./TextContext/TextContext";
import { useConnection } from "../../Services/ApplicationContext";
import ReactApexChart, { Props } from "react-apexcharts";
import { Bar } from "react-chartjs-2";

import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import { CSVLink } from "react-csv";
import "../../Pages/styles.scss";

export const StepFourComponent: FC = () => {
  const { token, filtered, tempToken, sessionData, setSessionData, stepFourData, resetFields, visualize } = useContext(TextContext);
  const { post } = useConnection();
  const [tableData, setTableData] = useState<any>([{}]);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [uniqueTokens, setNoOfUniqueTokens] = useState<number>(0);
  const [chart, setChart] = useState<any>({
    
  });
  const [form] = Form.useForm();

  useEffect(() => {
    // set the data from stepOneData
    restoreStepFourSession();
  }, [stepFourData]);

  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      setVisible(false);
    }
  }, [resetFields]);

  const setData = (step_four_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_four_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == 'step 4') {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_four_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const restoreStepFourSession = async () => {
    try {
      const result = await post("modules/text/descriptiveAnalysis", {
        tokens: stepFourData.request_data.tokens,
      });
      if (result?.data?.data) {
        setTableData(
            result?.data?.data.map((data: any, index: number) => {
              const newdata = {
                index: index + 1,
                token: data.token,
                word_count: data.word_count,
                tf: data.tf,
              };
              return newdata;
            })
        );

        setNoOfUniqueTokens(
            result?.data?.data.filter(
                (item: any, i: any, ar: any) => ar.indexOf(item) === i
            ).length
        );
        // data.token
        setChart({
          options: {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Count'
                  }
                },
              ],
              xAxes: [
                {

                  scaleLabel: {
                    display: true,
                    labelString: 'Token'
                  }
                },
              ],
            },
          },
          data: {
            datasets: [

              {
                label: 'Descriptive Analysis',
                backgroundColor: 'rgba(0,0,255,0.2)',
                borderColor: 'rgba(0,0,255,1)',
                borderWidth: 1,
                xAxis: "Token",
                yAxis: "Count",
                data: result?.data?.data

                    .map((data: any) => data.word_count)
                    .sort((a: any, b: any) => {
                      return b - a;
                    })
                    .slice(0, 40),
              },
            ],

            labels: result?.data?.data
                .sort((a: any, b: any) => {
                  if (b.word_count > a.word_count) return 1;
                  if (b.word_count < a.word_count) return -1;
                  return 0;
                })
                .splice(0, 40)
                .map((data: any) => data.token),
          },
        });
        setVisible(true);
      }
    }catch {

    }
  }

  //method to caluclate the descriptive analysis
  const calculate = async () => {
    setLoading(true);
    try {
      const result = await post("modules/text/descriptiveAnalysis", {
        tokens: filtered ? token : tempToken,
      });
      console.log(result?.data?.data);
      setTableData(
        result?.data?.data.map((data: any, index: number) => {
          const newdata = {
            index: index + 1,
            token: data.token,
            word_count: data.word_count,
            tf: data.tf,
          };
          return newdata;
        })
      );

      setNoOfUniqueTokens(
        result?.data?.data.filter(
          (item: any, i: any, ar: any) => ar.indexOf(item) === i
        ).length
      );
      // data.token
      setChart({
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Count'
                }
              },
            ],
            xAxes:[
              {
                 
                scaleLabel: {
                  display: true,
                  labelString: 'Token'
                }
              },
            ],
          },
        },
        data: {
          datasets: [
            
            {
              label: 'Descriptive Analysis',
              backgroundColor: 'rgba(0,0,255,0.2)',
              borderColor: 'rgba(0,0,255,1)',
              borderWidth: 1,
              xAxis:"Token",
              yAxis:"Count",
              data: result?.data?.data

                .map((data: any) => data.word_count)
                .sort((a: any, b: any) => {
                  return b - a;
                })
                .slice(0, 40),
            },
          ],

          labels: result?.data?.data
            .sort((a: any, b: any) => {
              if (b.word_count > a.word_count) return 1;
              if (b.word_count < a.word_count) return -1;
              return 0;
            })
            .splice(0, 40)
            .map((data: any) => data.token),
        },
      });

      console.log(result?.data?.data)

      //stepFour session data
      const step_four_data = {
        step: 'step 4',
        request_data: {tokens: filtered ? token : tempToken},
        response_data: {
          data: result?.data?.data
        }
      }
      //set session data
      setData(step_four_data)

      setLoading(false);
      setVisible(true);
      successNotification("Successful", "Descriptive analysis Successful!");
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message)
    }
  };

  //defining column
  const columns = [
    {
      title: "ID",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      sorter: (a: any, b: any) => a.token.localeCompare(b.token),
    },
    {
      title: "Word Count",
      dataIndex: "word_count",
      key: "word_count",
      sorter: (a: any, b: any) => a.word_count - b.word_count,
    },
    {
      title: "TF",
      dataIndex: "tf",
      key: "tf",
      sorter: (a: any, b: any) => a.tf - b.tf,
    },
  ];

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Step 4: Descriptive Analysis</h2>

        <Button type="primary" onClick={calculate} disabled={!visualize}>
          Calculate
        </Button>

        {visible ? (
          <>
            <div className="">
              <Table
                columns={columns}
                bordered
                pagination={{
                  pageSizeOptions: ["10", "15", "25"],
                  showSizeChanger: true,
                }}
                dataSource={tableData}
              />
            </div>

            <div className="" style={{ marginBottom: 10, marginTop: 5 }}>
              <span style={{ fontWeight: "bold" }}>
                Total Number of Unique Tokens = {uniqueTokens}
              </span>
            </div>

            <div className="">
              <Button type="primary">
                <CSVLink data={tableData} filename={"descriptive_analysis.csv"}>
                  Download
                </CSVLink>
              </Button>
            </div>
            <div className="chart-container">
              <Bar data={chart.data} options={chart.options} />
              {/* <ReactApexChart
                series={chart?.series}
                options={chart?.options}
                type="bar"
                height={350}
              /> */}
            </div>
          </>
        ) : null}
      </div>
    </Spin>
  );
};
