import * as React from 'react';
import {
    Route,
    Redirect,
    RouteProps,
    RouteComponentProps,
} from 'react-router-dom';

type PrivateRouteProps = RouteProps;
export class ProtectedRoute extends Route<PrivateRouteProps> {
    render() {
        const token = localStorage.getItem('token');
        return (
            <>
                <Route
                    exact
                    render={(props: RouteComponentProps) => {
                        if (!token) {
                            return <Redirect to="/login" />;
                        }

                        if (this.props.component) {
                            return React.createElement(this.props.component);
                        }

                        if (this.props.render) {
                            return this.props.render(props);
                        }
                    }}
                />
            </>
        );
    }
}
