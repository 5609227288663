import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, Select, Spin, Collapse } from "antd";
import { useConnection, useUser } from "../../Services/ApplicationContext";
import {
  errorNotification,
  successNotification,
} from "../../Services/NotificationService";
import {CSVLink} from "react-csv";

export const UploadStudent: FC = () => {
  // const [selectedFile, setSelectedFile] = useState<any>();
  const { post, get } = useConnection();
  const { role } = useUser();
  const [form] = Form.useForm();
  const [allClass, setAllClass] = useState<any>([]);
  const [selectedClass, setSelectedClass] = useState<any>(0);
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [duplicateRecords, setDuplicateRecords] = useState<string[]>([]);
  const [errorRecords, setErrorRecords] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [successCount, setSuccessCount] = useState<number>();
  const [duplicateCount, setDuplicateCount] = useState<number>();
  const [errorCount, setErrorCount] = useState<number>();
  const [visible, setVisible] = useState<boolean>(false);

  const { Option } = Select;
  const { Panel } = Collapse;
  //get base64
  const getBase64 = (file: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const onChange = (e: any) => {
    if (e.target.files[0]) {
      const fileUrl = URL.createObjectURL(e.target.files[0]);
      getBase64(e.target.files[0], (fileBase64: string) => {
        //  console.log("----   ", fileBase64);
        setFile(fileBase64);
      });
    }
  };

  useEffect(() => {
    const user_role = localStorage.getItem("role")
    if (typeof user_role === "string") {
      getStudnets(parseInt(user_role));
    } else {
      getStudnets(parseInt(role))
    }
  }, []);

  const getStudnets = async (role: number) => {
    let result = null;
    //   console.log(role);
    setLoading(true);
    try {
      if (role === 1) {
        result = await get("classes/view_classes/");
        console.log(result?.data?.classes);
        setAllClass(result?.data?.classes);
      } else {
        const id = parseInt(localStorage.getItem("id")!);
        //      console.log("+++++", typeof id);
        result = await post("instructors/view_instructor_classes/", {
          user_id: id,
        });
        console.log(result?.data);
        setAllClass(result?.data?.classes);
      }
      setLoading(false);
    } catch (error) {
      errorNotification("Error", error.message)
      //  setLoading(false);
      //     console.log(error);
    }
  };

  const onUpload = async () => {
    try {
      //   console.log(fileBase64);
      const fileBase64 = file.split(",")[1];
      //   console.log("----filebase64---  ", fileBase64);
      //   console.log(typeof fileBase64[1]);
      console.log(fileBase64);
      setLoading(true);

      const result = await post("instructors/update_students_csv/", {
        class_id: selectedClass,
        file: fileBase64,
      });
      console.log(result);
      setLoading(false);
      const data = result?.data?.data;
      setTotalCount(data?.total_records);
      setErrorCount(data?.fails?.length);
      setSuccessCount(data?.upload_count);
      setDuplicateCount(data?.skipped_duplicates);
      setDuplicateRecords(data?.duplicates);
      setErrorRecords(data?.fails);
      setVisible(true);
      successNotification("File Upload", "File Uploaded successfully!");
      form.resetFields();
    } catch (error) {
      setLoading(false);
      errorNotification("Error", error.message)
      form.resetFields();
      console.log(error);
    }
  };

  //getting class

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <div>
          <span
            style={{
              fontSize: 30,
            }}
          >
            Add Students
          </span>
          <div className="" style={{ marginTop: "20px" }}>
            <Form layout="vertical" onFinish={onUpload}>
              <div style={{marginBottom: 10}}>
              <CSVLink data={atob("Rmlyc3ROYW1lLExhc3ROYW1lLEVtYWlsLFByb2dyYW0sTWF0cmljTnVtYmVyLFBhc3N3b3JkLEVuZ2xpc2hOYW1lCkpvaG4sRG9lLGpvaG5Ac2FtcGxlLmNvbSxwcm9ncmFtIG5hbWUsMTIzNDU2NzgscGFzc3dvcmQsSm9obiBEb2UKQWJjLERlZixhYmNAdGVzdC5jb20scHJvZ3JhbSBuYW1lLDEyMzQscGFzc3dvcmQsQWJjIERlZgpNYXJrLExldmksbWFya0BhYmMuY29tLHByb2dyYW0gbmFtZSw0NTY0LHBhc3MsTWFyayBMZXZp")} filename={"sampleStudents.csv"}>
                Sample File
              </CSVLink>
              </div>
              <Form.Item
                style={{ width: "100%" }}
                label="Select a Class"
                name="class"
                rules={[{ required: true, message: "Please Select a Class" }]}
              >
                <Select
                  placeholder="Class Name"
                  onChange={(values: any) => setSelectedClass(values)}
                >
                  {/* <Option value={"lndalnkd"}>abc</Option> */}
                  {allClass.map((classDetail: any) => (
                    <Option value={classDetail.class_id}>
                      {classDetail.license_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="file"
                rules={[{ required: true, message: "Please Upload a File" }]}
                label="Students File"
              >
                <Input type="file" accept=".csv,text/plain" onChange={onChange} />
              </Form.Item>
              <Button htmlType="submit" type="primary">
                Upload
              </Button>
            </Form>
            { visible ?
                <div style={{marginTop: 15}}>
                  <Collapse accordion>
                    { visible ?
                        <Panel header="Upload Details" key="1">
                          <p>
                            Total Records = {totalCount}
                          </p>
                          <p>
                            Successfully Added = {successCount}
                          </p>
                          <p>
                            Duplicates Skipped = {duplicateCount}
                          </p>
                          <p>
                            Error Records = {errorCount}
                          </p>
                        </Panel>
                    : null}
                    { duplicateRecords.length > 0 ?
                        <Panel header="Duplicate Records" key="2">
                          {duplicateRecords.map((item, index) => {
                            return <p>{item}</p>
                          })}
                        </Panel>
                    : null}
                    { errorRecords.length > 0 ?
                        <Panel header="Error Records" key="3">
                          {errorRecords.map((item, index) => {
                            return <p>{item}</p>
                          })}
                        </Panel>
                    : null}
                  </Collapse>
                </div>
            : null}
          </div>
        </div>
      </div>
    </Spin>
  );
};
