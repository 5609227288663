import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Form, Select, Image, Spin, Row, Col } from "antd";
import { DecisionTreeContext } from "./DecisionTreeContext";
import { useConnection } from "../../Services/ApplicationContext";
import {errorNotification, successNotification} from "../../Services/NotificationService";

export const GenerateDecisionTree: FC = () => {
  const {
    targetVariable,
    file,
    plot,
    setPlot,
    features,
    setFeatures,
    sessionData,
    setSessionData,
    stepTwoData,
    resetFields
  } = useContext(DecisionTreeContext);
  const { post } = useConnection();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<any>({
    target: false,
    features: false,
   
  });
  const { Option } = Select;

  useEffect(() => {
    restoreStepTwoSession();
  }, [stepTwoData]);

  useEffect(() => {
    if(resetFields) {
      form.resetFields();
      setPlot('');
      setDisableButton({
        target: false,
        features: false,
      })
    }
  }, [resetFields]);

  const onVariableChange = (value: any) => {
    console.log(value);
    setDisableButton({ ...disableButton, target: true });
    form.setFieldsValue({
      features:[]
    })
    
    setFeatures(targetVariable.filter((variable: any) => variable != value));
  };

  const setData = (step_two_data: any) => {
    const s_data = sessionData;
    if(!s_data.session_data.length) {
      s_data.session_data.push(step_two_data);
      setSessionData(s_data);
    } else {
      let data = s_data.session_data;
      let index = -1
      for(let i=0; i<data.length; i++) {
        if(data[i].step == 'step 2') {
          index = i;
          break;
        }
      }
      if(index != -1) {
        data.splice(index, 1);
      }
      data.push(step_two_data);
      setSessionData(s_data);
    }

    console.log(sessionData);
  }

  const restoreStepTwoSession = () => {
    if(stepTwoData?.response_data?.data) {
      form.setFieldsValue({
        target: stepTwoData.request_data.target,
        features: stepTwoData.request_data.features
      });
      setDisableButton({...disableButton, features: true, target: true})
      setPlot(stepTwoData?.response_data?.data.plot);
    }
  }

  const onSubmit = async (values: any) => {
    console.log(values);
    try {
      setLoading(true);
      const data = {
        data: file,
        features: values.features ? values.features : [],
        target: values.target,
      }
      if(!values.target || values.target=="") {
        errorNotification("Error", "Target variable cannot be empty!");
        setLoading(false);
        return;
      }
      if(data.features.length == 0) {
        errorNotification("Error", "Features cannot be empty!");
        setLoading(false);
        return;
      }
      const result = await post("modules/tree/GenerateTree", data);
      result.data.data.plot = "data:image/jpeg;base64," + result.data.data.plot;
      setPlot(result.data.data.plot);
      console.log(result.data.data.plot);

      const step_two_data = {
        step: 'step 2',
        request_data: data,
        response_data: {
          data: result.data.data
        }
      }
      setData(step_two_data);
      setLoading(false);
      successNotification("Success", result.message);
    } catch (error) {
      errorNotification("Error", error.message)
      setLoading(false);
    }
  };

  const selectType = (value: any) => {

    if (value.length > 0) {
      setDisableButton({ ...disableButton, features: true });
    
        
    }else{
        setDisableButton({ ...disableButton, features: false });
    }
    if (value && value.length && value.includes("select-all")) {
      form.setFieldsValue({
        features: features,
      });
      setDisableButton({ ...disableButton, features: true });
    } else if (value.includes("deselect-all")) {
      form.setFieldsValue({
        features: [],
      });
      setDisableButton({ ...disableButton, features: false });
    }
  };

  useEffect(() => {
      form.resetFields()
      setPlot('')
      setDisableButton({
        target: false,
        features: false,
      })
      
  },[file])

  return (
    <Spin spinning={loading}>
      <div className="component-box">
        <h2>Generate Decision Tree</h2>
        <div className="">
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <h3>Target variable:</h3>
            <Form.Item name="target">
              <Select onChange={(value: any) => onVariableChange(value)}>
                {targetVariable.map((variable: any) => (
                    (variable?
                      <Option value={variable.trim()}>{variable.trim()}</Option>
                    :null)
                ))}
              </Select>
            </Form.Item>
            <h3>Features:</h3>{" "}
            <Form.Item name="features">
              <Select mode="multiple" onChange={selectType} disabled={!disableButton.target}>
                <Option value="select-all">Select All</Option>
                <Option value="deselect-all">Deselect All</Option>
                {features.map((variable: any) => (
                    (variable?
                    <Option value={variable.trim()}>{variable.trim()}</Option>
                    :null)
                ))}
              </Select>
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (file && file.localeCompare("") == 0) ||
                !(disableButton.features && disableButton.target  )
              }
            >
              Plot
            </Button>
          </Form>
        </div>
        {plot && (
          <Row justify="center">
            <Col>
              <Image src={plot} height={750} width={900} />
            </Col>
          </Row>
        )}
      </div>
    </Spin>
  );
};
